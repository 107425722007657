define('vimme/routes/school-admin/management/teachers', ['exports', 'ember', 'vimme/routes/school-admin'], function (exports, _ember, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        students: this.store.findAll('student'),
        teachers: this.store.findAll('teacher'),
        grades: this.store.findAll('grade')
      });
    }
  });
});