define('vimme/models/user-level', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    score: _emberData['default'].attr('number'),
    time: _emberData['default'].attr('number'),
    hasBeenPassed: _emberData['default'].attr('boolean'),

    level: _emberData['default'].belongsTo('level', { async: true }),

    userGame: _emberData['default'].belongsTo('user-game', {
      async: true,
      inverse: 'userLevels'
    })
  });
});