define('vimme/utils/compute-image-path', ['exports'], function (exports) {
  var computeImagePath = function computeImagePath(image, imagePath) {
    if (image) {
      imagePath = image.name;
    }

    if (imagePath) {
      var splitForward = imagePath.split('/');
      var splitBack = imagePath.split('\\');
      if (splitForward.length > 0) {
        imagePath = splitForward[splitForward.length - 1];
      } else if (splitBack.length > 0) {
        imagePath = splitBack[splitBack.length - 1];
      }
      imagePath = decodeURI(imagePath);
    }

    return imagePath;
  };
  exports.computeImagePath = computeImagePath;
});