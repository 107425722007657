define('vimme/routes/teacher/quiz', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model(params) {
      return RSVP.hash({
        quizQuestions: this.store.find('quizQuestion', { quiz_question_ids: params.quiz_question_ids }),
        quiz: params.quiz_id === 'new' ? undefined : this.store.find('quiz', params.quiz_id)
      });
    },
    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (window.zE) {
        window.zE(function () {
          window.zE.hide();
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('hideHeader', true);
    },
    resetController: function resetController() {
      this.controllerFor('application').set('hideHeader', false);
    }
  });
});