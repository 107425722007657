define('vimme/components/app-pages/teacher/quizzes/quiz/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    quiz: undefined,
    question: undefined,
    questions: _ember['default'].computed(function () {
      return [];
    }),

    number: 1,
    total: _ember['default'].computed.alias('questions.length'),

    hideAnswers: true,
    showAnswers: _ember['default'].computed.not('hideAnswers'),

    prev: _ember['default'].computed('number', function () {
      return this.get('number') - 1;
    }),
    next: _ember['default'].computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: _ember['default'].computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: _ember['default'].computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.set('tool', undefined);
    },

    actions: {
      toggleAnswers: function toggleAnswers() {
        this.toggleProperty('hideAnswers');
      },
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      }
    }
  });
});