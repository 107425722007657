define('vimme/components/vimme-search-list', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'ul',

    classNames: ['vimme-search-list'],

    search: null,

    isVisible: (function () {
      return this.get('search.isOpen');
    }).property('search.isOpen')
  });
});