define('vimme/controllers/school-admin/schedule-tests/index', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var guideType = _vimmeConfigEnvironment['default'].environment === 'core' ? 'VCL' : 'VL';

  exports['default'] = _ember['default'].Controller.extend({
    isDemo: (function () {
      return _vimmeConfigEnvironment['default'].environment === 'demo' || _vimmeConfigEnvironment['default'].environment === 'demo-dev';
    }).property(),

    selectedDate: null,
    selectedGrade: null,
    selectedTeacher: null,
    selectedTest: null,

    selectedGradePG: null,

    pacingGuide: (function () {
      var grade = this.get('selectedGradePG');

      if (!grade) {
        return null;
      }

      return this.assets.resolve('assets/pacing-guides/grade-' + grade.get('id') + '-pacing-guide-' + guideType + '.pdf');
    }).property('selectedGradePG'),

    AMPM: [{ label: 'am', value: false }, { label: 'pm', value: true }],

    showStartPicker: false,
    showEndPicker: false,

    startDay: null,
    startTime: null,
    startHour: null,
    startMinute: null,
    startPM: false,

    endDay: null,
    endHour: null,
    endMinute: null,
    endPM: false,

    isScheduling: false,
    isNotScheduling: _ember['default'].computed.not('isScheduling'),

    showLinks: _ember['default'].computed.and('selectedGrade', 'selectedTest'),

    filteredTeachers: _ember['default'].computed.filter('model.teachers', function (teacher) {
      if (this.get('selectedGrade')) {
        return teacher.get('grade.id') === this.get('selectedGrade.id');
      }

      return true;
    }).property('model.teachers', 'selectedGrade.id'),

    filteredTests: _ember['default'].computed.filter('model.tests', function (test) {
      if (this.get('selectedGrade')) {
        return test.get('grade.id') === this.get('selectedGrade.id');
      }

      return test.get('grade.id') === '2';
    }).property('model.tests', 'selectedGrade.id'),

    updateSelectedGrade: (function () {
      var grades = this.get('model.grades'),
          selectedTeacher = this.get('selectedTeacher');

      if (grades && selectedTeacher) {
        this.set('selectedGrade', grades.findBy('id', selectedTeacher.get('grade.id')));
      }
    }).on('init').observes('selectedTeacher.grade.id'),

    updateSelectedTeacher: (function () {
      var selectedGrade = this.get('selectedGrade'),
          selectedTeacher = this.get('selectedTeacher');

      if (selectedGrade && selectedTeacher && selectedGrade.get('id') !== selectedTeacher.get('grade.id')) {
        this.set('selectedTeacher', null);
      }
    }).observes('selectedGrade.id'),

    updateSelectedTest: (function () {
      var selectedGrade = this.get('selectedGrade'),
          selectedTest = this.get('selectedTest');

      if (!selectedGrade || selectedTest && selectedGrade.get('id') !== selectedTest.get('grade.id')) {
        this.set('selectedTest', null);
      }
    }).observes('selectedGrade.id'),

    updateStartHourMinute: (function () {
      var startTime = this.get('startTime'),
          startHour = null,
          startMinute = null,
          other = null;

      if (startTime) {
        var _startTime$split = startTime.split(':');

        var _startTime$split2 = _slicedToArray(_startTime$split, 3);

        startHour = _startTime$split2[0];
        startMinute = _startTime$split2[1];
        other = _startTime$split2[2];
      }

      if (other) {
        startHour = 13;
      }

      this.set('startHour', startHour);
      this.set('startMinute', startMinute);
    }).on('init').observes('startTime'),

    updateEndHourMinute: (function () {
      var endTime = this.get('endTime'),
          endHour = null,
          endMinute = null,
          other = null;

      if (endTime) {
        var _endTime$split = endTime.split(':');

        var _endTime$split2 = _slicedToArray(_endTime$split, 3);

        endHour = _endTime$split2[0];
        endMinute = _endTime$split2[1];
        other = _endTime$split2[2];
      }

      if (other) {
        endHour = 13;
      }

      this.set('endHour', endHour);
      this.set('endMinute', endMinute);
    }).on('init').observes('endTime'),

    startDate: (function () {
      var startDay = this.get('startDay'),
          startHour = this.get('startHour'),
          startMinute = this.get('startMinute'),
          startPM = this.get('startPM'),
          startDate;

      if (startDay) {
        startDate = moment(startDay);

        if (startHour) {
          if (isNaN(startHour) || parseInt(startHour, 10) < 1 || parseInt(startHour, 10) > 12) {
            return null;
          }
          if (parseInt(startHour, 10) !== 12) {
            startDate.add(startHour, 'hours');
          }
        }

        if (startMinute) {
          if (isNaN(startMinute) || parseInt(startMinute, 10) < 0 || parseInt(startMinute, 10) > 59) {
            return null;
          }
          if (startMinute.length === 1) {
            if (parseInt(startMinute, 10) > 5) {
              return null;
            }
            startMinute += '0';
          }
          startDate.add(startMinute, 'minutes');
        }

        if (startPM) {
          startDate.add(12, 'hours');
        }

        if (startDate.isValid()) {
          return startDate.toDate();
        }
      }

      return null;
    }).property('startDay', 'startHour', 'startMinute', 'startPM'),

    endDate: (function () {
      var endDay = this.get('endDay'),
          endHour = this.get('endHour'),
          endMinute = this.get('endMinute'),
          endPM = this.get('endPM'),
          endDate;

      if (endDay) {
        endDate = moment(endDay);

        if (endHour) {
          if (isNaN(endHour) || parseInt(endHour, 10) < 1 || parseInt(endHour, 10) > 12) {
            return null;
          }
          if (parseInt(endHour, 10) !== 12) {
            endDate.add(endHour, 'hours');
          }
        }

        if (endMinute) {
          if (isNaN(endMinute) || parseInt(endMinute, 10) < 0 || parseInt(endMinute, 10) > 59) {
            return null;
          }
          if (endMinute.length === 1) {
            endMinute += '0';
          }
          endDate.add(endMinute, 'minutes');
        }

        if (endPM) {
          endDate.add(12, 'hours');
        }

        if (endDate.isValid()) {
          return endDate.toDate();
        }
      }

      return null;
    }).property('endDay', 'endHour', 'endMinute', 'endPM'),

    endDateIsAfterStartDate: _ember['default'].computed('startDate', 'endDate', function () {
      var startDate = moment(this.get('startDate')),
          endDate = moment(this.get('endDate'));

      return startDate.isValid() && endDate.isValid() && endDate.isAfter(startDate);
    }),

    isSchedulable: _ember['default'].computed.and('selectedTest', 'startDate', 'endDate', 'endDateIsAfterStartDate', 'isNotScheduling'),
    isNotSchedulable: _ember['default'].computed.not('isSchedulable'),

    selectDay: function selectDay(day) {
      this.set('selectedDate', day);
      this.transitionToRoute('school-admin.schedule-tests.day', moment(day).format('MM-DD-YYYY'));
    },

    actions: {
      select: function select(day) {
        this.selectDay(day);
      },

      selectToday: function selectToday() {
        this.selectDay(moment().startOf('day').toDate());
      },

      selectStartDay: function selectStartDay(day) {
        this.set('startDay', day);
        this.set('showStartPicker', false);
      },

      selectEndDay: function selectEndDay(day) {
        this.set('endDay', day);
        this.set('showEndPicker', false);
      },

      toggleStartPicker: function toggleStartPicker() {
        this.toggleProperty('showStartPicker');
      },

      toggleEndPicker: function toggleEndPicker() {
        this.toggleProperty('showEndPicker');
      },

      schedule: function schedule() {
        var _this = this;

        var selectedGrade = this.get('selectedGrade'),
            selectedTeacher = this.get('selectedTeacher'),
            selectedTest = this.get('selectedTest'),
            scheduledTests = this.get('model.scheduledTests'),
            startDate = this.get('startDate'),
            endDate = this.get('endDate'),
            teachers,
            testsToSchedule,
            scheduledTestsToSave;

        if (!selectedGrade) {
          return;
        }

        this.set('isScheduling', true);

        if (selectedTeacher) {
          teachers = [selectedTeacher];
        } else {
          teachers = this.get('model.teachers').filterBy('grade.id', selectedGrade.get('id'));
        }

        if (selectedTest) {
          testsToSchedule = [selectedTest];
        } else {
          testsToSchedule = this.get('model.test').filterBy('grade.id', selectedGrade.get('id'));
        }

        scheduledTestsToSave = _ember['default'].RSVP.all(teachers.map(function (teacher) {
          return _ember['default'].RSVP.all(testsToSchedule.map(function (test) {
            var found = true;
            var scheduledTest = scheduledTests.find(function (scheduledTest) {
              return scheduledTest.get('teacher.id') === teacher.get('id') && scheduledTest.get('test.id') === test.get('id');
            });

            if (!scheduledTest) {
              found = false;
              scheduledTest = _this.store.createRecord('scheduledTest', {
                teacher: teacher,
                test: test,
                startDate: startDate,
                endDate: endDate
              });
            } else {
              scheduledTest.set('startDate', startDate);
              scheduledTest.set('endDate', endDate);
            }

            return scheduledTest.save().then(function (scheduledTest) {
              if (!found) {
                scheduledTests.pushObject(scheduledTest);
              }
            });
          }));
        }));

        scheduledTestsToSave['finally'](function () {
          _this.set('isScheduling', false);
          _this.set('showStartPicker', false);
          _this.set('showEndPicker', false);
        });
      }
    }
  });
});