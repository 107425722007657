define('vimme/routes/teacher/builder/quizzes/quiz', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model(params) {
      var quiz = this.store.find('new-quiz', params.quiz_id);
      var teacher = this.modelFor('teacher').user;
      var grade = teacher.get('grade');
      var allTeachers = grade.then(function (g) {
        return g.get('teachers');
      });
      var weeks = this.store.all('week');
      var teachers = allTeachers.then(function (teachers) {
        return teachers.filter(function (t) {
          return !t.get('isPlaceholder') && t.get('id') !== teacher.get('id');
        });
      });
      var questions = quiz.then(function (quiz) {
        return quiz.get('newQuizQuestions');
      });
      return _ember['default'].RSVP.hash({
        teachers: teachers,
        quiz: quiz,
        questions: questions,
        weeks: weeks
      });
    }
  });
});