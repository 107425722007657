define("vimme/templates/student/new-quiz", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/student/new-quiz.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/student/quizzes/quiz-wrapper", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.model.studentQuestions", ["loc", [null, [2, 21], [2, 48]]]]], [], []], "quiz", ["subexpr", "@mut", [["get", "this.model.quiz", ["loc", [null, [3, 9], [3, 24]]]]], [], []], "studentQuiz", ["subexpr", "@mut", [["get", "this.model.studentQuiz", ["loc", [null, [4, 16], [4, 38]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.model.question", ["loc", [null, [5, 13], [5, 32]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.model.questions", ["loc", [null, [6, 14], [6, 34]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.model.number", ["loc", [null, [7, 11], [7, 28]]]]], [], []]], ["loc", [null, [1, 0], [8, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});