define('vimme/routes/guardian/reports/week', ['exports', 'ember', 'vimme/routes/guardian'], function (exports, _ember, _vimmeRoutesGuardian) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        grades: this.modelFor('guardian.reports').grades,
        students: this.modelFor('guardian.reports').students,
        studentTests: this.modelFor('guardian.reports').studentTests,
        scheduledTests: this.modelFor('guardian.reports').scheduledTests,
        tests: this.modelFor('guardian.reports').tests,
        weeks: this.modelFor('guardian.reports').weeks
      });
    }
  });
});