define('vimme/routes/student', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmJwtDecode) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authenticationRoute: 'index',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      this._super(transition);

      if (role !== 'Student') {
        this.replaceWith('index');
      } else if (transition.targetName === 'student.index') {
        if (transition.sequence === 0) {
          this.replaceWith('student.testing-calendar');
        } else {
          this.transitionTo('student.testing-calendar');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref2.role;
      var id = _ref2.id;

      var user = this.store.find(role, id);

      return _ember['default'].RSVP.hash({
        school: user.then(function (user) {
          return user.get('school');
        }),
        teacher: user.then(function (user) {
          return user.get('teacher');
        }),
        tests: this.store.findAll('test'),
        scheduledTests: this.store.findAll('scheduledTest'),
        studentTests: this.store.findAll('studentTest'),
        quizzes: this.store.findAll('newQuiz'),
        studentQuizzes: this.store.findAll('studentNewQuiz'),
        user: user
      });
    }
  });
});