define('vimme/models/sub-admin', ['exports', 'ember-data', 'vimme/models/user'], function (exports, _emberData, _vimmeModelsUser) {
  exports['default'] = _vimmeModelsUser['default'].extend({
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    eula: _emberData['default'].attr('boolean'),
    schoolIds: _emberData['default'].attr('string'),

    school: _emberData['default'].belongsTo('school', { async: true }),
    quizzes: _emberData['default'].hasMany('quiz', { async: true }),

    fullName: (function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }).property('firstName', 'lastName'),

    lastThenFirst: (function () {
      return this.get('lastName') + ', ' + this.get('firstName');
    }).property('firstName', 'lastName'),

    isDistrictAdmin: (function () {
      var schoolIds = this.get('schoolIds');
      return !!schoolIds && schoolIds.split(',').length > 1;
    }).property('schoolIds')
  });
});