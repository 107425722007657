define("vimme/templates/admin/builder/tests/test/questions/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/admin/builder/tests/test/questions/new.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "vimme-test-question-editor", [], ["question", ["subexpr", "@mut", [["get", "model.question", ["loc", [null, [2, 13], [2, 27]]]]], [], []], "standards", ["subexpr", "@mut", [["get", "model.standards", ["loc", [null, [3, 14], [3, 29]]]]], [], []], "saveQuestion", ["subexpr", "route-action", ["saveQuestion"], [], ["loc", [null, [4, 17], [4, 46]]]], "cancel", ["subexpr", "route-action", ["cancel"], [], ["loc", [null, [5, 11], [5, 34]]]], "reroute", ["subexpr", "route-action", ["reroute"], [], ["loc", [null, [6, 12], [6, 36]]]]], ["loc", [null, [1, 0], [7, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});