define("vimme/components/vimme-ruler/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "vimme/components/vimme-ruler/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "vimme-ruler-shrink btn btn-icon-only icon-prev");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "vimme-ruler-reset btn btn-icon-only icon-reset");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "draggable", "false");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "vimme-ruler-grow btn btn-icon-only icon-next");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(fragment, [8]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element0, 'disabled');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createAttrMorph(element2, 'src');
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createElementMorph(element3);
        morphs[6] = dom.createAttrMorph(element4, 'disabled');
        morphs[7] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["attribute", "disabled", ["get", "disableShrink", ["loc", [null, [1, 74], [1, 87]]]]], ["element", "action", ["shrink"], [], ["loc", [null, [1, 90], [1, 109]]]], ["element", "action", ["resetRotate"], [], ["loc", [null, [2, 63], [2, 87]]]], ["attribute", "src", ["get", "rulerImage", ["loc", [null, [3, 29], [3, 39]]]]], ["attribute", "class", ["concat", ["vimme-ruler-rotate btn btn-icon-only ", ["subexpr", "if", [["get", "rotating", ["loc", [null, [4, 57], [4, 65]]]], "icon-move", "icon-rotate"], [], ["loc", [null, [4, 52], [4, 93]]]]]]], ["element", "action", ["toggleRotate"], [], ["loc", [null, [4, 95], [4, 120]]]], ["attribute", "disabled", ["get", "disableGrow", ["loc", [null, [5, 72], [5, 83]]]]], ["element", "action", ["grow"], [], ["loc", [null, [5, 86], [5, 103]]]]],
      locals: [],
      templates: []
    };
  })());
});