define("vimme/templates/sub-admin/reports/standard", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 8
            },
            "end": {
              "line": 16,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [11, 24], [11, 36]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [13, 22], [13, 35]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [10, 12], [15, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 8
            },
            "end": {
              "line": 24,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [19, 24], [19, 32]]]]], [], []], "optionLabelPath", "content.lastThenFirst", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [21, 22], [21, 37]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [18, 12], [23, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 16
              },
              "end": {
                "line": 38,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "cell");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                     ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("%");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element3, 'title');
            morphs[1] = dom.createMorphAt(element3, 0, 0);
            morphs[2] = dom.createAttrMorph(element4, 'class');
            morphs[3] = dom.createMorphAt(element4, 0, 0);
            return morphs;
          },
          statements: [["attribute", "title", ["get", "report.description", ["loc", [null, [35, 50], [35, 68]]]]], ["content", "report.id", ["loc", [null, [35, 71], [35, 84]]]], ["attribute", "class", ["concat", ["cell ", ["subexpr", "if", [["get", "report.total", ["loc", [null, [36, 46], [36, 58]]]], ["subexpr", "color", [["get", "report.percent", ["loc", [null, [36, 66], [36, 80]]]], ["get", "grade", ["loc", [null, [36, 81], [36, 86]]]]], [], ["loc", [null, [36, 59], [36, 87]]]]], [], ["loc", [null, [36, 41], [36, 89]]]]]]], ["content", "report.percent", ["loc", [null, [36, 91], [36, 109]]]]],
          locals: ["report"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 20
              },
              "end": {
                "line": 46,
                "column": 20
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n                     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n                 ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [45, 50], [45, 64]]]], " no-hover"]]], ["content", "week.shortName", ["loc", [null, [45, 77], [45, 95]]]]],
          locals: ["week"],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 91
                  },
                  "end": {
                    "line": 51,
                    "column": 126
                  }
                },
                "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("%");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["content", "week.percent", ["loc", [null, [51, 109], [51, 125]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 24
                },
                "end": {
                  "line": 52,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "if", [["get", "week.total", ["loc", [null, [51, 50], [51, 60]]]], ["subexpr", "color", [["get", "week.percent", ["loc", [null, [51, 68], [51, 80]]]], ["get", "grade", ["loc", [null, [51, 81], [51, 86]]]]], [], ["loc", [null, [51, 61], [51, 87]]]]], [], ["loc", [null, [51, 45], [51, 89]]]]]]], ["block", "if", [["get", "week.total", ["loc", [null, [51, 97], [51, 107]]]]], [], 0, null, ["loc", [null, [51, 91], [51, 133]]]]],
            locals: ["week"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 16
              },
              "end": {
                "line": 54,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                     ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "report.weeks", ["loc", [null, [50, 32], [50, 44]]]]], [], 0, null, ["loc", [null, [50, 24], [52, 33]]]]],
          locals: ["report"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 4
            },
            "end": {
              "line": 57,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-general column");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-general-inner");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header student-column no-hover");
          var el5 = dom.createTextNode("Standard");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n                 ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header average-column no-hover");
          var el5 = dom.createTextNode("Average");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-detailed column");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-detailed-inner");
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n                 ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
          morphs[2] = dom.createMorphAt(element5, 3, 3);
          return morphs;
        },
        statements: [["block", "each", [["get", "reports", ["loc", [null, [33, 24], [33, 31]]]]], [], 0, null, ["loc", [null, [33, 16], [38, 25]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [44, 28], [44, 39]]]]], [], 1, null, ["loc", [null, [44, 20], [46, 29]]]], ["block", "each", [["get", "reports", ["loc", [null, [48, 24], [48, 31]]]]], [], 2, null, ["loc", [null, [48, 16], [54, 25]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 59,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/sub-admin/reports/standard.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-standard");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element7, 1, 1);
        morphs[1] = dom.createMorphAt(element7, 3, 3);
        morphs[2] = dom.createMorphAt(element7, 4, 4);
        morphs[3] = dom.createMorphAt(element6, 3, 3);
        return morphs;
      },
      statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.schools", ["loc", [null, [4, 20], [4, 33]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedSchool", ["loc", [null, [6, 18], [6, 32]]]]], [], []], "prompt", "Select School"], ["loc", [null, [3, 8], [8, 10]]]], ["block", "if", [["get", "selectedSchool", ["loc", [null, [9, 14], [9, 28]]]]], [], 0, null, ["loc", [null, [9, 8], [16, 15]]]], ["block", "if", [["get", "selectedGrade", ["loc", [null, [17, 14], [17, 27]]]]], [], 1, null, ["loc", [null, [17, 8], [24, 15]]]], ["block", "if", [["get", "reports", ["loc", [null, [26, 10], [26, 17]]]]], [], 2, null, ["loc", [null, [26, 4], [57, 11]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});