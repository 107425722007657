define('vimme/models/grade', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    shortTitle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),

    number: Ember.computed('id', function () {
      if (this.get('id') === 0) {
        return 'K';
      }
      if (this.get('id') === 9) {
        return 'A';
      }
      return this.get('id');
    }),

    students: _emberData['default'].hasMany('student', { async: true }),
    teachers: _emberData['default'].hasMany('teacher', { async: true }),
    studentTests: _emberData['default'].hasMany('studentTest', { async: true }),
    quizQuestions: _emberData['default'].hasMany('quizQuestion', { async: true }),
    resources: _emberData['default'].hasMany('resource', { async: true })
  });
});