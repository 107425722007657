define('vimme/initializers/inject-router', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject('component', 'router', 'router:main');
  }

  exports['default'] = {
    name: 'inject-router',
    initialize: initialize
  };
});