define('vimme/components/print-view-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    position: '',

    style: _ember['default'].computed('position', function () {
      switch (this.get('position')) {
        case 'high':
          return 'margin-top: -25px;';
        case 'low':
          return 'margin-top: 15px;';
        default:
          return '';
      }
    })
  });
});