define('vimme/routes/teacher/builder/quizzes/index', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      var weeks = this.modelFor('teacher.builder.quizzes').weeks;
      return _ember['default'].RSVP.hash({
        quiz: this.store.createRecord('new-quiz', {
          teacher: teacher
        }),
        weeks: weeks
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('new-quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});