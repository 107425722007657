define('vimme/controllers/admin/builder/quiz-questions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    'new': _ember['default'].inject.controller('admin.builder.quiz-questions.new'),

    actions: {
      newQuizQuestion: function newQuizQuestion() {
        if (this.get('application.currentRouteName') !== 'admin.builder.quiz-questions.new') {
          this.transitionToRoute('admin.builder.quiz-questions.new');
        } else {
          this.store.all('quizQuestion').filterBy('id', null).invoke('deleteRecord');
          this.set('new.model.quizQuestion', this.store.createRecord('quizQuestion', {
            title: null
          }));
        }
      }
    }
  });
});