define('vimme/components/app-pages/admin/schools/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    searchTerm: '',
    schools: _ember['default'].computed(function () {
      return [];
    }),

    filteredSchools: _ember['default'].computed('schools.@each.{title,isNew,isDeleted}', 'searchTerm', function () {
      var schools = this.get('schools').filterBy('isNew', false).filterBy('isDeleted', false);

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');

      if (!term) {
        return schools;
      }

      return schools.filter(function (school) {
        return filter.test(school.get('title'));
      });
    })
  });
});