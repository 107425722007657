define('vimme/components/vimme-scientific-calculator/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['vimme-calculator'],

    didInsertElement: function didInsertElement() {
      this.calculator = window.Desmos.ScientificCalculator(this.element);
    },

    willDestroyElement: function willDestroyElement() {
      this.calculator.destroy();
    },

    x: 0,
    y: 0,

    tempX: 0,
    tempY: 0,

    gestures: {
      drag: function drag(event) {
        event.gesture.preventDefault();

        var _getProperties = this.getProperties('x', 'y');

        var x = _getProperties.x;
        var y = _getProperties.y;
        var _event$gesture = event.gesture;
        var deltaX = _event$gesture.deltaX;
        var deltaY = _event$gesture.deltaY;

        var tempX = x + deltaX;
        var tempY = y + deltaY;

        this.$().css({
          '-webkit-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-moz-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-o-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          '-ms-transform': 'translate(' + tempX + 'px, ' + tempY + 'px)',
          transform: 'translate(' + tempX + 'px, ' + tempY + 'px)'
        });

        this.setProperties({
          tempX: tempX,
          tempY: tempY
        });
      },
      dragend: function dragend() {
        this.setProperties({
          x: this.tempX,
          y: this.tempY
        });
      }
    }
  });
});