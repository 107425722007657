define('vimme/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/configuration', 'vimme/config/environment', 'npm:jwt-decode'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _emberSimpleAuthConfiguration, _vimmeConfigEnvironment, _npmJwtDecode) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {
    cookies: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),

    routeAfterAuthentication: _ember['default'].computed('session.data.authenticated.token', function () {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      switch (role) {
        case 'Admin':
          return 'admin.management.schools';
        case 'SchoolAdmin':
          return 'school-admin.schedule-tests';
        case 'SubAdmin':
          return 'sub-admin.reports.week';
        case 'Teacher':
          return 'teacher.testing-calendar';
        case 'Student':
          return 'student.testing-calendar';
        case 'Guardian':
          return 'guardian.profile';
      }
      return _emberSimpleAuthConfiguration['default'].routeAfterAuthentication;
    }),

    trackUserForStonly: function trackUserForStonly() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var isTrial = _ref2.isTrial;
      var role = _ref2.role;
      var sub = _ref2.sub;

      var appId = undefined;
      switch (_vimmeConfigEnvironment['default'].environment) {
        case 'core':
          appId = 'core';
          break;
        case 'production':
          appId = 'app';
          break;
        case 'demo':
          appId = 'demo';
          break;
        default:
          appId = 'local';
          break;
      }
      stonlyTrack('identify', appId + '-' + sub, {
        'app-id': appId,
        trial: !!isTrial,
        role: role
      });
    },

    _subscribeToSessionEvents: function _subscribeToSessionEvents() {
      var _this = this;

      _ember['default'].A([['authenticationSucceeded', 'sessionAuthenticated'], ['invalidationSucceeded', 'sessionInvalidated']]).forEach(function (_ref3) {
        var _ref32 = _slicedToArray(_ref3, 2);

        var event = _ref32[0];
        var method = _ref32[1];

        _this.get('session').on(event, function () {
          return _this[method].apply(_this, arguments);
        });
      });
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this;

      this.getPostAuthPreloads().then(function () {

        _this2.trackUserForStonly();

        var attemptedTransition = _this2.get('session.attemptedTransition');
        var cookies = _this2.get('cookies');
        var redirectTarget = cookies.read('ember_simple_auth-redirectTarget');

        if (attemptedTransition) {
          attemptedTransition.retry();
          _this2.set('session.attemptedTransition', null);
        } else if (redirectTarget) {
          _this2.transitionTo(redirectTarget);
          cookies.clear('ember_simple_auth-redirectTarget');
        } else {
          _this2.transitionTo(_this2.get('routeAfterAuthentication'));
        }
      });
    },

    getPostAuthPreloads: function getPostAuthPreloads() {
      return _ember['default'].RSVP.all([this.store.findAll('week'), this.store.findAll('grade')]);
    },

    actions: {
      // error: function() {
      //     this.transitionTo('index');
      // },
      gotoTest: function gotoTest(id, model) {
        var token = this.get('session.data.authenticated.token');

        var _decode = (0, _npmJwtDecode['default'])(token);

        var role = _decode.role;

        this.controller.set('savedRoute', this.controller.get('currentRouteName'));

        if (model) {
          this.controller.set('savedModel', model);
        }

        this.transitionTo(role.dasherize() + '.questions', id, { queryParams: { testReviewID: 'resetme' } });
      },
      gotoTestReview: function gotoTestReview(id, questionNum, queryParam, model) {
        var token = this.get('session.data.authenticated.token');

        var _decode2 = (0, _npmJwtDecode['default'])(token);

        var role = _decode2.role;

        if (!questionNum) {
          questionNum = 1;
        }

        this.controller.set('savedRoute', this.controller.get('currentRouteName'));

        if (model) {
          this.controller.set('savedModel', model);
        }

        this.transitionTo(role.dasherize() + '.questions.question', id, questionNum, {
          queryParams: { testReviewID: queryParam }
        });
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this3 = this;

      this._super(transition);

      this.get('session').set('data.eula', undefined);

      return this.get('ajax').request(_vimmeConfigEnvironment['default'].API.host + '/preloads').then(function (data) {
        _this3.store.pushPayload(data);
      }).then(function () {
        if (_this3.get('session.isAuthenticated')) {
          _this3.trackUserForStonly();
          return _this3.getPostAuthPreloads();
        }
      });
    }
  });
});