define('vimme/components/app-pages/student/quiz-calendar/quiz/component', ['exports', 'ember', 'vimme/utils/round'], function (exports, _ember, _vimmeUtilsRound) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    quiz: undefined,

    studentQuizzes: _ember['default'].computed(function () {
      return [];
    }),

    studentQuiz: _ember['default'].computed('quiz', 'studentQuizzes.@each.newQuiz.id', function () {
      var _this = this;

      return this.get('studentQuizzes').find(function (sq) {
        return sq.get('newQuiz.id') === _this.get('quiz.id');
      });
    })
  });
});