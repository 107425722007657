define('vimme/controllers/teacher/testing-calendar/test', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    testingCalendarController: _ember['default'].inject.controller('teacher/testing-calendar'),

    showDialog: false,

    setCalendarDate: (function () {
      var selectedDate = this.get('testingCalendarController.selectedDate');

      if (!selectedDate) {
        this.set('testingCalendarController.selectedDate', this.get('model.dateAvailable'));
      }
    }).observes('model.dateAvailable'),

    scheduledTest: _ember['default'].computed.alias('model.scheduledTests.firstObject'),

    isNotViewable: _ember['default'].computed.not('scheduledTest.isViewable'),
    isNotActive: _ember['default'].computed.not('scheduledTest.isActive'),

    actions: {
      toggleReview: function toggleReview() {
        var scheduledTest = this.get('scheduledTest');

        if (scheduledTest) {
          scheduledTest.toggleProperty('isReviewable');
          scheduledTest.save()['catch'](function () {
            scheduledTest.toggleProperty('isReviewable');
          });
        }
      }
    }
  });
});