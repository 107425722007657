define('vimme/routes/school-admin/questions/question', ['exports', 'vimme/routes/school-admin'], function (exports, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model(params) {
      var questions = this.modelFor('school-admin.questions').questions,
          length = questions.get('length');

      if (params.question_id > 0 && params.question_id <= length) {
        return questions.objectAt(params.question_id - 1);
      }

      this.replaceWith('school-admin.questions.question', 1);
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      this.controllerFor('school-admin.questions').set('currentQuestion', model);
    }
  });
});