define('vimme/controllers/guardian/resources', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['weeks', 'grades', 'page', 'sort'],
    weeks: null,
    grades: null,
    page: 1,
    sort: 'grade',
    userTypes: 'parent',

    resourcesCache: [],
    resources: (function (key, value) {
      var _this = this;

      if (arguments.length > 1) {
        this.set('resourcesCache', value);
        return value;
      }
      this.store.find('resource', {
        grades: this.get('grades'),
        weeks: this.get('weeks'),
        page: this.get('page'),
        sort: this.get('sort'),
        userTypes: this.get('userTypes')
      }).then(function (resources) {
        _this.set('resources', resources);
      });
      return this.get('resourcesCache');
    }).property('weeks', 'grades', 'page', 'sort', 'userTypes')
  });
});