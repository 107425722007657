define('vimme/controllers/guardian/profile', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authAjax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    isSaving: false,

    showDialogPassword: false,

    code: '',
    password: '',
    passwordConfirmation: '',

    isValidPassword: (function () {
      var password = this.get('password'),
          passwordConfirmation = this.get('passwordConfirmation');

      return !!password && !!passwordConfirmation;
    }).property('password', 'passwordConfirmation'),

    isUnsavablePassword: _ember['default'].computed.not('isValidPassword'),

    isValidUpdate: (function () {
      var email = this.get('model.user.email'),
          lastName = this.get('model.user.lastName'),
          firstName = this.get('model.user.firstName');

      return !!email && !!lastName && !!firstName;
    }).property('model.user.{email,firstName,lastName}'),

    isUnsavable: _ember['default'].computed.not('isValidUpdate'),

    actions: {
      cancelDialogPassword: function cancelDialogPassword() {
        this.set('password', '');
        this.set('passwordConfirmation', '');
        this.set('showDialogPassword', false);
      },

      showDialogPassword: function showDialogPassword() {
        this.set('password', '');
        this.set('passwordConfirmation', '');
        this.set('showDialogPassword', true);
      },

      save: function save() {
        var _this = this;

        this.set('isSaving', true);
        var user = this.get('model.user');
        user.save().then(function () {
          _this.set('isSaving', false);
        });
      },

      showAccessCode: function showAccessCode() {
        this.set('showAccessCode', true);
      },

      redeem: function redeem() {
        var _this2 = this;

        this.set('isSaving', true);
        var code = this.get('code');
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/access-codes/redeem', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            code: code
          })
        }).then(function () {
          _this2.set('isSaving', false);
          _this2.set('code', '');
          _this2.set('showAccessCode', false);
        })['catch'](function (error) {
          _this2.set('isSaving', false);
          var message = _ember['default'].get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      },

      changePassword: function changePassword() {
        var _this3 = this;

        this.set('isSaving', true);
        var password = this.get('password');
        var password_confirmation = this.get('passwordConfirmation');
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/guardians/' + this.get('model.user.id') + '/password', {
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            guardian: {
              password: password,
              password_confirmation: password_confirmation
            }
          })
        }).then(function () {
          _this3.set('isSaving', false);
          _this3.set('showDialogPassword', false);
          _this3.set('password', '');
          _this3.set('passwordConfirmation', '');
        })['catch'](function (error) {
          _this3.set('isSaving', false);

          if (error && _ember['default'].get(error, 'payload.errors') && Object.values(_ember['default'].get(error, 'payload.errors')).length) {
            alert(Object.values(_ember['default'].get(error, 'payload.errors'))[0][0]);
          } else {
            alert('An error has occurred.');
          }
        });
      }
    }
  });
});