define('vimme/components/app-pages/student/quizzes/quiz/answer/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    selectedAnswers: _ember['default'].computed(function () {
      return [];
    }),
    answer: undefined,

    isSelected: _ember['default'].computed('selectedAnswers.@each.id', 'answer.id', function () {
      var _this = this;

      return !!this.get('selectedAnswers').find(function (a) {
        return a.get('id') === _this.get('answer.id');
      });
    })
  });
});