define('vimme/components/color-key/component', ['exports', 'ember', 'vimme/constants/color'], function (exports, _ember, _vimmeConstantsColor) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    grade: 0,
    print: false,

    showKey: _ember['default'].computed('grade', 'print', function () {
      if (this.get('print')) {
        return false;
      }

      var grade = parseInt(this.get('grade'), 10);

      return !!grade && !!_vimmeConstantsColor.showKey[grade];
    })
  });
});