define('vimme/components/app-pages/teacher/quizzes/quiz-review/question/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    studentQuestion: null,
    question: null,

    missingAnswers: _ember['default'].computed('studentQuestion.newQuizQuestionAnswers.[]', 'question.newQuizQuestionAnswers.[]', function () {
      var _this = this;

      return this.get('question.newQuizQuestionAnswers').filter(function (answer) {
        return answer.get('isCorrect') && !_this.get('studentQuestion.newQuizQuestionAnswers').find(function (a) {
          return a.get('id') === answer.get('id');
        });
      });
    }),

    showMissingAnswers: _ember['default'].computed('studentQuestion.{isCorrect,isTextAnswer}', 'missingAnswers.length', function () {
      if (this.get('studentQuestion.isCorrect') || this.get('studentQuestion.isTextAnswer')) {
        return false;
      }
      return !!this.get('missingAnswers.length');
    }),

    isUnanswered: _ember['default'].computed('studentQuestion.{newQuizQuestionAnswers.length,textAnswer}', 'isTextType', function () {
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.newQuizQuestionAnswers.length');
      return !this.get('isTextType') && !answers || typeof textAnswer === 'undefined';
    }),

    isTextType: _ember['default'].computed('question.questionType', function () {
      return this.get('question.questionType') === 'text';
    })
  });
});