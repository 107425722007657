define('vimme/routes/admin/builder/resources/new', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model() {
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      return _ember['default'].RSVP.hash({
        resource: this.store.createRecord('resource', {
          title: null
        }),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('resource').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});