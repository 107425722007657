define('vimme/components/vimme-student-management-score/component', ['exports', 'ember', 'vimme/utils/round'], function (exports, _ember, _vimmeUtilsRound) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',

    classNames: ['tr'],
    classNameBindings: ['isSelected'],

    // external
    studentTest: null,
    selected: null,

    isSelected: (function () {
      return this.get('studentTest.id') === this.get('selected.id');
    }).property('studentTest.id', 'selected.id'),

    percent: (function () {
      return (0, _vimmeUtilsRound['default'])(this.get('studentTest.percent'));
    }).property('studentTest.percent'),

    click: function click() {
      this.sendAction('select', this.get('studentTest'));
    }
  });
});