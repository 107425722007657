define('vimme/components/vimme-resources-list/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['vimme-resources-list'],
    classNameBindings: ['viewOnly', 'hideGrades'],

    grades: [],
    weeks: [],
    resources: [],
    page: 1,

    selectedGrades: '',
    selectedWeeks: '',
    sort: null,

    viewOnly: false,
    hideGrades: false,

    localSeletedGrades: [],
    localSelectedWeeks: [],
    localSelectedUserTypes: [],

    userTypes: [{
      id: 'student',
      value: 'Student'
    }, {
      id: 'parent',
      value: 'Parent'
    }, {
      id: 'teacher',
      value: 'Teacher'
    }, {
      id: 'admin',
      value: 'Admin'
    }],

    sortType: (function () {
      var sort = this.get('sort');
      return this.get('sort.length') && sort[0] === '-' ? sort.slice(1) : sort;
    }).property('sort'),
    sortDir: (function () {
      return this.get('sort.length') && this.get('sort')[0] === '-' ? 'desc' : 'asc';
    }).property('sort'),
    sortedByTitle: (function () {
      return this.get('sortType') === 'title';
    }).property('sortType'),
    sortedByWeek: (function () {
      return this.get('sortType') === 'week';
    }).property('sortType'),
    sortedByGrade: (function () {
      return this.get('sortType') === 'grade';
    }).property('sortType'),

    pagination: _ember['default'].computed.alias('resources.meta.pagination'),
    paginationStart: (function () {
      var current = this.get('pagination.current_page');
      var per = this.get('pagination.per_page');
      return current * per - per + 1;
    }).property('pagination.{per_page,current_page}'),
    paginationEnd: (function () {
      return this.get('paginationStart') + this.get('pagination.count') - 1;
    }).property('paginationStart', 'pagination.count'),

    componentWillMount: (function () {
      var selectedGrades = this.get('selectedGrades') || '';
      var grades = this.get('grades').find(function (grade) {
        return grade.get('id') === selectedGrades;
      });
      // let grades = selectedGrades.split(',').reduce((prev, item) => {
      //     let grade = this.get('grades').find(grade => grade.get('id') === item);
      //     if (grade) {
      //         prev.push(grade);
      //     }
      //     return prev;
      // }, []);
      var selectedWeeks = this.get('selectedWeeks') || '';
      var weeks = this.get('weeks').find(function (week) {
        return _ember['default'].get(week, 'id') === selectedWeeks;
      });
      // let weeks = selectedWeeks.split(',').reduce((prev, item) => {
      //     let week = this.get('weeks').find(week => week.get('id') === item);
      //     if (week) {
      //         prev.push(week);
      //     }
      //     return prev;
      // }, []);
      var selectedUserTypes = this.get('selectedUserTypes') || '';
      var userTypes = this.get('userTypes').find(function (userType) {
        return _ember['default'].get(userType, 'id') === selectedUserTypes;
      });
      // let userTypes = selectedUserTypes.split(',').reduce((prev, item) => {
      //     let userType = this.get('userTypes').find(userType => userType.id === item);
      //     if (userType) {
      //         prev.push(userType);
      //     }
      //     return prev;
      // }, []);
      this.set('localSelectedGrades', grades);
      this.set('localSelectedWeeks', weeks);
      this.set('localSelectedUserTypes', userTypes);
    }).on('willInsertElement'),

    actions: {
      userTypeChanged: function userTypeChanged() {
        // let userTypes = this.get('localSelectedUserTypes').reduce((prev, item) => {
        //     return prev ? prev + ',' + item.id : item.id;
        // }, '');
        var userTypes = this.get('localSelectedUserTypes');
        userTypes = userTypes ? userTypes.id : null;
        this.set('page', 1);
        this.set('selectedUserTypes', userTypes);
      },
      weekChanged: function weekChanged() {
        // let weeks = this.get('localSelectedWeeks').reduce((prev, item) => {
        //     return prev ? prev + ',' + item.get('id') : item.get('id');
        // }, '');
        var weeks = this.get('localSelectedWeeks');
        weeks = weeks ? weeks.get('id') : null;
        this.set('page', 1);
        this.set('selectedWeeks', weeks);
      },
      gradeChanged: function gradeChanged() {
        // let grades = this.get('localSelectedGrades').reduce((prev, item) => {
        //     return prev ? prev + ',' + item.get('id') : item.get('id');
        // }, '');
        var grades = this.get('localSelectedGrades');
        grades = grades ? grades.get('id') : null;
        this.set('page', 1);
        this.set('selectedGrades', grades);
      },
      prev: function prev() {
        this.set('page', this.get('page') - 1);
      },
      next: function next() {
        this.set('page', this.get('page') + 1);
      },
      sortBy: function sortBy(sort) {
        if (sort === this.get('sortType')) {
          sort = this.get('sortDir') === 'desc' ? sort : '-' + sort;
        }
        this.set('sort', sort);
      },
      setVideoUrl: function setVideoUrl(videoUrl) {
        this.set('videoUrl', videoUrl);
      }
    }
  });
});