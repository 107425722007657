define('vimme/controllers/student/questions/question', ['exports', 'ember', 'npm:jwt-decode'], function (exports, _ember, _npmJwtDecode) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    questionsController: _ember['default'].inject.controller('student.questions'),
    isReviewing: _ember['default'].computed.alias('questionsController.isReviewing'),

    // set by route
    studentTest: null,

    // set by route
    studentQuestion: null,
    studentQuestions: null,
    studentAnswers: _ember['default'].computed.alias('studentQuestion.answers'),

    // set by route
    textAnswer: null,

    showError: false,

    isSaving: _ember['default'].computed.alias('questionsController.isSaving'),

    isSavable: _ember['default'].computed('textAnswer', 'studentQuestion.textAnswer', 'model.isTextQuestion', 'isSaving', function () {
      var isTextQuestion = this.get('model.isTextQuestion');
      var newTextAnswer = this.get('textAnswer');
      var oldTextAnswer = this.get('studentQuestion.textAnswer');

      if (this.get('isSaving')) {
        return false;
      }

      if (!isTextQuestion) {
        return true;
      }

      return !!newTextAnswer && newTextAnswer !== oldTextAnswer;
    }),
    isUnsavable: _ember['default'].computed.not('isSavable'),

    submitText: _ember['default'].computed('isSavable', 'isSaving', 'textAnswer', 'studentQuestion.textAnswer', function () {
      var newTextAnswer = this.get('textAnswer'),
          oldTextAnswer = this.get('studentQuestion.textAnswer'),
          isSavable = this.get('isSavable');

      if (this.get('isSaving')) {
        return 'Saving';
      }

      if (isSavable || !newTextAnswer && !oldTextAnswer) {
        return 'Submit';
      }

      return 'Saved';
    }),

    actions: {
      saveTextAnswer: function saveTextAnswer() {
        var _this = this;

        var token = this.get('session.data.authenticated.token');

        var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

        var id = _ref.id;

        if (!this.get('isSavable')) {
          return;
        }

        this.set('isSaving', true);
        this.set('showError', false);

        var studentQuestion = this.get('studentQuestion');

        if (studentQuestion) {
          studentQuestion.set('textAnswer', this.get('textAnswer'));

          studentQuestion.save()['catch'](function (error) {
            _this.set('showError', true);

            if (error.status === 403) {
              _this.transitionToRoute('student.testing-calendar');
            }
          })['finally'](function () {
            _this.set('isSaving', false);
          });
        } else {
          this.store.find('student', id).then(function (student) {
            studentQuestion = _this.store.createRecord('studentQuestion', {
              textAnswer: _this.get('textAnswer'),
              student: student,
              question: _this.get('model'),
              studentTest: _this.get('studentTest')
            });

            studentQuestion.save().then(function (studentQuestion) {
              _this.get('studentQuestions').addObject(studentQuestion);
              _this.set('studentQuestion', studentQuestion);
            })['catch'](function (error) {
              studentQuestion.deleteRecord();

              this.set('showError', true);

              if (error.status === 403) {
                this.transitionToRoute('student.testing-calendar');
              }
            })['finally'](function () {
              _this.set('isSaving', false);
            });
          });
        }
      },

      saveAnswer: function saveAnswer(answer, isSelected) {
        var _this2 = this;

        var token = this.get('session.data.authenticated.token');

        var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

        var id = _ref2.id;

        if (!this.get('isSavable')) {
          return;
        }

        var isMultipleQuestion = this.get('model.isMultipleQuestion'),
            studentQuestion = this.get('studentQuestion'),
            answers = this.get('studentQuestion.answers'),
            prevAnswers = answers ? answers.toArray() : [];

        this.set('isSaving', true);

        if (!isSelected) {
          if (studentQuestion) {
            if (!isMultipleQuestion) {
              answers.clear();
            }
            answers.addObject(answer);
            studentQuestion.save()['catch'](function (error) {
              answers.removeObject(answer);
              if (!isMultipleQuestion) {
                answers.addObjects(prevAnswers);
              }
              _this2.set('showError', true);
              if (error.status === 403) {
                _this2.transitionToRoute('student.testing-calendar');
              }
            })['finally'](function () {
              _this2.set('isSaving', false);
            });
          } else {
            this.store.find('student', id).then(function (student) {
              studentQuestion = _this2.store.createRecord('studentQuestion', {
                student: student,
                question: _this2.get('model'),
                studentTest: _this2.get('studentTest')
              });
              studentQuestion.get('answers').addObject(answer);
              studentQuestion.save().then(function (studentQuestion) {
                _this2.set('studentQuestion', studentQuestion);
                _this2.get('studentQuestions').addObject(studentQuestion);
              })['catch'](function (error) {
                studentQuestion.deleteRecord();
                _this2.set('showError', true);
                if (error.status === 403) {
                  _this2.transitionToRoute('student.testing-calendar');
                }
              })['finally'](function () {
                _this2.set('isSaving', false);
              });
            });
          }
        } else {
          if (studentQuestion) {
            if (isMultipleQuestion) {
              answers.removeObject(answer);
              studentQuestion.save()['catch'](function (error) {
                answers.addObject(answer);
                _this2.set('showError', true);
                if (error.status === 403) {
                  _this2.transitionToRoute('student.testing-calendar');
                }
              })['finally'](function () {
                _this2.set('isSaving', false);
              });
            } else {
              this.set('isSaving', false);
            }
          } else {
            this.set('isSaving', false);
          }
        }
      }
    }
  });
});