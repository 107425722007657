define('vimme/models/test', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    reviewTopics: _emberData['default'].attr('string'),
    total: _emberData['default'].attr('number'),
    isPractice: _emberData['default'].attr('boolean'),
    isDraft: _emberData['default'].attr('boolean'),

    week: _emberData['default'].belongsTo('week', { async: true }),
    grade: _emberData['default'].belongsTo('grade', { async: true }),
    standards: _emberData['default'].hasMany('standard', { async: true }),
    questions: _emberData['default'].hasMany('question', { async: true }),
    studentQuestions: _emberData['default'].hasMany('studentQuestion', { async: true }),
    studentTests: _emberData['default'].hasMany('studentTest', { async: true }),
    scheduledTests: _emberData['default'].hasMany('scheduledTest', { async: true }),
    answers: _emberData['default'].hasMany('answer', { async: true }),

    createdAt: _emberData['default'].attr('isodate'),
    updatedAt: _emberData['default'].attr('isodate'),

    titleWithGrade: (function () {
      return this.get('title') + ' (Grade ' + this.get('grade.id') + ')';
    }).property('title', 'grade')
  });
});