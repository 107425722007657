define('vimme/routes/guardian/pacing-guides', ['exports', 'ember', 'vimme/routes/guardian'], function (exports, _ember, _vimmeRoutesGuardian) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model() {
      var grades = this.store.findAll('grade');

      return _ember['default'].RSVP.hash({
        grades: grades
      });
    }
  });
});