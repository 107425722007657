define('vimme/controllers/teacher/testing-calendar', ['exports', 'ember', 'vimme/config/environment', 'npm:jwt-decode'], function (exports, _ember, _vimmeConfigEnvironment, _npmJwtDecode) {

  var guideType = _vimmeConfigEnvironment['default'].environment === 'core' ? 'VCL' : 'VL';

  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),

    sortScheduledTestProps: ['startDate'],
    sortedScheduledTests: _ember['default'].computed.sort('model.scheduledTests', 'sortScheduledTestProps'),

    filteredTests: _ember['default'].computed('model.tests.@each.id', 'sortedScheduledTests.@each.test.id', function () {
      var _this = this;

      return this.get('model.tests').filter(function (test) {
        return !_this.get('sortedScheduledTests').find(function (st) {
          return st.get('test.id') === test.get('id');
        });
      });
    }),

    guideType: _ember['default'].computed('model.school.isTrial', function () {
      return this.get('model.school.isTrial') ? 'free-' + _vimmeConfigEnvironment['default'].trialPeriod : guideType;
    }),

    sortedTests: _ember['default'].computed.sort('filteredTests', function (a, b) {
      return Number(a.get('week.id')) - Number(b.get('week.id'));
    }),

    pacingGuide: _ember['default'].computed('session.data.authenticated.token', 'guideType', function () {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var grade = _ref.grade;

      return this.assets.resolve('assets/pacing-guides/grade-' + grade + '-pacing-guide-' + this.get('guideType') + '.pdf');
    })
  });
});