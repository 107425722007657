define('vimme/components/vimme-percent/component', ['exports', 'ember', 'vimme/utils/round'], function (exports, _ember, _vimmeUtilsRound) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    total: 0,
    score: 0,

    percent: _ember['default'].computed('total', 'score', function () {
      if (!this.get('total')) {
        return 0;
      }
      return this.get('score') / this.get('total') * 100;
    }),

    roundedPercent: _ember['default'].computed('percent', function () {
      return (0, _vimmeUtilsRound['default'])(this.get('percent'));
    }),

    formattedPercent: _ember['default'].computed('roundedPercent', function () {
      return this.get('roundedPercent') + '%';
    })
  });
});