define('vimme/routes/sub-admin/quiz-builder', ['exports', 'vimme/routes/sub-admin', 'ember'], function (exports, _vimmeRoutesSubAdmin, _ember) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      var subAdmin = this.modelFor('sub-admin').user;
      return _ember['default'].RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        quizzes: subAdmin.get('quizzes'),
        subAdmin: subAdmin
      });
    }
  });
});