define('vimme/components/vimme-search-no-results', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',

    classNames: ['vimme-search-no-results'],

    attributeBindings: ['tabindex'],

    tabindex: -1
  });
});