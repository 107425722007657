define('vimme/utils/color', ['exports', 'vimme/constants/color'], function (exports, _vimmeConstantsColor) {
  exports['default'] = color;

  function color(score, grade) {
    var match = _vimmeConstantsColor.scores[parseInt(grade) || 0].find(function (_ref) {
      var max = _ref.max;

      return score >= max;
    });

    return match ? match.color : undefined;
  }
});