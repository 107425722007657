define('vimme/routes/school-admin/schedule-tests', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        tests: _this.modelFor('schoolAdmin').tests,
        scheduledTests: _this.store.findAll('scheduledTest').then(function (scheduledTests) {
          return scheduledTests.reduce(function (prev, scheduledTest) {
            if (!scheduledTest.get('teacher.isPlaceholder')) {
              prev.addObject(scheduledTest);
            }
            return prev;
          }, []);
        }),
        teachers: _this.store.findAll('teacher').then(function (teachers) {
          return teachers.reduce(function (prev, teacher) {
            if (!teacher.get('isPlaceholder')) {
              prev.addObject(teacher);
            }
            return prev;
          }, []);
        }),
        grades: _this.store.findAll('grade')
      });
    }
  });
});