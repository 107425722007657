define('vimme/controllers/admin/management/school-admins', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    schoolAdminsIndexController: _ember['default'].inject.controller('admin/management/school-admins/index'),

    actions: {
      newSchoolAdmin: function newSchoolAdmin() {
        if (this.get('applicationController.currentRouteName') !== 'admin.management.school-admins.index') {
          this.transitionToRoute('admin.management.school-admins');
        }

        var index = this.get('schoolAdminsIndexController');

        index.set('lastName', '');
        index.set('firstName', '');
        index.set('school', null);
        index.set('username', '');
        index.set('password', '');
      }
    },

    unsorted: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var schoolAdmins = this.get('model.schoolAdmins') || [],
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = schoolAdmins.filter(function (schoolAdmin) {
          return filter.test(schoolAdmin.get('fullName'));
        });
      } else {
        unsorted = schoolAdmins;
      }
      return unsorted;
    }).property('searchTerm', 'model.schoolAdmins.[]'),
    filterProps: ['lastName'],
    filteredSchoolAdmins: _ember['default'].computed.sort('unsorted', 'filterProps')
  });
});