define('vimme/routes/school-admin/management/teachers/teacher', ['exports', 'ember', 'vimme/routes/school-admin'], function (exports, _ember, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model(params) {
      var _this = this;

      var teacher = this.store.find('teacher', params.teacher_id);
      var students = teacher.then(function (teacher) {
        return _this.modelFor('school-admin.management.teachers').students.filter(function (student) {
          return student.get('grade.id') === teacher.get('grade.id') && student.get('teacher.isPlaceholder');
        }).sortBy('lastThenFirst');
      });
      return _ember['default'].RSVP.hash({
        students: students,
        teacher: teacher,
        grades: this.modelFor('school-admin.management.teachers').grades
      });
    }
  });
});