define("vimme/components/app-pages/student/quizzes/quiz-wrapper/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 10,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/student/quizzes/quiz-wrapper/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/student/quizzes/quiz-review", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.studentQuestions", ["loc", [null, [3, 25], [3, 46]]]]], [], []], "quiz", ["subexpr", "@mut", [["get", "this.quiz", ["loc", [null, [4, 13], [4, 22]]]]], [], []], "studentQuiz", ["subexpr", "@mut", [["get", "this.studentQuiz", ["loc", [null, [5, 20], [5, 36]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [6, 17], [6, 30]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.questions", ["loc", [null, [7, 18], [7, 32]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.number", ["loc", [null, [8, 15], [8, 26]]]]], [], []]], ["loc", [null, [2, 4], [9, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 0
              },
              "end": {
                "line": 20,
                "column": 0
              }
            },
            "moduleName": "vimme/components/app-pages/student/quizzes/quiz-wrapper/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "app-pages/student/quizzes/quiz", [], ["studentQuestions", ["subexpr", "@mut", [["get", "this.studentQuestions", ["loc", [null, [12, 25], [12, 46]]]]], [], []], "quiz", ["subexpr", "@mut", [["get", "this.quiz", ["loc", [null, [13, 13], [13, 22]]]]], [], []], "studentQuiz", ["subexpr", "@mut", [["get", "this.studentQuiz", ["loc", [null, [14, 20], [14, 36]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.question", ["loc", [null, [15, 17], [15, 30]]]]], [], []], "questions", ["subexpr", "@mut", [["get", "this.questions", ["loc", [null, [16, 18], [16, 32]]]]], [], []], "number", ["subexpr", "@mut", [["get", "this.number", ["loc", [null, [17, 15], [17, 26]]]]], [], []], "isFinishDialogVisible", ["subexpr", "@mut", [["get", "this.isFinishDialogVisible", ["loc", [null, [18, 30], [18, 56]]]]], [], []]], ["loc", [null, [11, 4], [19, 6]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/student/quizzes/quiz-wrapper/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.quiz.isTakable", ["loc", [null, [10, 10], [10, 29]]]]], [], 0, null, ["loc", [null, [10, 0], [20, 0]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "vimme/components/app-pages/student/quizzes/quiz-wrapper/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "this.studentQuiz.isComplete", ["loc", [null, [1, 11], [1, 38]]]], ["get", "this.isFinishDialogHidden", ["loc", [null, [1, 39], [1, 64]]]]], [], ["loc", [null, [1, 6], [1, 65]]]]], [], 0, 1, ["loc", [null, [1, 0], [20, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});