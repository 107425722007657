define('vimme/routes/teacher/builder/quizzes/quiz/question', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model(params) {
      var question = this.store.find('new-quiz-question', params.question_id);
      return _ember['default'].RSVP.hash({
        question: question
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('new-quiz-question-answer').filterBy('id', null).invoke('deleteRecord');
        controller.get('model.question.newQuizQuestionAnswers').invoke('rollback');
      }
    }
  });
});