define('vimme/helpers/attempt-color', ['exports', 'ember'], function (exports, _ember) {
  exports.color = color;

  function color(params /*, hash*/) {
    if (params[0].passed && params[0].attempts) {
      return 'benchmark';
    } else if (params[0].attempts) {
      return 'intensive';
    } else {
      return '';
    }
  }

  exports['default'] = _ember['default'].HTMLBars.makeBoundHelper(color);
});