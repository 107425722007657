define('vimme/components/app-pages/teacher/reports/quiz/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    store: _ember['default'].inject.service(),

    quizId: '',
    quizzes: _ember['default'].computed(function () {
      return [];
    }),

    newQuiz: undefined,
    newQuizQuestions: _ember['default'].computed(function () {
      return [];
    }),
    studentNewQuizzes: _ember['default'].computed(function () {
      return [];
    }),
    studentNewQuizQuestions: _ember['default'].computed(function () {
      return [];
    }),

    scoreAverage: _ember['default'].computed('studentNewQuizzes.@each.score', function () {
      var sum = this.get('studentNewQuizzes').reduce(function (s, sq) {
        return s + sq.get('score');
      }, 0);
      return sum / this.get('studentNewQuizzes.length');
    }),

    isLoading: false,

    reset: function reset() {
      this.setProperties({
        newQuiz: undefined,
        newQuizQuestions: [],
        studentNewQuizzes: [],
        studentNewQuizQuestions: [],
        isLoading: false
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('quizId')) {
        (function () {
          var newQuiz = _this.get('quizzes').find(function (quiz) {
            return quiz.get('id') === _this.get('quizId');
          });
          if (newQuiz) {
            _this.set('isLoading', true);
            _ember['default'].RSVP.hash({
              newQuiz: newQuiz,
              newQuizQuestions: newQuiz.get('newQuizQuestions'),
              studentNewQuizzes: newQuiz.get('studentNewQuizzes'),
              studentNewQuizQuestions: newQuiz.get('studentNewQuizQuestions')
            }).then(function (_ref) {
              var newQuizQuestions = _ref.newQuizQuestions;
              var studentNewQuizzes = _ref.studentNewQuizzes;
              var studentNewQuizQuestions = _ref.studentNewQuizQuestions;

              _this.setProperties({
                newQuiz: newQuiz,
                newQuizQuestions: newQuizQuestions,
                studentNewQuizzes: studentNewQuizzes,
                studentNewQuizQuestions: studentNewQuizQuestions,
                isLoading: false
              });
            })['catch'](function (err) {
              console.warn(err);
              _this.reset();
              alert('Something went wrong loading your report!');
            });
          } else {
            _this.reset();
          }
        })();
      } else {
        this.reset();
      }
    },

    actions: {
      showQuestion: function showQuestion(studentQuiz, padded) {
        this.set('openQuiz', studentQuiz);
        this.set('openQuestion', padded);
      },
      hideQuestion: function hideQuestion() {
        this.set('openQuiz', null);
        this.set('openQuestion', null);
      },
      setQuickQuestion: function setQuickQuestion(quickQuestion, element) {
        this.set('quickQuestion', quickQuestion);
        if (element) {
          var rect = _ember['default'].$(element).get(0).getBoundingClientRect();
          this.set('alignLeft', rect.right);
          this.set('alignTop', rect.top - 1);
        }
      }
    }
  });
});