define('vimme/components/vimme-printable-quiz-break', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['vimme-printable-quiz-break'],
    classNameBindings: ['break'],

    index: 0,
    total: 0,

    'break': (function () {
      var index = this.get('index') + 1;
      return index !== this.get('total') && !(index % 6);
    }).property('index')
  });
});