define("vimme/templates/student/questions/question", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/student/questions/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 17], [3, 22]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "studentQuestion", ["loc", [null, [4, 24], [4, 39]]]]], [], []], "isReviewing", ["subexpr", "@mut", [["get", "isReviewing", ["loc", [null, [5, 20], [5, 31]]]]], [], []], "showAudio", ["subexpr", "and", [["get", "this.student.enableAudio", ["loc", [null, [6, 23], [6, 47]]]], ["subexpr", "not", [["get", "this.school.isTrial", ["loc", [null, [6, 53], [6, 72]]]]], [], ["loc", [null, [6, 48], [6, 73]]]]], [], ["loc", [null, [6, 18], [6, 74]]]]], ["loc", [null, [2, 4], [7, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 0
              },
              "end": {
                "line": 13,
                "column": 0
              }
            },
            "moduleName": "vimme/templates/student/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "vimme-audio", [], ["src", ["subexpr", "@mut", [["get", "model.audioPath", ["loc", [null, [12, 22], [12, 37]]]]], [], []], "style", "position: absolute; top: -32px; left: 50%; margin-left: -14px;"], ["loc", [null, [12, 4], [12, 110]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 12
                },
                "end": {
                  "line": 27,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/student/questions/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "vimme-answer", [], ["showAudio", ["subexpr", "and", [["subexpr", "not", [["get", "this.school.isTrial", ["loc", [null, [21, 40], [21, 59]]]]], [], ["loc", [null, [21, 35], [21, 60]]]], ["get", "this.student.enableAudio", ["loc", [null, [21, 61], [21, 85]]]], ["get", "model.answersAudio", ["loc", [null, [21, 86], [21, 104]]]], ["subexpr", "not", [["get", "model.noAudio", ["loc", [null, [21, 110], [21, 123]]]]], [], ["loc", [null, [21, 105], [21, 124]]]]], [], ["loc", [null, [21, 30], [21, 125]]]], "answer", ["subexpr", "@mut", [["get", "questionAnswer", ["loc", [null, [22, 27], [22, 41]]]]], [], []], "studentAnswers", ["subexpr", "@mut", [["get", "studentAnswers", ["loc", [null, [23, 35], [23, 49]]]]], [], []], "isSavable", ["subexpr", "@mut", [["get", "isSavable", ["loc", [null, [24, 30], [24, 39]]]]], [], []], "action", "saveAnswer"], ["loc", [null, [20, 16], [26, 18]]]]],
            locals: ["questionAnswer"],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 29,
                "column": 4
              }
            },
            "moduleName": "vimme/templates/student/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h4");
            dom.setAttribute(el1, "class", "answer-header");
            var el2 = dom.createTextNode("Answer choices:");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ol");
            dom.setAttribute(el1, "class", "question-answers");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.answers", ["loc", [null, [19, 20], [19, 33]]]]], [], 0, null, ["loc", [null, [19, 12], [27, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 4
              },
              "end": {
                "line": 33,
                "column": 4
              }
            },
            "moduleName": "vimme/templates/student/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-text-answer");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("button");
            dom.setAttribute(el2, "class", "btn btn-submit-answer");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "input-inliner");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element2, 'disabled');
            morphs[1] = dom.createElementMorph(element2);
            morphs[2] = dom.createMorphAt(element2, 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element1, [2]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "isUnsavable", ["loc", [null, [31, 61], [31, 72]]]]], ["element", "action", ["saveTextAnswer"], [], ["loc", [null, [31, 75], [31, 102]]]], ["content", "submitText", ["loc", [null, [31, 103], [31, 117]]]], ["inline", "answer-input", [], ["grade", ["subexpr", "@mut", [["get", "test.grade.id", ["loc", [null, [31, 174], [31, 187]]]]], [], []], "value", ["subexpr", "@mut", [["get", "textAnswer", ["loc", [null, [31, 194], [31, 204]]]]], [], []], "placeholder", "Enter answer here...", "class", "input input-submit-answer"], ["loc", [null, [31, 153], [31, 275]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 35,
                  "column": 8
                },
                "end": {
                  "line": 37,
                  "column": 8
                }
              },
              "moduleName": "vimme/templates/student/questions/question.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-error");
              var el2 = dom.createTextNode("Oops! Your answer wasn't saved.");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Please click submit again or refresh the page.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 8
                },
                "end": {
                  "line": 39,
                  "column": 8
                }
              },
              "moduleName": "vimme/templates/student/questions/question.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-error");
              var el2 = dom.createTextNode("Oops! Your answer wasn't saved.");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Please select your answer again or refresh the page.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 40,
                "column": 4
              }
            },
            "moduleName": "vimme/templates/student/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "model.isTextQuestion", ["loc", [null, [35, 14], [35, 34]]]]], [], 0, 1, ["loc", [null, [35, 8], [39, 15]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 3
              },
              "end": {
                "line": 46,
                "column": 0
              }
            },
            "moduleName": "vimme/templates/student/questions/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-image");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "alt", "");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["concat", [["get", "model.src", ["loc", [null, [44, 20], [44, 29]]]]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/student/questions/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "position: relative;");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [2]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createAttrMorph(element4, 'class');
          morphs[2] = dom.createUnsafeMorphAt(element4, 1, 1);
          morphs[3] = dom.createMorphAt(element4, 3, 3);
          morphs[4] = dom.createMorphAt(element4, 4, 4);
          morphs[5] = dom.createMorphAt(element3, 4, 4);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "and", [["subexpr", "not", [["get", "this.school.isTrial", ["loc", [null, [11, 16], [11, 35]]]]], [], ["loc", [null, [11, 11], [11, 36]]]], ["get", "this.student.enableAudio", ["loc", [null, [11, 37], [11, 61]]]], ["get", "model.audioPath", ["loc", [null, [11, 62], [11, 77]]]], ["subexpr", "not", [["get", "model.noAudio", ["loc", [null, [11, 83], [11, 96]]]]], [], ["loc", [null, [11, 78], [11, 97]]]]], [], ["loc", [null, [11, 6], [11, 98]]]]], [], 0, null, ["loc", [null, [11, 0], [13, 7]]]], ["attribute", "class", ["concat", ["question-text ", ["subexpr", "if", [["get", "model.isVertical", ["loc", [null, [14, 31], [14, 47]]]], "is-vertical"], [], ["loc", [null, [14, 26], [14, 63]]]]]]], ["content", "model.title", ["loc", [null, [15, 4], [15, 21]]]], ["block", "unless", [["get", "model.isTextQuestion", ["loc", [null, [16, 14], [16, 34]]]]], [], 1, 2, ["loc", [null, [16, 4], [33, 15]]]], ["block", "if", [["get", "showError", ["loc", [null, [34, 10], [34, 19]]]]], [], 3, null, ["loc", [null, [34, 4], [40, 11]]]], ["block", "if", [["get", "model.hasImage", ["loc", [null, [42, 9], [42, 23]]]]], [], 4, null, ["loc", [null, [42, 3], [46, 7]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 48,
            "column": 7
          }
        },
        "moduleName": "vimme/templates/student/questions/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [1, 6], [1, 17]]]]], [], 0, 1, ["loc", [null, [1, 0], [48, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});