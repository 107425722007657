define('vimme/components/vimme-zone-date-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['vimme-zone-date-row', 'tr'],

    scheduledTest: null,

    selected: false,

    actions: {
      toggle: function toggle(scheduledTest) {
        if (this.get('selected')) {
          this.sendAction('remove', scheduledTest);
        } else {
          this.sendAction('add', scheduledTest);
        }
        this.toggleProperty('selected');
      }
    }
  });
});