define('vimme/controllers/school-admin/management/students/student', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authAjax: _ember['default'].inject.service(),

    showDialogAccessCode: false,
    showDialogStudent: false,
    showDialogScore: false,
    showDialogTransfer: false,
    showDialogPassword: false,

    password: '',
    passwordConfirmation: '',

    newAccessCode: '',

    selectedScore: null,

    noSelectedScore: _ember['default'].computed.not('selectedScore'),

    grade: null,
    teacher: null,

    teachersByGrade: _ember['default'].computed.filter('model.teachers', function (teacher) {
      return teacher.get('grade.id') === this.get('model.student.grade.id') && !teacher.get('isPlaceholder');
    }),

    setTeacher: (function () {
      var teachers = this.get('model.teachers');
      var student = this.get('model.student');

      if (teachers) {
        var teacher = teachers.filterBy('isPlaceholder', false).findBy('id', student.get('teacher.id'));
        if (!teacher) {
          teacher = teachers.filterBy('grade.id', student.get('grade.id')).get('firstObject');
        }
        this.set('teacher', teacher);
      }
    }).on('init').observes('model.{student.teacher.id,teachers}'),

    setGrade: (function () {
      var grades = this.get('model.grades');
      var student = this.get('model.student');

      if (grades) {
        var grade = grades.findBy('id', student.get('grade.id'));
        this.set('grade', grade);
      }
    }).on('init').observes('model.{student.grade.id,grades}'),

    isClean: _ember['default'].computed.not('model.student.isDirty'),

    studentTestSorting: ['scheduledTest.startDate'],
    sortedStudentTests: _ember['default'].computed.sort('model.student.studentTests', 'studentTestSorting'),

    inProgress: _ember['default'].computed.filterBy('model.student.studentTests', 'isComplete', false),

    resetSelectedScore: (function () {
      this.set('selectedScore', null);
    }).on('init').observes('model.id'),

    validPassword: _ember['default'].computed('password', 'passwordConfirmation', function () {
      var password = this.get('password'),
          passwordConfirmation = this.get('passwordConfirmation');

      return !!password && password.length >= 5 && password === passwordConfirmation;
    }),
    invalidPassword: _ember['default'].computed.not('validPassword'),

    isValid: (function () {
      var firstName = (this.get('model.student.firstName') + '').trim(),
          lastName = (this.get('model.student.lastName') + '').trim();

      return !!firstName && !!lastName;
    }).property('model.student.{firstName,lastName}'),

    isUnsavable: (function () {
      return this.get('isClean') || !this.get('isValid');
    }).property('isClean', 'isValid'),

    actions: {
      onToggleAudio: function onToggleAudio(e) {
        e.preventDefault();
        this.toggleProperty('model.student.enableAudio');
      },
      createParentCode: function createParentCode(student) {
        var _this = this;

        var accessCode = this.store.createRecord('accessCode', {
          student: student
        });

        accessCode.save().then(function (accessCode) {
          _this.set('newAccessCode', accessCode.get('code'));
          _this.set('showDialogAccessCode', true);
        });
      },

      showDialogAccessCode: function showDialogAccessCode() {
        this.set('showDialogAccessCode', true);
      },

      cancelDialogAccessCode: function cancelDialogAccessCode() {
        this.set('newAccessCode', '');
        this.set('showDialogAccessCode', false);
      },

      showDialogStudent: function showDialogStudent() {
        this.set('showDialogStudent', true);
      },

      cancelDialogStudent: function cancelDialogStudent() {
        this.set('showDialogStudent', false);
      },

      showDialogScore: function showDialogScore() {
        this.set('showDialogScore', true);
      },

      cancelDialogScore: function cancelDialogScore() {
        this.set('showDialogScore', false);
      },

      showDialogTransfer: function showDialogTransfer() {
        this.set('showDialogTransfer', true);
      },

      cancelDialogTransfer: function cancelDialogTransfer() {
        this.set('showDialogTransfer', false);
      },

      showDialogPassword: function showDialogPassword() {
        this.set('showDialogPassword', true);
      },

      cancelDialogPassword: function cancelDialogPassword() {
        this.set('showDialogPassword', false);
      },

      showDialogGrade: function showDialogGrade() {
        this.set('showDialogGrade', true);
      },

      cancelDialogGrade: function cancelDialogGrade() {
        this.set('showDialogGrade', false);
      },

      setSelected: function setSelected(score) {
        this.set('selectedScore', score);
      },

      save: function save(student) {
        student.save();
      },

      'delete': function _delete(student) {
        var _this2 = this;

        student.destroyRecord().then(function () {
          _this2.set('showDialogStudent', false);
          _this2.transitionToRoute('school-admin.management.students');
        })['catch'](function () {
          student.rollback();
        });
      },

      unassign: function unassign(student) {
        var _this3 = this;

        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/unassigned-transfers', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            unassigned_transfer: {
              student_id: student.get('id')
            }
          })
        }).then(function (student) {
          _this3.store.pushPayload(student);
          _this3.store.find('student', student.student.id).then(function (student) {
            return student.get('studentTests').reload();
          });
          _this3.set('showDialogStudent', false);
          _this3.set('password', '');
          _this3.set('passwordConfirmation', '');
        })['catch'](function (error) {
          var message = _ember['default'].get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      },

      deleteScore: function deleteScore(selectedTest) {
        var _this4 = this;

        selectedTest.destroyRecord().then(function () {
          _this4.set('selectedScore', null);
          _this4.set('showDialogScore', false);
        })['catch'](function () {
          selectedTest.rollback();
        });
      },

      changePassword: function changePassword(password, passwordConfirmation) {
        var _this5 = this;

        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/students/' + this.get('model.student.id') + '/password', {
          type: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            student: {
              password: password,
              password_confirmation: passwordConfirmation
            }
          })
        }).then(function (student) {
          _this5.store.pushPayload(student);
          _this5.set('showDialogPassword', false);
          _this5.set('password', '');
          _this5.set('passwordConfirmation', '');
        })['catch'](function (error) {
          var message = _ember['default'].get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      },

      transfer: function transfer(teacher) {
        var _this6 = this;

        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/student-transfers', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            student_transfer: {
              student_id: this.get('model.student.id'),
              teacher_id: teacher.get('id')
            }
          })
        }).then(function (student) {
          _this6.store.pushPayload(student);
          _this6.store.find('student', student.student.id).then(function (student) {
            return student.get('studentTests').reload();
          });
          _this6.set('showDialogTransfer', false);
          _this6.set('password', '');
          _this6.set('passwordConfirmation', '');
        })['catch'](function (error) {
          var message = _ember['default'].get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      },

      changeGrade: function changeGrade(grade) {
        var _this7 = this;

        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/grade-transfers', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            grade_transfer: {
              student_id: this.get('model.student.id'),
              grade_id: grade.get('id')
            }
          })
        }).then(function (student) {
          _this7.store.pushPayload(student);
          _this7.store.find('student', student.student.id).then(function (student) {
            return student.get('studentTests').reload();
          });
          _this7.set('showDialogGrade', false);
          _this7.set('password', '');
          _this7.set('passwordConfirmation', '');
        })['catch'](function (error) {
          var message = _ember['default'].get(error, 'responseJSON.message');

          if (message) {
            alert(message);
          } else {
            alert('An error has occurred.');
          }
        });
      }
    }
  });
});
// TODO: don't use isDirty from ember data (hasDirtyAttributes instead!)