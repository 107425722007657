define('vimme/controllers/school-admin/management/teachers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    schoolAdminController: _ember['default'].inject.controller('schoolAdmin'),
    school: _ember['default'].computed.alias('schoolAdminController.model.user.school'),

    lastName: '',
    firstName: '',
    grade: null,
    email: '',
    password: '',

    username: _ember['default'].computed.alias('email'),

    resetVals: (function () {
      this.set('lastName', '');
      this.set('firstName', '');
      this.set('grade', null);
      this.set('email', '');
      this.set('password', '');
    }).on('init'),

    isValid: (function () {
      var lastName = this.get('lastName'),
          firstName = this.get('firstName'),
          grade = this.get('grade'),
          email = this.get('email'),
          password = this.get('password');

      return !!lastName && !!firstName && !!grade && !!email && !!password && password.length >= 5;
    }).property('lastName', 'firstName', 'email', 'password', 'grade'),

    isUnsavable: _ember['default'].computed.not('isValid'),

    actions: {
      save: function save() {
        var _this = this;

        this.get('school').then(function (school) {
          var teacher = _this.store.createRecord('teacher', {
            email: _this.get('email'),
            firstName: _this.get('firstName'),
            grade: _this.get('grade'),
            isPlaceholder: false,
            lastName: _this.get('lastName'),
            password: _this.get('password'),
            school: school,
            username: _this.get('username')
          });

          teacher.save().then(function (teacher) {
            _this.transitionToRoute('school-admin.management.teachers.teacher', teacher.get('id'));
          })['catch'](function () {
            teacher.deleteRecord();
          });
        });
      }
    }
  });
});