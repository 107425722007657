define('vimme/routes/school-admin', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode', 'vimme/utils/embed-zendesk'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmJwtDecode, _vimmeUtilsEmbedZendesk) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authenticationRoute: 'index',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      if (role !== 'SchoolAdmin') {
        this.replaceWith('index');
      } else if (transition.targetName === 'school-admin.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.schedule-tests');
        } else {
          this.transitionTo('school-admin.schedule-tests');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref2.role;
      var id = _ref2.id;

      var tests = this.store.all('test');

      return _ember['default'].RSVP.hash({
        tests: tests.get('length') ? tests : this.store.findAll('test'),
        user: this.store.find(role, id)
      });
    },
    afterModel: function afterModel(model) {
      var user = model.user;
      (0, _vimmeUtilsEmbedZendesk['default'])();
      if (user) {
        window.zE(function () {
          window.zE.identify({
            name: user.get('fullName'),
            email: user.get('email')
          });
        });
      }
      this._super.apply(this, arguments);
    }
  });
});