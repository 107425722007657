define('vimme/routes/student/test', ['exports', 'vimme/routes/student', 'ember', 'npm:jwt-decode'], function (exports, _vimmeRoutesStudent, _ember, _npmJwtDecode) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    session: _ember['default'].inject.service(),
    findScheduledTest: function findScheduledTest(scheduledTests) {
      if (!scheduledTests || scheduledTests.get('length') <= 0) {
        throw 'Missing Teacher Test';
      }
      return scheduledTests.get('firstObject');
    },
    findStudentsAndStudentTests: function findStudentsAndStudentTests(scheduledTest) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var id = _ref.id;

      if (!scheduledTest.get('isActive') && !scheduledTest.get('isReviewable')) {
        throw 'Test should not be viewable';
      }
      return _ember['default'].RSVP.hash({
        scheduledTest: scheduledTest,
        studentTests: scheduledTest.get('studentTests'),
        student: this.store.find('student', id)
      });
    },
    findOrCreateStudentTest: function findOrCreateStudentTest(_ref2) {
      var scheduledTest = _ref2.scheduledTest;
      var studentTests = _ref2.studentTests;
      var student = _ref2.student;

      var studentTest;
      if (studentTests && studentTests.get('length') > 0) {
        studentTest = studentTests.get('firstObject');
      }

      if (studentTest) {
        if (studentTest.get('isComplete') && !scheduledTest.get('isReviewable')) {
          throw 'Test is not reviewable';
        }
        if (!studentTest.get('isComplete') && !scheduledTest.get('isActive')) {
          throw 'Test cannot be continued because scheduledTest is inactive';
        }
      } else {
        if (!scheduledTest.get('isActive')) {
          throw 'Cannot create student test for inactive teacher test.';
        }
        studentTest = this.store.createRecord('studentTest', {
          scheduledTest: scheduledTest,
          student: student
        }).save();
      }

      return _ember['default'].RSVP.hash({
        studentTests: studentTests,
        studentTest: studentTest
      });
    },

    model: function model(params) {
      return this.store.find('test', params.test_id);
    },
    afterModel: function afterModel(test, transition) {
      var _this = this;

      return test.get('scheduledTests').then(this.findScheduledTest).then(this.findStudentsAndStudentTests.bind(this)).then(this.findOrCreateStudentTest.bind(this)).then(function (_ref3) {
        var studentTests = _ref3.studentTests;
        var studentTest = _ref3.studentTest;

        studentTests.pushObject(studentTest);
        if (transition.targetName === 'student.test.index') {
          _this.replaceWith('student.questions');
        }
      })['catch'](function () /*err*/{
        // console.log(err);
        _this.store.all('studentTest').filterBy('id', null).invoke('deleteRecord');
        _this.replaceWith('student.testing-calendar');
      });
    },

    actions: {
      error: function error() /*error, transition*/{
        // console.log(error);
        this.store.all('studentTest').filterBy('id', null).invoke('deleteRecord');
        this.replaceWith('student.testing-calendar');
      }
    }
  });
});