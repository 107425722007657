define("vimme/templates/teacher/builder/quizzes/quiz/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/builder/quizzes/quiz/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "app-pages/teacher/quiz-builder/quiz", [], ["quiz", ["subexpr", "@mut", [["get", "this.model.quiz", ["loc", [null, [2, 9], [2, 24]]]]], [], []], "teachers", ["subexpr", "@mut", [["get", "this.model.teachers", ["loc", [null, [3, 13], [3, 32]]]]], [], []], "weeks", ["subexpr", "@mut", [["get", "this.model.weeks", ["loc", [null, [4, 10], [4, 26]]]]], [], []]], ["loc", [null, [1, 0], [5, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});