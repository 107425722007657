define("vimme/components/app-pages/teacher/reports/quiz/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "loading-frame");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loader");
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "loader-text");
          var el4 = dom.createTextNode("Loading...");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 20
                },
                "end": {
                  "line": 29,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createTextNode("Average");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["content", "p.formattedPercent", ["loc", [null, [28, 42], [28, 64]]]]],
            locals: ["p"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 31,
                  "column": 16
                },
                "end": {
                  "line": 36,
                  "column": 17
                }
              },
              "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "app-pages/teacher/reports/quiz/student-row", [], ["quiz", ["subexpr", "@mut", [["get", "this.newQuiz", ["loc", [null, [33, 29], [33, 41]]]]], [], []], "studentQuiz", ["subexpr", "@mut", [["get", "studentQuiz", ["loc", [null, [34, 36], [34, 47]]]]], [], []]], ["loc", [null, [32, 20], [35, 22]]]]],
            locals: ["studentQuiz"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 20
                },
                "end": {
                  "line": 44,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                       ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell-header no-hover");
              var el2 = dom.createTextNode("Q");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "newQuizQuestion.number", ["loc", [null, [43, 58], [43, 84]]]]],
            locals: ["newQuizQuestion"],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 47,
                  "column": 20
                },
                "end": {
                  "line": 52,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "app-pages/teacher/reports/quiz/average-row", [], ["question", ["subexpr", "@mut", [["get", "newQuizQuestion", ["loc", [null, [49, 37], [49, 52]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "this.studentNewQuizQuestions", ["loc", [null, [50, 45], [50, 73]]]]], [], []]], ["loc", [null, [48, 24], [51, 26]]]]],
            locals: ["newQuizQuestion"],
            templates: []
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 62,
                      "column": 28
                    },
                    "end": {
                      "line": 68,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "app-pages/teacher/reports/quiz/question-cell", [], ["paddedStudentQuestion", ["subexpr", "@mut", [["get", "paddedStudentQuestion", ["loc", [null, [64, 58], [64, 79]]]]], [], []], "clickMe", ["subexpr", "action", ["showQuestion", ["get", "studentQuiz", ["loc", [null, [65, 67], [65, 78]]]]], [], ["loc", [null, [65, 44], [65, 79]]]], "hoverMe", ["subexpr", "action", ["setQuickQuestion"], [], ["loc", [null, [66, 44], [66, 71]]]]], ["loc", [null, [63, 32], [67, 34]]]]],
                locals: ["paddedStudentQuestion"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 20
                  },
                  "end": {
                    "line": 70,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [62, 36], [62, 58]]]]], [], 0, null, ["loc", [null, [62, 28], [68, 37]]]]],
              locals: ["paddedStudentQuestions"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 54,
                  "column": 16
                },
                "end": {
                  "line": 71,
                  "column": 16
                }
              },
              "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "app-pages/teacher/reports/quiz/question-row", [], ["questions", ["subexpr", "@mut", [["get", "this.newQuizQuestions", ["loc", [null, [56, 34], [56, 55]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "this.studentNewQuizQuestions", ["loc", [null, [57, 41], [57, 69]]]]], [], []], "studentQuiz", ["subexpr", "@mut", [["get", "studentQuiz", ["loc", [null, [58, 36], [58, 47]]]]], [], []]], 0, null, ["loc", [null, [55, 20], [70, 68]]]]],
            locals: ["studentQuiz"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 4
              },
              "end": {
                "line": 74,
                "column": 4
              }
            },
            "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column no-hover");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header score-column no-hover");
            var el5 = dom.createTextNode("Total");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 1]);
            var element3 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[1] = dom.createMorphAt(element2, 5, 5);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
            morphs[4] = dom.createMorphAt(element3, 5, 5);
            return morphs;
          },
          statements: [["block", "vimme-percent", [], ["score", ["subexpr", "@mut", [["get", "this.scoreAverage", ["loc", [null, [26, 43], [26, 60]]]]], [], []], "total", ["subexpr", "@mut", [["get", "this.newQuiz.total", ["loc", [null, [26, 67], [26, 85]]]]], [], []]], 0, null, ["loc", [null, [26, 20], [29, 38]]]], ["block", "each", [["get", "this.studentNewQuizzes", ["loc", [null, [31, 24], [31, 46]]]]], [], 1, null, ["loc", [null, [31, 16], [36, 26]]]], ["block", "each", [["get", "this.newQuizQuestions", ["loc", [null, [42, 28], [42, 49]]]]], [], 2, null, ["loc", [null, [42, 20], [44, 29]]]], ["block", "each", [["get", "this.newQuizQuestions", ["loc", [null, [47, 28], [47, 49]]]]], [], 3, null, ["loc", [null, [47, 20], [52, 29]]]], ["block", "each", [["get", "this.studentNewQuizzes", ["loc", [null, [54, 24], [54, 46]]]]], [], 4, null, ["loc", [null, [54, 16], [71, 25]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 74,
              "column": 4
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "this.studentNewQuizzes", ["loc", [null, [18, 14], [18, 36]]]]], [], 0, null, ["loc", [null, [18, 4], [74, 4]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 0
            },
            "end": {
              "line": 91,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog question-dialog");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "btn btn-icon-only btn-dialog-exit icon-delete");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message test-question");
          var el4 = dom.createTextNode("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideQuestion"], [], ["loc", [null, [80, 15], [80, 40]]]], ["content", "this.openQuiz.student.lastThenFirst", ["loc", [null, [81, 37], [81, 76]]]], ["content", "this.newQuiz.title", ["loc", [null, [82, 37], [82, 59]]]], ["inline", "app-pages/teacher/quizzes/quiz-review/question", [], ["question", ["subexpr", "@mut", [["get", "this.openQuestion.studentQuestion.newQuizQuestion", ["loc", [null, [85, 29], [85, 78]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "this.openQuestion.studentQuestion", ["loc", [null, [86, 36], [86, 69]]]]], [], []]], ["loc", [null, [84, 16], [87, 18]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 0
            },
            "end": {
              "line": 101,
              "column": 0
            }
          },
          "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "app-pages/teacher/reports/quiz/quick-question", [], ["studentQuestion", ["subexpr", "@mut", [["get", "this.quickQuestion.studentQuestion", ["loc", [null, [95, 24], [95, 58]]]]], [], []], "question", ["subexpr", "@mut", [["get", "this.quickQuestion.studentQuestion.newQuizQuestion", ["loc", [null, [96, 17], [96, 67]]]]], [], []], "answers", ["subexpr", "@mut", [["get", "this.quickQuestion.studentQuestion.newQuizQuestion.newQuizQuestionAnswers", ["loc", [null, [97, 16], [97, 89]]]]], [], []], "alignLeft", ["subexpr", "@mut", [["get", "this.alignLeft", ["loc", [null, [98, 18], [98, 32]]]]], [], []], "alignTop", ["subexpr", "@mut", [["get", "this.alignTop", ["loc", [null, [99, 17], [99, 30]]]]], [], []]], ["loc", [null, [94, 4], [100, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 102,
            "column": 0
          }
        },
        "moduleName": "vimme/components/app-pages/teacher/reports/quiz/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "filter-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "half");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element4, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "ember-selectize", [], ["content", ["subexpr", "@mut", [["get", "this.quizzes", ["loc", [null, [5, 24], [5, 36]]]]], [], []], "optionValuePath", "content.id", "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "this.quizId", ["loc", [null, [8, 22], [8, 33]]]]], [], []], "placeholder", "Select quiz..."], ["loc", [null, [4, 12], [10, 14]]]], ["block", "if", [["get", "this.isLoading", ["loc", [null, [14, 10], [14, 24]]]]], [], 0, 1, ["loc", [null, [14, 4], [74, 11]]]], ["block", "if", [["subexpr", "and", [["get", "this.openQuestion", ["loc", [null, [77, 11], [77, 28]]]], ["get", "this.openQuiz", ["loc", [null, [77, 29], [77, 42]]]]], [], ["loc", [null, [77, 6], [77, 43]]]]], [], 2, null, ["loc", [null, [77, 0], [91, 7]]]], ["block", "if", [["get", "this.quickQuestion", ["loc", [null, [93, 6], [93, 24]]]]], [], 3, null, ["loc", [null, [93, 0], [101, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});