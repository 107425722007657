define('vimme/controllers/admin/test-data/test', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isClean: _ember['default'].computed.not('model.isDirty'),

    actions: {
      save: function save() {
        var test = this.get('model');

        test.save();
      }
    }
  });
});
// TODO: don't use isDirty from ember data (hasDirtyAttributes instead!)