define('vimme/routes/sub-admin/reports/mathbots/index', ['exports', 'ember', 'vimme/routes/sub-admin', 'vimme/config/environment'], function (exports, _ember, _vimmeRoutesSubAdmin, _vimmeConfigEnvironment) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    queryParams: {
      teacher: {
        refreshModel: true
      },
      grade: {
        refreshModel: true
      },
      school: {
        refreshModel: true
      }
    },

    authAjax: _ember['default'].inject.service(),
    model: function model(_ref) {
      var teacherId = _ref.teacher;
      var gradeId = _ref.grade;
      var schoolId = _ref.school;

      var schools = this.modelFor('sub-admin.reports').schools;
      var grades = this.modelFor('sub-admin.reports').grades;
      var teachers = gradeId ? this.store.find('teacher', { grade: gradeId, school: schoolId }).then(function (teachers) {
        return teachers.filterBy('isPlaceholder', false);
      }) : [];
      var teacher = teacherId ? this.store.find('teacher', teacherId) : null;
      var grade = gradeId ? this.store.find('grade', gradeId) : null;
      var school = schoolId ? this.store.find('school', schoolId) : null;
      if (!teacher) {
        return _ember['default'].RSVP.hash({
          grades: grades,
          grade: grade,
          schools: schools,
          school: school,
          teachers: teachers,
          teacher: teacher
        });
      }
      var levels = this.store.findAll('level');
      var students = teacher.then(function (teacher) {
        return teacher.get('students');
      });
      return this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.gameHost + '/reports/teacher?teacher=' + teacherId).then(function (_ref2) {
        var attempts = _ref2.attempts;

        return _ember['default'].RSVP.hash({
          levels: levels,
          students: students,
          attempts: attempts
        }).then(function (_ref3) {
          var levels = _ref3.levels;
          var students = _ref3.students;
          var attempts = _ref3.attempts;

          students = students.sortBy('lastThenFirst');
          var averages = levels.map(function (level) {
            var passed = 0;
            var total = 0;
            students.forEach(function (student) {
              var studentAttempts = attempts[student.get('userId')];
              var studentLevel = studentAttempts && studentAttempts[level.get('id')];
              if (studentLevel && studentLevel.passed) {
                passed++;
              }
              total++;
            });
            return Math.round(passed / total * 100);
          });
          return _ember['default'].RSVP.hash({
            levels: levels,
            students: students,
            attempts: attempts,
            averages: averages,
            teachers: teachers,
            teacher: teacher,
            grades: grades,
            grade: grade,
            schools: schools,
            school: school
          });
        });
      });
    }
  });
});