define('vimme/routes/student/resources', ['exports', 'vimme/routes/student', 'ember', 'npm:jwt-decode'], function (exports, _vimmeRoutesStudent, _ember, _npmJwtDecode) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    session: _ember['default'].inject.service(),
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var id = _ref.id;

      var student = this.store.findRecord('student', id);
      var week0 = _ember['default'].Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var localWeeks = this.store.all('week');
      var weeks = [week0];
      if (localWeeks.get('length')) {
        weeks = weeks.concat(localWeeks.toArray());
      } else {
        weeks = this.store.findAll('week').then(function (moreWeeks) {
          return weeks.concat(moreWeeks.toArray());
        });
      }
      return student.then(function (student) {
        return _ember['default'].RSVP.hash({
          grade: student.get('grade.id'),
          weeks: weeks
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!controller.get('grades')) {
        controller.set('grades', model.grade);
      }
    }
  });
});