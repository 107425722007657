define('vimme/controllers/sub-admin/reports/mathbots-competition', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['school', 'grade', 'teacher'],

    school: null,
    grade: null,
    teacher: null,

    actions: {
      setSchool: function setSchool(school) {
        this.setProperties({
          school: school.get('id'),
          teacher: null
        });
      },
      setGrade: function setGrade(grade) {
        this.setProperties({
          grade: grade.get('id'),
          teacher: null
        });
      },
      setTeacher: function setTeacher(teacher) {
        this.set('teacher', teacher.get('id'));
      }
    }
  });
});