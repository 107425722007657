define('vimme/models/student-new-quiz-question', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    isCorrect: _emberData['default'].attr('boolean', { defaultValue: false }),
    textAnswer: _emberData['default'].attr('string'),

    studentId: _emberData['default'].attr('string'),
    student: _emberData['default'].belongsTo('student', { async: true }),

    newQuizQuestion: _emberData['default'].belongsTo('newQuizQuestion', { async: true }),

    studentNewQuiz: _emberData['default'].belongsTo('studentNewQuiz', { async: true }),

    newQuizQuestionAnswers: _emberData['default'].hasMany('newQuizQuestionAnswer'),

    correctIcon: _ember['default'].computed('isCorrect', function () {
      return this.get('isCorrect') ? 'icon-check' : 'icon-delete';
    })
  });
});