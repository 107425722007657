define('vimme/controllers/sub-admin/questions/question', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    questionsController: _ember['default'].inject.controller('sub-admin/questions'),

    studentQuestions: _ember['default'].computed.alias('questionsController.studentQuestions'),

    isReviewing: _ember['default'].computed.alias('questionsController.isReviewing'),

    studentQuestion: (function () {
      var studentQuestions = this.get('studentQuestions');

      if (studentQuestions && studentQuestions.get('length') > 0) {
        return studentQuestions.findBy('question.id', this.get('content.id'));
      }
    }).property('studentQuestions.[]', 'content')
  });
});