define("vimme/templates/school-admin/reports/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 12
              },
              "end": {
                "line": 17,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [12, 28], [12, 36]]]]], [], []], "optionLabelPath", "content.lastThenFirst", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [14, 26], [14, 41]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [11, 16], [16, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 18,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [5, 24], [5, 36]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [7, 22], [7, 35]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [4, 12], [9, 14]]]], ["block", "if", [["get", "showTeachers", ["loc", [null, [10, 18], [10, 30]]]]], [], 0, null, ["loc", [null, [10, 12], [17, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 16
              },
              "end": {
                "line": 22,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "grade-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedGrade.title", ["loc", [null, [21, 46], [21, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 16
              },
              "end": {
                "line": 25,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "teacher-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedTeacher.lastThenFirst", ["loc", [null, [24, 48], [24, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element34 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element34, 1, 1);
          morphs[1] = dom.createMorphAt(element34, 2, 2);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedGrade", ["loc", [null, [20, 22], [20, 35]]]]], [], 0, null, ["loc", [null, [20, 16], [22, 23]]]], ["block", "if", [["get", "selectedTeacher", ["loc", [null, [23, 22], [23, 37]]]]], [], 1, null, ["loc", [null, [23, 16], [25, 23]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 28
                    },
                    "end": {
                      "line": 49,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element26 = dom.childAt(fragment, [1]);
                  var element27 = dom.childAt(element26, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element27, 'class');
                  morphs[2] = dom.createMorphAt(element27, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [46, 54], [46, 77]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [47, 55], [47, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [47, 64], [47, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 43,
                    "column": 24
                  },
                  "end": {
                    "line": 50,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [44, 57], [44, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [44, 77], [44, 84]]]]], [], []]], 0, null, ["loc", [null, [44, 28], [49, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 20
                },
                "end": {
                  "line": 51,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header grade-column");
              var el3 = dom.createTextNode("Grade");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element28 = dom.childAt(fragment, [1]);
              var element29 = dom.childAt(element28, [1]);
              var element30 = dom.childAt(element28, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element28, 'class');
              morphs[1] = dom.createElementMorph(element29);
              morphs[2] = dom.createElementMorph(element30);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [39, 49], [39, 66]]]]]]], ["element", "action", ["sortAveragesOther", "grade-column"], [], ["loc", [null, [40, 66], [40, 111]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [41, 68], [41, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [43, 32], [43, 40]]]]], [], 0, null, ["loc", [null, [43, 24], [50, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 28
                    },
                    "end": {
                      "line": 63,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var element22 = dom.childAt(element21, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element22, 'class');
                  morphs[2] = dom.createMorphAt(element22, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirst", ["loc", [null, [60, 54], [60, 87]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [61, 55], [61, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [61, 64], [61, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 24
                  },
                  "end": {
                    "line": 64,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [58, 57], [58, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [58, 77], [58, 84]]]]], [], []]], 0, null, ["loc", [null, [58, 28], [63, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 20
                },
                "end": {
                  "line": 65,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header teacher-column");
              var el3 = dom.createTextNode("Teacher");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var element24 = dom.childAt(element23, [1]);
              var element25 = dom.childAt(element23, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element23, 'class');
              morphs[1] = dom.createElementMorph(element24);
              morphs[2] = dom.createElementMorph(element25);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [53, 49], [53, 66]]]]]]], ["element", "action", ["sortAveragesOther", "teacher-column"], [], ["loc", [null, [54, 68], [54, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [55, 68], [55, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [57, 32], [57, 40]]]]], [], 0, null, ["loc", [null, [57, 24], [64, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 28
                    },
                    "end": {
                      "line": 77,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element16 = dom.childAt(fragment, [1]);
                  var element17 = dom.childAt(element16, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element17, 'class');
                  morphs[2] = dom.createMorphAt(element17, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [74, 54], [74, 87]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [75, 55], [75, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [75, 64], [75, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 24
                  },
                  "end": {
                    "line": 78,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [72, 57], [72, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [72, 77], [72, 84]]]]], [], []]], 0, null, ["loc", [null, [72, 28], [77, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 66,
                  "column": 20
                },
                "end": {
                  "line": 79,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header student-column");
              var el3 = dom.createTextNode("Student");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var element19 = dom.childAt(element18, [1]);
              var element20 = dom.childAt(element18, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element18, 'class');
              morphs[1] = dom.createElementMorph(element19);
              morphs[2] = dom.createElementMorph(element20);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [67, 49], [67, 66]]]]]]], ["element", "action", ["sortAveragesOther", "student-column"], [], ["loc", [null, [68, 68], [68, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [69, 68], [69, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [71, 32], [71, 40]]]]], [], 0, null, ["loc", [null, [71, 24], [78, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 24
                },
                "end": {
                  "line": 87,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element15, 'class');
              morphs[1] = dom.createElementMorph(element15);
              morphs[2] = dom.createMorphAt(element15, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [86, 54], [86, 68]]]]]]], ["element", "action", ["sortAveragesWeek", ["get", "week", ["loc", [null, [86, 100], [86, 104]]]]], [], ["loc", [null, [86, 72], [86, 106]]]], ["content", "week.shortName", ["loc", [null, [86, 107], [86, 125]]]]],
            locals: ["week"],
            templates: []
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 92,
                      "column": 32
                    },
                    "end": {
                      "line": 94,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element14 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element14, 'class');
                  morphs[1] = dom.createMorphAt(element14, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.color", ["loc", [null, [93, 55], [93, 80]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [93, 84], [93, 132]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 90,
                    "column": 24
                  },
                  "end": {
                    "line": 96,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [92, 40], [92, 60]]]]], [], 0, null, ["loc", [null, [92, 32], [94, 41]]]]],
              locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 89,
                  "column": 20
                },
                "end": {
                  "line": 97,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [90, 53], [90, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [90, 73], [90, 80]]]]], [], []]], 0, null, ["loc", [null, [90, 24], [96, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child5 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 101,
                  "column": 16
                },
                "end": {
                  "line": 103,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "processing-state");
              var el2 = dom.createTextNode("Ending of school year is ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("...");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "if", [["get", "processingSchoolYear", ["loc", [null, [102, 81], [102, 101]]]], "processing", "queued"], [], ["loc", [null, [102, 76], [102, 125]]]]],
            locals: [],
            templates: []
          };
        })();
        var child6 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 103,
                  "column": 16
                },
                "end": {
                  "line": 105,
                  "column": 16
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn btn-cancel btn-end-school-year icon-delete");
              var el2 = dom.createTextNode("End School Year");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element13 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createElementMorph(element13);
              return morphs;
            },
            statements: [["element", "action", ["showDialogEndSchoolYear"], [], ["loc", [null, [104, 28], [104, 64]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 8
              },
              "end": {
                "line": 107,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "end-school-year");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1, 1]);
            var element32 = dom.childAt(fragment, [3, 1]);
            var element33 = dom.childAt(element32, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(element31, 1, 1);
            morphs[1] = dom.createMorphAt(element31, 2, 2);
            morphs[2] = dom.createMorphAt(element31, 3, 3);
            morphs[3] = dom.createAttrMorph(element33, 'class');
            morphs[4] = dom.createMorphAt(element33, 1, 1);
            morphs[5] = dom.createMorphAt(element32, 3, 3);
            morphs[6] = dom.createMorphAt(dom.childAt(fragment, [5]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [38, 26], [38, 43]]]]], [], 0, null, ["loc", [null, [38, 20], [51, 27]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [52, 26], [52, 45]]]]], [], 1, null, ["loc", [null, [52, 20], [65, 27]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [66, 26], [66, 45]]]]], [], 2, null, ["loc", [null, [66, 20], [79, 27]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [84, 45], [84, 62]]]]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [85, 32], [85, 43]]]]], [], 3, null, ["loc", [null, [85, 24], [87, 33]]]], ["block", "each", [["get", "averages", ["loc", [null, [89, 28], [89, 36]]]]], [], 4, null, ["loc", [null, [89, 20], [97, 29]]]], ["block", "if", [["get", "inProgressSchoolYear", ["loc", [null, [101, 22], [101, 42]]]]], [], 5, 6, ["loc", [null, [101, 16], [105, 23]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5, child6]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 4
            },
            "end": {
              "line": 108,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [35, 14], [35, 22]]]]], [], 0, null, ["loc", [null, [35, 8], [107, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 119,
                        "column": 32
                      },
                      "end": {
                        "line": 121,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element10 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element10, 1, 1);
                    morphs[1] = dom.createMorphAt(element10, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [120, 62], [120, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [120, 91], [120, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 112,
                      "column": 20
                    },
                    "end": {
                      "line": 124,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Grade: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1]);
                  var element12 = dom.childAt(element11, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element12, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element12, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [115, 75], [115, 98]]]], ["content", "formattedPercent", ["loc", [null, [116, 76], [116, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [119, 40], [119, 60]]]]], [], 0, null, ["loc", [null, [119, 32], [121, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 16
                  },
                  "end": {
                    "line": 125,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [112, 49], [112, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [112, 69], [112, 76]]]]], [], []]], 0, null, ["loc", [null, [112, 20], [124, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 12
                },
                "end": {
                  "line": 126,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [111, 24], [111, 32]]]]], [], 0, null, ["loc", [null, [111, 16], [125, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 136,
                        "column": 32
                      },
                      "end": {
                        "line": 138,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element7, 1, 1);
                    morphs[1] = dom.createMorphAt(element7, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [137, 62], [137, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [137, 91], [137, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 129,
                      "column": 20
                    },
                    "end": {
                      "line": 141,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Teacher: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var element9 = dom.childAt(element8, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirst", ["loc", [null, [132, 77], [132, 110]]]], ["content", "formattedPercent", ["loc", [null, [133, 76], [133, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [136, 40], [136, 60]]]]], [], 0, null, ["loc", [null, [136, 32], [138, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 128,
                    "column": 16
                  },
                  "end": {
                    "line": 142,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [129, 49], [129, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [129, 69], [129, 76]]]]], [], []]], 0, null, ["loc", [null, [129, 20], [141, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 127,
                  "column": 12
                },
                "end": {
                  "line": 143,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [128, 24], [128, 32]]]]], [], 0, null, ["loc", [null, [128, 16], [142, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 153,
                        "column": 32
                      },
                      "end": {
                        "line": 155,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element4, 1, 1);
                    morphs[1] = dom.createMorphAt(element4, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [154, 62], [154, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [154, 91], [154, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 146,
                      "column": 20
                    },
                    "end": {
                      "line": 158,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Student: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [149, 77], [149, 110]]]], ["content", "formattedPercent", ["loc", [null, [150, 76], [150, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [153, 40], [153, 60]]]]], [], 0, null, ["loc", [null, [153, 32], [155, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 145,
                    "column": 16
                  },
                  "end": {
                    "line": 159,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [146, 49], [146, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [146, 69], [146, 76]]]]], [], []]], 0, null, ["loc", [null, [146, 20], [158, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 144,
                  "column": 12
                },
                "end": {
                  "line": 160,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [145, 24], [145, 32]]]]], [], 0, null, ["loc", [null, [145, 16], [159, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 109,
                "column": 8
              },
              "end": {
                "line": 161,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [110, 18], [110, 35]]]]], [], 0, null, ["loc", [null, [110, 12], [126, 19]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [127, 18], [127, 37]]]]], [], 1, null, ["loc", [null, [127, 12], [143, 19]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [144, 18], [144, 37]]]]], [], 2, null, ["loc", [null, [144, 12], [160, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 108,
              "column": 4
            },
            "end": {
              "line": 162,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [109, 14], [109, 22]]]]], [], 0, null, ["loc", [null, [109, 8], [161, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 165,
              "column": 0
            },
            "end": {
              "line": 188,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog test-dialog dialog-end-school-year");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner test-dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("End School Year");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4, "class", "dialog-header");
          var el5 = dom.createTextNode("This will generate end of year reports, delete all currently scheduled tests plus results, and move students to the next grade with unassigned teachers.\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("The process will take up to 24 hours. You will recieve an email when complete.\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("Are you sure you really want to do this?");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "dialog-text");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "input-container");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6, "for", "school-year");
          dom.setAttribute(el6, "class", "label");
          var el7 = dom.createTextNode("School Year Name");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-buttons");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn btn-cancel icon-delete");
          var el5 = dom.createTextNode("Cancel");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn btn-ok icon-check");
          var el5 = dom.createTextNode("End Year");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [5]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 3, 1]), 3, 3);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createAttrMorph(element3, 'disabled');
          morphs[3] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "schoolYearName", ["loc", [null, [177, 34], [177, 48]]]]], [], []], "id", "school-year", "class", "input"], ["loc", [null, [177, 20], [177, 81]]]], ["element", "action", ["cancelDialogEndSchoolYear"], [], ["loc", [null, [182, 55], [182, 93]]]], ["attribute", "disabled", ["get", "endSchoolYearInvalid", ["loc", [null, [183, 103], [183, 123]]]]], ["element", "action", ["endSchoolYear", ["get", "schoolYearName", ["loc", [null, [183, 75], [183, 89]]]]], [], ["loc", [null, [183, 50], [183, 91]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 189,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/school-admin/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element35 = dom.childAt(fragment, [0]);
        var element36 = dom.childAt(element35, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element36, 1, 1);
        morphs[1] = dom.createMorphAt(element36, 3, 3);
        morphs[2] = dom.createMorphAt(element36, 5, 5);
        morphs[3] = dom.createMorphAt(element35, 3, 3);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [27, 19]]]], ["inline", "print-view-button", [], ["printView", ["subexpr", "action", ["printView"], [], ["loc", [null, [29, 38], [29, 58]]]], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [29, 65], [29, 70]]]]], [], []]], ["loc", [null, [29, 8], [29, 72]]]], ["inline", "color-key", [], ["grade", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [31, 26], [31, 31]]]]], [], []], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [31, 38], [31, 43]]]]], [], []]], ["loc", [null, [31, 8], [31, 45]]]], ["block", "unless", [["get", "print", ["loc", [null, [34, 14], [34, 19]]]]], [], 2, 3, ["loc", [null, [34, 4], [162, 15]]]], ["block", "if", [["get", "showDialogEndSchoolYear", ["loc", [null, [165, 6], [165, 29]]]]], [], 4, null, ["loc", [null, [165, 0], [188, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});