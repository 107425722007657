define('vimme/models/student', ['exports', 'ember-data', 'vimme/models/user'], function (exports, _emberData, _vimmeModelsUser) {
  exports['default'] = _vimmeModelsUser['default'].extend({
    firstName: _emberData['default'].attr('string'),
    middleInitial: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    schoolStudentId: _emberData['default'].attr('string'),

    enableAudio: _emberData['default'].attr('boolean'),

    userId: _emberData['default'].attr('string'),

    grade: _emberData['default'].belongsTo('grade', { async: true }),
    accessCode: _emberData['default'].belongsTo('accessCode', { async: true }),

    school: _emberData['default'].belongsTo('school', { async: true }),
    teacher: _emberData['default'].belongsTo('teacher', { async: true }),

    guardians: _emberData['default'].hasMany('guardian', { async: true }),
    studentTests: _emberData['default'].hasMany('studentTest', { async: true }),

    studentNewQuizzes: _emberData['default'].hasMany('studentNewQuiz', { async: true }),

    fullName: (function () {
      var MI = this.get('middleInitial');

      if (MI) {
        MI = ' ' + MI.toUpperCase() + '. ';
      } else {
        MI = ' ';
      }

      return this.get('firstName') + MI + this.get('lastName');
    }).property('firstName', 'middleInitial', 'lastName'),

    lastThenFirst: (function () {
      var MI = this.get('middleInitial'),
          last = this.get('lastName'),
          first = this.get('firstName');

      if (MI) {
        MI = ' ' + MI.toUpperCase() + '.';
      } else {
        MI = '';
      }

      if (last && first) {
        return this.get('lastName') + ', ' + this.get('firstName') + MI;
      }

      return '';
    }).property('firstName', 'middleInitial', 'lastName')
  });
});