define('vimme/components/vimme-answer/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['question-answer', 'is-selectable'],
    classNameBindings: ['isSelected', 'isUnsavable'],

    answer: null,
    studentAnswers: null,
    isSavable: null,

    isUnsavable: _ember['default'].computed.not('isSavable'),

    studentAnswerIds: _ember['default'].computed.mapBy('studentAnswers', 'id'),

    isSelected: (function () {
      var studentAnswerIds = this.get('studentAnswerIds');

      if (studentAnswerIds && studentAnswerIds.get('length') > 0) {
        if (studentAnswerIds.indexOf(this.get('answer.id')) >= 0) {
          return true;
        }
      }

      return false;
    }).property('studentAnswerIds.[]'),

    click: function click() {
      if (this.get('isSavable')) {
        this.sendAction('action', this.get('answer'), this.get('isSelected'));
      }
    }
  });
});