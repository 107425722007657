define('vimme/routes/teacher/builder/paper-quizzes/paper-quiz', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        categories: this.modelFor('teacher.builder.paper-quizzes').categories,
        difficulties: this.modelFor('teacher.builder.paper-quizzes').difficulties,
        grades: this.modelFor('teacher.builder.paper-quizzes').grades,
        quiz: this.store.find('quiz', params.paper_quiz_id),
        teacher: this.modelFor('teacher.builder.paper-quizzes').teacher
      });
    },
    resetController: function resetController(controller) {
      var quiz = controller.get('model.quiz');
      var quizQuestions = quiz.get('quizQuestions');
      quiz.rollback();
      quizQuestions.reload();
    }
  });
});