define('vimme/routes/teacher/reports/week', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      return _ember['default'].RSVP.hash({
        weeks: this.modelFor('teacher.reports').weeks,
        teacherAveragesRaw: this.store.findAll('perTeacherAverage'),
        studentTests: this.store.findAll('studentTest'),
        students: teacher.get('students')
      });
    }
  });
});