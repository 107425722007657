define('vimme/routes/school-admin/test/print', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        answers: _this.modelFor('school-admin.test').get('answers'),
        questions: _this.modelFor('school-admin.test').get('questions')
      });
    }
  });
});