define('vimme/components/vimme-hoverable-icon', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    classNames: ['vimme-hoverable-icon'],

    value: null,

    inMouseArea: false,

    click: function click() {
      if (!this.get('inMouseArea')) {
        this.sendAction('action', this.get('value'), this.get('element'));
      }
    },
    mouseEnter: function mouseEnter() {
      this.sendAction('action', this.get('value'), this.get('element'));
      this.set('inMouseArea', true);
    },
    mouseLeave: function mouseLeave() {
      this.sendAction('action', this.get('value'));
      this.set('inMouseArea', false);
    }
  });
});