define("vimme/templates/admin/reports/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 16
              },
              "end": {
                "line": 11,
                "column": 124
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Week View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 16
              },
              "end": {
                "line": 12,
                "column": 132
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "selected-box icon-delete");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("Question View");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 12
              },
              "end": {
                "line": 27,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [22, 28], [22, 40]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [24, 26], [24, 39]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [21, 16], [26, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 12
              },
              "end": {
                "line": 35,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [30, 28], [30, 36]]]]], [], []], "optionLabelPath", "content.lastThenFirst", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [32, 26], [32, 41]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [29, 16], [34, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "view-choices");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element35 = dom.childAt(fragment, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(element35, 1, 1);
          morphs[2] = dom.createMorphAt(element35, 3, 3);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "schoolYears", ["loc", [null, [5, 24], [5, 35]]]]], [], []], "optionLabelPath", "content.nameSchool", "value", ["subexpr", "@mut", [["get", "selectedSchoolYear", ["loc", [null, [7, 22], [7, 40]]]]], [], []], "prompt", "Current Year"], ["loc", [null, [4, 12], [9, 14]]]], ["block", "link-to", ["admin.reports.week"], ["class", "view-choice"], 0, null, ["loc", [null, [11, 16], [11, 136]]]], ["block", "link-to", ["admin.reports.question"], ["class", "view-choice"], 1, null, ["loc", [null, [12, 16], [12, 144]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.schools", ["loc", [null, [15, 24], [15, 37]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedSchool", ["loc", [null, [17, 22], [17, 36]]]]], [], []], "prompt", "Select School"], ["loc", [null, [14, 12], [19, 14]]]], ["block", "if", [["get", "selectedSchool", ["loc", [null, [20, 18], [20, 32]]]]], [], 2, null, ["loc", [null, [20, 12], [27, 19]]]], ["block", "if", [["get", "showTeachers", ["loc", [null, [28, 18], [28, 30]]]]], [], 3, null, ["loc", [null, [28, 12], [35, 19]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 16
              },
              "end": {
                "line": 40,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "school-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedSchool.title", ["loc", [null, [39, 47], [39, 71]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 16
              },
              "end": {
                "line": 43,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "grade-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedGrade.title", ["loc", [null, [42, 46], [42, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 16
              },
              "end": {
                "line": 46,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "teacher-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedTeacher.lastThenFirst", ["loc", [null, [45, 48], [45, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 36,
              "column": 8
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element34 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element34, 1, 1);
          morphs[1] = dom.createMorphAt(element34, 2, 2);
          morphs[2] = dom.createMorphAt(element34, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedSchool", ["loc", [null, [38, 22], [38, 36]]]]], [], 0, null, ["loc", [null, [38, 16], [40, 23]]]], ["block", "if", [["get", "selectedGrade", ["loc", [null, [41, 22], [41, 35]]]]], [], 1, null, ["loc", [null, [41, 16], [43, 23]]]], ["block", "if", [["get", "selectedTeacher", ["loc", [null, [44, 22], [44, 37]]]]], [], 2, null, ["loc", [null, [44, 16], [46, 23]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 65,
                      "column": 28
                    },
                    "end": {
                      "line": 70,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element26 = dom.childAt(fragment, [1]);
                  var element27 = dom.childAt(element26, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element27, 'class');
                  morphs[2] = dom.createMorphAt(element27, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [67, 54], [67, 77]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [68, 55], [68, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [68, 64], [68, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 24
                  },
                  "end": {
                    "line": 71,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [65, 57], [65, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [65, 77], [65, 84]]]]], [], []]], 0, null, ["loc", [null, [65, 28], [70, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 59,
                  "column": 20
                },
                "end": {
                  "line": 72,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header grade-column");
              var el3 = dom.createTextNode("Grade");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element28 = dom.childAt(fragment, [1]);
              var element29 = dom.childAt(element28, [1]);
              var element30 = dom.childAt(element28, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element28, 'class');
              morphs[1] = dom.createElementMorph(element29);
              morphs[2] = dom.createElementMorph(element30);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [60, 49], [60, 66]]]]]]], ["element", "action", ["sortAveragesOther", "grade-column"], [], ["loc", [null, [61, 66], [61, 111]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [62, 68], [62, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [64, 32], [64, 40]]]]], [], 0, null, ["loc", [null, [64, 24], [71, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 79,
                      "column": 28
                    },
                    "end": {
                      "line": 84,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element21 = dom.childAt(fragment, [1]);
                  var element22 = dom.childAt(element21, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element22, 'class');
                  morphs[2] = dom.createMorphAt(element22, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirst", ["loc", [null, [81, 54], [81, 87]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [82, 55], [82, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [82, 64], [82, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 24
                  },
                  "end": {
                    "line": 85,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [79, 57], [79, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [79, 77], [79, 84]]]]], [], []]], 0, null, ["loc", [null, [79, 28], [84, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 73,
                  "column": 20
                },
                "end": {
                  "line": 86,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header teacher-column");
              var el3 = dom.createTextNode("Teacher");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var element24 = dom.childAt(element23, [1]);
              var element25 = dom.childAt(element23, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element23, 'class');
              morphs[1] = dom.createElementMorph(element24);
              morphs[2] = dom.createElementMorph(element25);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [74, 49], [74, 66]]]]]]], ["element", "action", ["sortAveragesOther", "teacher-column"], [], ["loc", [null, [75, 68], [75, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [76, 68], [76, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [78, 32], [78, 40]]]]], [], 0, null, ["loc", [null, [78, 24], [85, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 93,
                      "column": 28
                    },
                    "end": {
                      "line": 98,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 2,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "row");
                  var el2 = dom.createTextNode("\n                                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "cell");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("\n                                 ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element16 = dom.childAt(fragment, [1]);
                  var element17 = dom.childAt(element16, [3]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element16, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element17, 'class');
                  morphs[2] = dom.createMorphAt(element17, 0, 0);
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [95, 54], [95, 87]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [96, 55], [96, 60]]]]]]], ["content", "formattedPercent", ["loc", [null, [96, 64], [96, 84]]]]],
                locals: ["formattedPercent", "color"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 92,
                    "column": 24
                  },
                  "end": {
                    "line": 99,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [93, 57], [93, 68]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [93, 77], [93, 84]]]]], [], []]], 0, null, ["loc", [null, [93, 28], [98, 52]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 87,
                  "column": 20
                },
                "end": {
                  "line": 100,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header student-column");
              var el3 = dom.createTextNode("Student");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell-header average-column");
              var el3 = dom.createTextNode("Average");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element18 = dom.childAt(fragment, [1]);
              var element19 = dom.childAt(element18, [1]);
              var element20 = dom.childAt(element18, [3]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element18, 'class');
              morphs[1] = dom.createElementMorph(element19);
              morphs[2] = dom.createElementMorph(element20);
              morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [88, 49], [88, 66]]]]]]], ["element", "action", ["sortAveragesOther", "student-column"], [], ["loc", [null, [89, 68], [89, 115]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [90, 68], [90, 115]]]], ["block", "each", [["get", "averages", ["loc", [null, [92, 32], [92, 40]]]]], [], 0, null, ["loc", [null, [92, 24], [99, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 24
                },
                "end": {
                  "line": 108,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element15 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element15, 'class');
              morphs[1] = dom.createElementMorph(element15);
              morphs[2] = dom.createMorphAt(element15, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [107, 54], [107, 68]]]]]]], ["element", "action", ["sortAveragesWeek", ["get", "week", ["loc", [null, [107, 100], [107, 104]]]]], [], ["loc", [null, [107, 72], [107, 106]]]], ["content", "week.shortName", ["loc", [null, [107, 107], [107, 125]]]]],
            locals: ["week"],
            templates: []
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 113,
                      "column": 32
                    },
                    "end": {
                      "line": 115,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element14 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element14, 'class');
                  morphs[1] = dom.createMorphAt(element14, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.color", ["loc", [null, [114, 55], [114, 80]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [114, 84], [114, 132]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 24
                  },
                  "end": {
                    "line": 117,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [113, 40], [113, 60]]]]], [], 0, null, ["loc", [null, [113, 32], [115, 41]]]]],
              locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 110,
                  "column": 20
                },
                "end": {
                  "line": 118,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [111, 53], [111, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [111, 73], [111, 80]]]]], [], []]], 0, null, ["loc", [null, [111, 24], [117, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child5 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 123,
                    "column": 20
                  },
                  "end": {
                    "line": 125,
                    "column": 20
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "processing-state");
                var el2 = dom.createTextNode("Ending of school year is ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("...");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "if", [["get", "processingSchoolYear", ["loc", [null, [124, 85], [124, 105]]]], "processing", "queued"], [], ["loc", [null, [124, 80], [124, 129]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 125,
                    "column": 20
                  },
                  "end": {
                    "line": 127,
                    "column": 20
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-cancel btn-end-school-year icon-delete");
                var el2 = dom.createTextNode("End School Year");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element13 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element13);
                return morphs;
              },
              statements: [["element", "action", ["showDialogEndSchoolYear"], [], ["loc", [null, [126, 32], [126, 68]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 121,
                  "column": 12
                },
                "end": {
                  "line": 129,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "end-school-year");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "inProgressSchoolYear", ["loc", [null, [123, 26], [123, 46]]]]], [], 0, 1, ["loc", [null, [123, 20], [127, 27]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 8
              },
              "end": {
                "line": 130,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element31 = dom.childAt(fragment, [1, 1]);
            var element32 = dom.childAt(fragment, [3, 1]);
            var element33 = dom.childAt(element32, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createMorphAt(element31, 1, 1);
            morphs[1] = dom.createMorphAt(element31, 2, 2);
            morphs[2] = dom.createMorphAt(element31, 3, 3);
            morphs[3] = dom.createAttrMorph(element33, 'class');
            morphs[4] = dom.createMorphAt(element33, 1, 1);
            morphs[5] = dom.createMorphAt(element32, 3, 3);
            morphs[6] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [59, 26], [59, 43]]]]], [], 0, null, ["loc", [null, [59, 20], [72, 27]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [73, 26], [73, 45]]]]], [], 1, null, ["loc", [null, [73, 20], [86, 27]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [87, 26], [87, 45]]]]], [], 2, null, ["loc", [null, [87, 20], [100, 27]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [105, 45], [105, 62]]]]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [106, 32], [106, 43]]]]], [], 3, null, ["loc", [null, [106, 24], [108, 33]]]], ["block", "each", [["get", "averages", ["loc", [null, [110, 28], [110, 36]]]]], [], 4, null, ["loc", [null, [110, 20], [118, 29]]]], ["block", "if", [["get", "selectedSchool", ["loc", [null, [121, 18], [121, 32]]]]], [], 5, null, ["loc", [null, [121, 12], [129, 19]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4, child5]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 4
            },
            "end": {
              "line": 131,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [56, 14], [56, 22]]]]], [], 0, null, ["loc", [null, [56, 8], [130, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 142,
                        "column": 32
                      },
                      "end": {
                        "line": 144,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element10 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element10, 1, 1);
                    morphs[1] = dom.createMorphAt(element10, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [143, 62], [143, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [143, 91], [143, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 135,
                      "column": 20
                    },
                    "end": {
                      "line": 147,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Grade: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element11 = dom.childAt(fragment, [1]);
                  var element12 = dom.childAt(element11, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element12, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element12, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.grade.title", ["loc", [null, [138, 75], [138, 98]]]], ["content", "formattedPercent", ["loc", [null, [139, 76], [139, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [142, 40], [142, 60]]]]], [], 0, null, ["loc", [null, [142, 32], [144, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 134,
                    "column": 16
                  },
                  "end": {
                    "line": 148,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [135, 49], [135, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [135, 69], [135, 76]]]]], [], []]], 0, null, ["loc", [null, [135, 20], [147, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 133,
                  "column": 12
                },
                "end": {
                  "line": 149,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [134, 24], [134, 32]]]]], [], 0, null, ["loc", [null, [134, 16], [148, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 159,
                        "column": 32
                      },
                      "end": {
                        "line": 161,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element7, 1, 1);
                    morphs[1] = dom.createMorphAt(element7, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [160, 62], [160, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [160, 91], [160, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 152,
                      "column": 20
                    },
                    "end": {
                      "line": 164,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Teacher: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element8 = dom.childAt(fragment, [1]);
                  var element9 = dom.childAt(element8, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.teacher.lastThenFirst", ["loc", [null, [155, 77], [155, 110]]]], ["content", "formattedPercent", ["loc", [null, [156, 76], [156, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [159, 40], [159, 60]]]]], [], 0, null, ["loc", [null, [159, 32], [161, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 16
                  },
                  "end": {
                    "line": 165,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [152, 49], [152, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [152, 69], [152, 76]]]]], [], []]], 0, null, ["loc", [null, [152, 20], [164, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 150,
                  "column": 12
                },
                "end": {
                  "line": 166,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [151, 24], [151, 32]]]]], [], 0, null, ["loc", [null, [151, 16], [165, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 176,
                        "column": 32
                      },
                      "end": {
                        "line": 178,
                        "column": 32
                      }
                    },
                    "moduleName": "vimme/templates/admin/reports/week.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                    ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "print-item");
                    var el2 = dom.createTextNode("W");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(": ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element4 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(element4, 1, 1);
                    morphs[1] = dom.createMorphAt(element4, 3, 3);
                    return morphs;
                  },
                  statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [177, 62], [177, 89]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [177, 91], [177, 139]]]]],
                  locals: ["paddedWeeklyAverage"],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 169,
                      "column": 20
                    },
                    "end": {
                      "line": 181,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/templates/admin/reports/week.hbs"
                },
                arity: 3,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "print-row column");
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-general");
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-name");
                  var el4 = dom.createTextNode("Student: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                                ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "print-item print-avg");
                  var el4 = dom.createTextNode("Average: ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "print-details");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
                  return morphs;
                },
                statements: [["content", "average.student.lastThenFirst", ["loc", [null, [172, 77], [172, 110]]]], ["content", "formattedPercent", ["loc", [null, [173, 76], [173, 96]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [176, 40], [176, 60]]]]], [], 0, null, ["loc", [null, [176, 32], [178, 41]]]]],
                locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 168,
                    "column": 16
                  },
                  "end": {
                    "line": 182,
                    "column": 16
                  }
                },
                "moduleName": "vimme/templates/admin/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [169, 49], [169, 60]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [169, 69], [169, 76]]]]], [], []]], 0, null, ["loc", [null, [169, 20], [181, 44]]]]],
              locals: ["average"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 167,
                  "column": 12
                },
                "end": {
                  "line": 183,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/admin/reports/week.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "averages", ["loc", [null, [168, 24], [168, 32]]]]], [], 0, null, ["loc", [null, [168, 16], [182, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 132,
                "column": 8
              },
              "end": {
                "line": 184,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/admin/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showGradeAverages", ["loc", [null, [133, 18], [133, 35]]]]], [], 0, null, ["loc", [null, [133, 12], [149, 19]]]], ["block", "if", [["get", "showTeacherAverages", ["loc", [null, [150, 18], [150, 37]]]]], [], 1, null, ["loc", [null, [150, 12], [166, 19]]]], ["block", "if", [["get", "showStudentAverages", ["loc", [null, [167, 18], [167, 37]]]]], [], 2, null, ["loc", [null, [167, 12], [183, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 131,
              "column": 4
            },
            "end": {
              "line": 185,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [132, 14], [132, 22]]]]], [], 0, null, ["loc", [null, [132, 8], [184, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 188,
              "column": 0
            },
            "end": {
              "line": 211,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/admin/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog test-dialog dialog-end-school-year");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner test-dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("End School Year");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h1");
          dom.setAttribute(el4, "class", "dialog-header");
          var el5 = dom.createTextNode("This will generate end of year reports, delete all currently scheduled tests plus results, and move students to the next grade with unassigned teachers.\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("The process will take up to 24 hours. You will recieve an email when complete.\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("br");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("Are you sure you really want to do this?");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "dialog-text");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "input-container");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          dom.setAttribute(el6, "for", "school-year");
          dom.setAttribute(el6, "class", "label");
          var el7 = dom.createTextNode("School Year Name");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-buttons");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn btn-cancel icon-delete");
          var el5 = dom.createTextNode("Cancel");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn btn-ok icon-check");
          var el5 = dom.createTextNode("End Year");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [5]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [3, 3, 1]), 3, 3);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createAttrMorph(element3, 'disabled');
          morphs[3] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "schoolYearName", ["loc", [null, [200, 34], [200, 48]]]]], [], []], "id", "school-year", "class", "input"], ["loc", [null, [200, 20], [200, 81]]]], ["element", "action", ["cancelDialogEndSchoolYear"], [], ["loc", [null, [205, 55], [205, 93]]]], ["attribute", "disabled", ["get", "endSchoolYearInvalid", ["loc", [null, [206, 103], [206, 123]]]]], ["element", "action", ["endSchoolYear", ["get", "schoolYearName", ["loc", [null, [206, 75], [206, 89]]]]], [], ["loc", [null, [206, 50], [206, 91]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 212,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/admin/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element36 = dom.childAt(fragment, [0]);
        var element37 = dom.childAt(element36, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element37, 1, 1);
        morphs[1] = dom.createMorphAt(element37, 3, 3);
        morphs[2] = dom.createMorphAt(element37, 5, 5);
        morphs[3] = dom.createMorphAt(element36, 3, 3);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [48, 19]]]], ["inline", "print-view-button", [], ["printView", ["subexpr", "action", ["printView"], [], ["loc", [null, [50, 38], [50, 58]]]], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [50, 65], [50, 70]]]]], [], []], "position", "low"], ["loc", [null, [50, 8], [50, 87]]]], ["inline", "color-key", [], ["grade", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [52, 26], [52, 31]]]]], [], []], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [52, 38], [52, 43]]]]], [], []]], ["loc", [null, [52, 8], [52, 45]]]], ["block", "unless", [["get", "print", ["loc", [null, [55, 14], [55, 19]]]]], [], 2, 3, ["loc", [null, [55, 4], [185, 15]]]], ["block", "if", [["get", "showDialogEndSchoolYear", ["loc", [null, [188, 6], [188, 29]]]]], [], 4, null, ["loc", [null, [188, 0], [211, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});