define('vimme/models/standard', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    description: _emberData['default'].attr('string'),
    gradeId: _emberData['default'].attr('number'),

    strand: _emberData['default'].belongsTo('strand', { async: true }),
    questions: _emberData['default'].hasMany('question', { async: true }),
    tests: _emberData['default'].hasMany('test', { async: true }),

    createdAt: _emberData['default'].attr('isodate'),
    updatedAt: _emberData['default'].attr('isodate')
  });
});