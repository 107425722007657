define('vimme/routes/school-admin/reports/yearly', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model(_ref) {
      var school_year_id = _ref.school_year_id;

      var _modelFor = this.modelFor('school-admin.reports');

      var weeks = _modelFor.weeks;
      var grades = _modelFor.grades;
      var schoolYears = _modelFor.schoolYears;

      var schoolYear = schoolYears.findBy('id', school_year_id);
      return _ember['default'].RSVP.hash({
        weeks: weeks,
        grades: grades,
        schoolYear: schoolYear,
        schoolYears: schoolYears,
        schoolYearGradeReports: schoolYear.get('schoolYearGradeReports')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      this.controllerFor('school-admin.reports').set('selectedSchoolYear', model.schoolYear);
      controller.set('averages', model.schoolYearGradeReports);
      controller.set('currentSortDirection', 'desc');
      controller.set('currentSortColumn', 'grade-column');
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        this.controllerFor('school-admin.reports').set('selectedSchoolYear', null);
      }
    }
  });
});