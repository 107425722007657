define('vimme/routes/admin/management/schools/index', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        school: this.store.createRecord('school')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('school').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});