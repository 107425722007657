define('vimme/routes/student/testing-calendar', ['exports', 'vimme/routes/student', 'ember'], function (exports, _vimmeRoutesStudent, _ember) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        tests: _this.modelFor('student').tests,
        scheduledTests: _this.modelFor('student').scheduledTests
      });
    }
  });
});