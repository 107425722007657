define('vimme/routes/school-admin/reports/growth', ['exports', 'vimme/routes/school-admin', 'ember'], function (exports, _vimmeRoutesSchoolAdmin, _ember) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    queryParams: {
      from: {
        replace: true
      },
      to: {
        replace: true
      },
      grade: {
        replace: true
      },
      teacher: {
        replace: true
      }
    },

    model: function model(params) {
      var weeks = this.modelFor('school-admin.reports').weeks;
      var grades = this.modelFor('school-admin.reports').grades;

      var user = this.modelFor('school-admin').user;
      var selectedFrom = params.from ? weeks.find(function (week) {
        return week.get('id') === params.from;
      }) : undefined;
      var selectedTo = params.to ? weeks.find(function (week) {
        return week.get('id') === params.to;
      }) : undefined;
      var selectedGrade = params.grade ? grades.find(function (grade) {
        return grade.get('id') === params.grade;
      }) : undefined;
      var teachers = selectedGrade ? selectedGrade.get('teachers') : undefined;
      var selectedTeacher = params.teacher && selectedGrade ? teachers.then(function (ts) {
        return ts.find(function (teacher) {
          return teacher.get('id') === params.teacher;
        });
      }) : undefined;
      var students = params.teacher && selectedGrade ? selectedTeacher.then(function (t) {
        return t.get('students');
      }) : undefined;

      return _ember['default'].RSVP.hash({
        params: params,
        weeks: weeks,
        grades: grades,
        selectedGrade: selectedGrade,
        selectedTeacher: selectedTeacher,
        selectedFrom: selectedFrom,
        selectedTo: selectedTo,
        students: students,
        teachers: teachers,
        user: user
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedGrade', model.selectedGrade);
      controller.set('selectedTeacher', model.selectedTeacher);
      controller.set('selectedFrom', model.selectedFrom);
      controller.set('selectedTo', model.selectedTo);
      controller.set('students', model.students);
      controller.set('teachers', model.teachers);
      controller.set('selectedTeacher', model.selectedTeacher);
    }
  });
});