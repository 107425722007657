define('vimme/models/quiz-question', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    imagePath: _emberData['default'].attr('string'),

    categories: _emberData['default'].hasMany('category', { async: false }),
    difficulty: _emberData['default'].belongsTo('difficulty', { async: true }),
    grade: _emberData['default'].belongsTo('grade', { async: true }),
    quizzes: _emberData['default'].hasMany('quiz', { async: true }),

    createdAt: _emberData['default'].attr('isodate'),
    updatedAt: _emberData['default'].attr('isodate'),

    categoriesString: (function () {
      var categories = this.get('categories');
      var categoriesString = '';
      if (categories) {
        categoriesString = categories.reduce(function (prev, category) {
          return prev ? prev + ', ' + category.get('title') : category.get('title');
        }, '');
      }
      return categoriesString;
    }).property('categories.[]')
  });
});