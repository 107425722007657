define('vimme/helpers/one-way-select/contains', ['exports', 'ember-one-way-select/helpers/one-way-select/contains'], function (exports, _emberOneWaySelectHelpersOneWaySelectContains) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberOneWaySelectHelpersOneWaySelectContains['default'];
    }
  });
  Object.defineProperty(exports, 'contains', {
    enumerable: true,
    get: function get() {
      return _emberOneWaySelectHelpersOneWaySelectContains.contains;
    }
  });
});