define('vimme/routes/guardian/quiz-builder/quiz', ['exports', 'ember', 'vimme/routes/guardian'], function (exports, _ember, _vimmeRoutesGuardian) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        categories: this.modelFor('guardian.quiz-builder').categories,
        difficulties: this.modelFor('guardian.quiz-builder').difficulties,
        grades: this.modelFor('guardian.quiz-builder').grades,
        quiz: this.store.find('quiz', params.quiz_id),
        guardian: this.modelFor('guardian.quiz-builder').guardian
      });
    },
    resetController: function resetController(controller) {
      var quiz = controller.get('model.quiz');
      var quizQuestions = quiz.get('quizQuestions');
      quiz.rollback();
      quizQuestions.reload();
    }
  });
});