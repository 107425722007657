define('vimme/controllers/student/testing-calendar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedDate: null,
    selectedItems: null,

    sortProps: ['startDate'],
    sortedScheduledTests: _ember['default'].computed.sort('model.scheduledTests', 'sortProps'),

    actions: {
      select: function select(day) {
        this.set('selectedDate', day);
      },

      selectToday: function selectToday() {
        this.set('selectedDate', moment().startOf('day').toDate());
      }
    }
  });
});