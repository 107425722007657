define('vimme/routes/sub-admin/reports/standard', ['exports', 'vimme/routes/sub-admin'], function (exports, _vimmeRoutesSubAdmin) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    model: function model() {
      return this.modelFor('sub-admin.reports');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedSubYear', null);
    }
  });
});