define('vimme/models/new-quiz', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    total: _emberData['default'].attr('number'),
    isPublic: _emberData['default'].attr('boolean'),

    startAt: _emberData['default'].attr('isodate'),
    endAt: _emberData['default'].attr('isodate'),

    newQuizQuestions: _emberData['default'].hasMany('new-quiz-question', { async: true }),

    studentNewQuizzes: _emberData['default'].hasMany('student-new-quiz', { async: true }),
    studentNewQuizQuestions: _emberData['default'].hasMany('student-new-quiz-question', { async: true }),

    teacher: _emberData['default'].belongsTo('teacher', { async: true }),
    week: _emberData['default'].belongsTo('week', { async: true }),

    isTakable: _ember['default'].computed('startAt', 'endAt', function () {
      if (!this.get('startAt') || !this.get('endAt')) {
        return false;
      }
      return moment().isBetween(this.get('startAt'), this.get('endAt'));
    }),

    isInPast: _ember['default'].computed('endAt', function () {
      return moment().isAfter(this.get('endAt'));
    })
  });
});