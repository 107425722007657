define('vimme/routes/admin/builder/tests/test/questions/question', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model(params) {
      var question = this.store.find('question', params.question_id);
      var standards = this.store.peekAll('standard');

      var _modelFor = this.modelFor('admin');

      var tests = _modelFor.tests;

      var _modelFor2 = this.modelFor('admin.builder.tests.test.questions');

      var grade = _modelFor2.grade;
      var test = _modelFor2.test;

      return _ember['default'].RSVP.hash({
        grade: grade,
        question: question,
        standards: standards,
        test: test,
        tests: tests.filter(function (t) {
          return t.get('grade.id') === grade.get('id') && t.get('id') !== test.get('id');
        })
      });
    }
  });
});