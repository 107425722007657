define('vimme/routes/teacher/reports', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'teacher.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('teacher.reports.week');
        } else {
          this.transitionTo('teacher.reports.week');
        }
      }
    },
    model: function model() {
      var weeks = this.store.findAll('week');
      var parentModel = this.modelFor('teacher');

      var hash = Object.assign({
        weeks: weeks
      }, parentModel);

      return _ember['default'].RSVP.hash(hash);
    }
  });
});