define('vimme/controllers/admin/builder/resources/resource', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSaving: false,

    actions: {
      'delete': function _delete() {
        var _this = this;

        this.set('isSaving', true);

        this.get('model.resource').destroyRecord().then(function () {
          _this.set('isSaving', false);
          _this.transitionToRoute('admin.builder.resources');
        })['catch'](function (resource) {
          resource.rollback();
          _this.set('isSaving', false);
        });
      },
      save: function save(resource) {
        var _this2 = this;

        this.set('isSaving', true);

        resource.save().then(function () {
          _this2.set('isSaving', false);
        })['catch'](function (err) {
          console.error(err);
          _this2.get('model.resource').rollback();
          _this2.set('isSaving', false);
        });
      }
    }
  });
});