define('vimme/components/vimme-calendar-day/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',

    classNames: ['vimme-calendar-day'],

    classNameBindings: ['isToday', 'isThisMonth', 'isSelected', 'day.isScheduled'],

    month: null,
    today: null,
    day: null,
    selected: null,

    isToday: (function () {
      return !moment(this.get('day.date')).diff(this.get('today'));
    }).property('day.date', 'today'),

    isThisMonth: (function () {
      return !moment(this.get('day.date')).startOf('month').diff(this.get('month'));
    }).property('day.date', 'month'),

    isSelected: (function () {
      var selected = this.get('selected');

      return !!selected && !moment(this.get('day.date')).diff(selected);
    }).property('day.date', 'selected'),

    label: (function () {
      return moment(this.get('day.date')).format('D');
    }).property('day.date'),

    click: function click() {
      this.sendAction('action', this.get('day.date'));
    }
  });
});