define('vimme/routes/guardian/quiz-builder', ['exports', 'vimme/routes/guardian', 'ember'], function (exports, _vimmeRoutesGuardian, _ember) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      var guardian = this.modelFor('guardian').user;
      return _ember['default'].RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        quizzes: guardian.get('quizzes'),
        guardian: guardian
      });
    }
  });
});