define('vimme/components/app-pages/student/quiz-calendar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    selectedDate: undefined,

    quizzes: _ember['default'].computed(function () {
      return [];
    }),
    studentQuizzes: _ember['default'].computed(function () {
      return [];
    }),

    actions: {
      select: function select(day) {
        this.set('selectedDate', day);
      },

      selectToday: function selectToday() {
        this.set('selectedDate', moment().startOf('day').toDate());
      }
    }
  });
});