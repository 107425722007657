define('vimme/routes/teacher/reports/standard', ['exports', 'ember', 'vimme/routes/teacher', 'vimme/config/environment'], function (exports, _ember, _vimmeRoutesTeacher, _vimmeConfigEnvironment) {
  var RSVP = _ember['default'].RSVP;
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    authAjax: _ember['default'].inject.service(),
    model: function model() {
      var _modelFor = this.modelFor('teacher.reports');

      var weeks = _modelFor.weeks;

      var reports = this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/standard-reports');
      return RSVP.hash(Object.assign({
        reports: reports,
        weeks: weeks
      }, this.modelFor('teacher')));
    }
  });
});