define('vimme/components/app-pages/teacher/reports/quiz/average-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    question: null,
    studentQuestions: _ember['default'].computed(function () {
      return [];
    }),

    matchedQuestions: _ember['default'].computed('studentQuestions.newQuizQuestion.id', 'question.id', function () {
      var _this = this;

      return this.get('studentQuestions').filter(function (sq) {
        return sq.get('newQuizQuestion.id') === _this.get('question.id');
      });
    }),

    correctQuestions: _ember['default'].computed.filterBy('matchedQuestions', 'isCorrect')
  });
});