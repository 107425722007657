define('vimme/controllers/teacher/management/index', ['exports', 'ember', 'npm:jwt-decode'], function (exports, _ember, _npmJwtDecode) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    lastName: '',
    middleInitial: '',
    firstName: '',
    grade: null,
    gradeNum: null,
    teacher: null,
    username: '',
    password: '',
    schoolStudentId: '',

    isSaving: false,

    resetVals: (function () {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var id = _ref.id;

      var teacher = this.store.find('teacher', id),
          _this = this;

      teacher.then(function (teacher) {
        _this.set('lastName', '');
        _this.set('middleInitial', '');
        _this.set('firstName', '');
        _this.set('grade', teacher.get('grade'));
        _this.set('gradeNum', teacher.get('grade.id'));
        _this.set('teacher', null);
        _this.set('username', '');
        _this.set('password', '');
        _this.set('schoolStudentId', '');
      });
    }).on('init'),

    isValid: (function () {
      var grade = this.get('grade'),
          lastName = this.get('lastName'),
          firstName = this.get('firstName'),
          password = this.get('password');

      return !!grade && !!lastName && !!firstName && !!password && password.length >= 5;
    }).property('gradeNum', 'lastName', 'firstName', 'password'),

    isUnsavable: (function () {
      return !this.get('isValid');
    }).property('isValid'),

    updateGrade: (function () {
      var gradeNum = this.get('gradeNum'),
          _this = this;

      if (gradeNum) {
        this.store.find('grade', gradeNum).then(function (grade) {
          _this.set('grade', grade);
        })['catch'](function () {
          _this.set('gradeNum', null);

          _this.set('grade', null);
        });
      }
    }).observes('gradeNum'),

    actions: {
      save: function save() {
        var token = this.get('session.data.authenticated.token');

        var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

        var id = _ref2.id;

        if (!this.get('isSaving')) {
          this.set('isSaving', true);

          var teacher = this.store.find('teacher', id),
              _this = this;

          teacher.then(function (teacher) {
            var student = _this.store.createRecord('student', {
              lastName: _this.get('lastName'),
              middleInitial: _this.get('middleInitial'),
              firstName: _this.get('firstName'),
              grade: _this.get('grade'),
              teacher: teacher,
              password: _this.get('password'),
              schoolStudentId: _this.get('schoolStudentId')
            });

            student.save().then(function (student) {
              _this.set('lastName', '');
              _this.set('middleInitial', '');
              _this.set('firstName', '');
              _this.set('grade', teacher.get('grade'));
              _this.set('gradeNum', teacher.get('grade.id'));
              _this.set('teacher', null);
              _this.set('username', '');
              _this.set('password', '');
              _this.set('schoolStudentId', '');

              _this.set('isSaving', false);

              _this.transitionToRoute('teacher.management.student', student.get('id'));
              _this.send('refresh');
            })['catch'](function (error) {
              student.deleteRecord();

              if (error && error.responseJSON && error.responseJSON.message) {
                alert(error.responseJSON.message);
              } else {
                alert('Server Error');
              }

              _this.set('isSaving', false);
            });
          });
        }
      }
    }
  });
});