define('vimme/routes/guardian/reports', ['exports', 'ember', 'vimme/routes/guardian'], function (exports, _ember, _vimmeRoutesGuardian) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'guardian.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('guardian.reports.week');
        } else {
          this.transitionTo('guardian.reports.week');
        }
      }
    },
    model: function model() {
      var grades = this.store.findAll('grade');
      var students = this.store.findAll('student');
      var studentTests = this.store.findAll('studentTest');
      var scheduledTests = this.store.findAll('scheduledTest');
      var tests = this.store.findAll('test');
      var weeks = this.store.findAll('week');

      return _ember['default'].RSVP.hash({
        grades: grades,
        students: students,
        studentTests: studentTests,
        scheduledTests: scheduledTests,
        tests: tests,
        weeks: weeks
      });
    }
  });
});