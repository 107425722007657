define('vimme/services/auth-ajax', ['exports', 'ember', 'ember-ajax/services/ajax'], function (exports, _ember, _emberAjaxServicesAjax) {
  exports['default'] = _emberAjaxServicesAjax['default'].extend({
    session: _ember['default'].inject.service(),

    trustedHosts: ['mathbots.vimmelearning.com'],

    headers: _ember['default'].computed('session.data.authenticated.token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.token');
        if (token) {
          headers['Authorization'] = 'Bearer ' + token;
        }
        return headers;
      }
    })
  });
});