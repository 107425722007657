define("vimme/components/mathbots-teacher-reporting/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "title", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "schools", ["loc", [null, [6, 20], [6, 27]]]]], [], []], "value", ["subexpr", "@mut", [["get", "school", ["loc", [null, [7, 18], [7, 24]]]]], [], []], "update", ["subexpr", "action", [["get", "setSchool", ["loc", [null, [8, 27], [8, 36]]]]], [], ["loc", [null, [8, 19], [8, 37]]]], "prompt", "Select School"], ["loc", [null, [3, 8], [10, 10]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 21,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "title", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "grades", ["loc", [null, [16, 20], [16, 26]]]]], [], []], "value", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [17, 18], [17, 23]]]]], [], []], "update", ["subexpr", "action", [["get", "setGrade", ["loc", [null, [18, 27], [18, 35]]]]], [], ["loc", [null, [18, 19], [18, 36]]]], "prompt", "Select Grade"], ["loc", [null, [13, 8], [20, 10]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 22,
              "column": 4
            },
            "end": {
              "line": 31,
              "column": 4
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "one-way-select", [], ["optionLabelPath", "lastThenFirst", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [26, 20], [26, 28]]]]], [], []], "value", ["subexpr", "@mut", [["get", "teacher", ["loc", [null, [27, 18], [27, 25]]]]], [], []], "update", ["subexpr", "action", [["get", "setTeacher", ["loc", [null, [28, 27], [28, 37]]]]], [], ["loc", [null, [28, 19], [28, 38]]]], "prompt", "Select Teacher"], ["loc", [null, [23, 8], [30, 10]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 16
              },
              "end": {
                "line": 62,
                "column": 16
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n                 ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cell-header student-column no-hover");
            var el2 = dom.createTextNode("Sign Up");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n             ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 66,
                "column": 16
              },
              "end": {
                "line": 68,
                "column": 16
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n                 ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cell");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("%");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n             ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "signupAvg", ["loc", [null, [67, 38], [67, 51]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 73,
                    "column": 24
                  },
                  "end": {
                    "line": 73,
                    "column": 124
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["content", "student.lastThenFirst", ["loc", [null, [73, 99], [73, 124]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 72,
                  "column": 20
                },
                "end": {
                  "line": 74,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", [["get", "routeName", ["loc", [null, [73, 35], [73, 44]]]], ["get", "student.userId", ["loc", [null, [73, 45], [73, 59]]]]], ["class", "cell student-column cell-link"], 0, null, ["loc", [null, [73, 24], [73, 136]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 74,
                  "column": 20
                },
                "end": {
                  "line": 76,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "cell student-column");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "student.lastThenFirst", ["loc", [null, [75, 57], [75, 82]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 78,
                    "column": 24
                  },
                  "end": {
                    "line": 80,
                    "column": 24
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createElement("span");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                     ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var element2 = dom.childAt(element1, [0]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element1, 'class');
                morphs[1] = dom.createAttrMorph(element2, 'class');
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell average-column ", ["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [79, 65], [79, 85]]]], "benchmark", "intensive"], [], ["loc", [null, [79, 60], [79, 111]]]]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "attempt.is_signed_up", ["loc", [null, [79, 131], [79, 151]]]], "icon-check", "icon-delete"], [], ["loc", [null, [79, 126], [79, 180]]]]]]]],
              locals: ["attempt"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 20
                },
                "end": {
                  "line": 81,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                 ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [78, 50], [78, 58]]]], ["get", "student.userId", ["loc", [null, [78, 59], [78, 73]]]]], [], ["loc", [null, [78, 32], [78, 74]]]]], [], 0, null, ["loc", [null, [78, 24], [80, 33]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 12
              },
              "end": {
                "line": 83,
                "column": 12
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                 ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n             ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element3, 1, 1);
            morphs[1] = dom.createMorphAt(element3, 3, 3);
            return morphs;
          },
          statements: [["block", "if", [["get", "routeName", ["loc", [null, [72, 26], [72, 35]]]]], [], 0, 1, ["loc", [null, [72, 20], [76, 27]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [77, 26], [77, 34]]]]], [], 2, null, ["loc", [null, [77, 20], [81, 27]]]]],
          locals: ["student"],
          templates: [child0, child1, child2]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 19
              },
              "end": {
                "line": 91,
                "column": 19
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cell-header no-hover");
            var el2 = dom.createTextNode("L");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "level.order", ["loc", [null, [90, 58], [90, 73]]]]],
          locals: ["level"],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 94,
                "column": 19
              },
              "end": {
                "line": 96,
                "column": 19
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "cell");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("%");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n                ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "average", ["loc", [null, [95, 41], [95, 52]]]]],
          locals: ["average"],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 103,
                        "column": 81
                      },
                      "end": {
                        "line": 103,
                        "column": 129
                      }
                    },
                    "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "userLevel.attempts", ["loc", [null, [103, 107], [103, 129]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 102,
                      "column": 31
                    },
                    "end": {
                      "line": 104,
                      "column": 31
                    }
                  },
                  "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                            ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  morphs[1] = dom.createMorphAt(element0, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["subexpr", "attempt-color", [["get", "userLevel", ["loc", [null, [103, 68], [103, 77]]]]], [], ["loc", [null, [103, 52], [103, 79]]]]]]], ["block", "if", [["get", "userLevel.attempts", ["loc", [null, [103, 87], [103, 105]]]]], [], 0, null, ["loc", [null, [103, 81], [103, 136]]]]],
                locals: ["userLevel"],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 101,
                    "column": 27
                  },
                  "end": {
                    "line": 105,
                    "column": 27
                  }
                },
                "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                        ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempt", ["loc", [null, [102, 57], [102, 64]]]], ["get", "level.id", ["loc", [null, [102, 65], [102, 73]]]]], [], ["loc", [null, [102, 39], [102, 74]]]]], [], 0, null, ["loc", [null, [102, 31], [104, 40]]]]],
              locals: ["level"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 100,
                  "column": 23
                },
                "end": {
                  "line": 106,
                  "column": 19
                }
              },
              "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "each", [["get", "levels", ["loc", [null, [101, 35], [101, 41]]]]], [], 0, null, ["loc", [null, [101, 27], [105, 36]]]]],
            locals: ["attempt"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 98,
                "column": 12
              },
              "end": {
                "line": 108,
                "column": 12
              }
            },
            "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createComment("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("\n                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "with", [["subexpr", "get-with-default", [["get", "attempts", ["loc", [null, [100, 49], [100, 57]]]], ["get", "student.userId", ["loc", [null, [100, 58], [100, 72]]]]], [], ["loc", [null, [100, 31], [100, 73]]]]], [], 0, null, ["loc", [null, [100, 23], [106, 28]]]]],
          locals: ["student"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 55,
              "column": 0
            },
            "end": {
              "line": 111,
              "column": 0
            }
          },
          "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-general column");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-general-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell-header student-column no-hover");
          var el5 = dom.createTextNode("Student");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row reports-average-row");
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "cell");
          var el5 = dom.createTextNode("Average");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n             ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n         ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reports-detailed column");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "reports-detailed-inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row-header");
          var el4 = dom.createComment("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row reports-average-row");
          var el4 = dom.createComment("\n                ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n            ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1, 1]);
          var element5 = dom.childAt(fragment, [3, 1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 3, 3);
          morphs[2] = dom.createMorphAt(element4, 5, 5);
          morphs[3] = dom.createMorphAt(dom.childAt(element5, [1]), 1, 1);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          morphs[5] = dom.createMorphAt(element5, 5, 5);
          return morphs;
        },
        statements: [["block", "if", [["get", "signedUp", ["loc", [null, [60, 22], [60, 30]]]]], [], 0, null, ["loc", [null, [60, 16], [62, 23]]]], ["block", "if", [["get", "signedUp", ["loc", [null, [66, 22], [66, 30]]]]], [], 1, null, ["loc", [null, [66, 16], [68, 23]]]], ["block", "each", [["get", "students", ["loc", [null, [70, 20], [70, 28]]]]], [], 2, null, ["loc", [null, [70, 12], [83, 21]]]], ["block", "each", [["get", "levels", ["loc", [null, [89, 27], [89, 33]]]]], [], 3, null, ["loc", [null, [89, 19], [91, 28]]]], ["block", "each", [["get", "averages", ["loc", [null, [94, 27], [94, 35]]]]], [], 4, null, ["loc", [null, [94, 19], [96, 28]]]], ["block", "each", [["get", "students", ["loc", [null, [98, 20], [98, 28]]]]], [], 5, null, ["loc", [null, [98, 12], [108, 21]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 112,
            "column": 0
          }
        },
        "moduleName": "vimme/components/mathbots-teacher-reporting/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "whole filter-bar");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "mathbots-key");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "mathbots-key__left");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mathbots-key__item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__box benchmark");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__text");
        var el6 = dom.createTextNode("Passed");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mathbots-key__item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__box intensive");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__text");
        var el6 = dom.createTextNode("Failed");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "mathbots-key__right");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mathbots-key__item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__box");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__text");
        var el6 = dom.createTextNode("Unattempted");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mathbots-key__item");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__box");
        var el6 = dom.createTextNode("1+");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "mathbots-key__text");
        var el6 = dom.createTextNode("Attempts");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element6, 1, 1);
        morphs[1] = dom.createMorphAt(element6, 2, 2);
        morphs[2] = dom.createMorphAt(element6, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "schools.length", ["loc", [null, [2, 10], [2, 24]]]]], [], 0, null, ["loc", [null, [2, 4], [11, 11]]]], ["block", "if", [["get", "grades.length", ["loc", [null, [12, 10], [12, 23]]]]], [], 1, null, ["loc", [null, [12, 4], [21, 11]]]], ["block", "if", [["get", "teachers.length", ["loc", [null, [22, 10], [22, 25]]]]], [], 2, null, ["loc", [null, [22, 4], [31, 11]]]], ["block", "if", [["get", "students", ["loc", [null, [55, 6], [55, 14]]]]], [], 3, null, ["loc", [null, [55, 0], [111, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});