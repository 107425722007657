define('vimme/routes/admin/builder/quiz-questions/new', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      return _ember['default'].RSVP.hash({
        quizQuestion: this.store.createRecord('quizQuestion', {
          title: null
        }),
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade')
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('category').filterBy('id', null).invoke('deleteRecord');
        this.store.all('quizQuestion').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});