define('vimme/routes/guardian/quiz-builder/index', ['exports', 'ember', 'vimme/routes/guardian'], function (exports, _ember, _vimmeRoutesGuardian) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model() {
      var guardian = this.modelFor('guardian.quiz-builder').guardian;
      return _ember['default'].RSVP.hash({
        categories: this.modelFor('guardian.quiz-builder').categories,
        difficulties: this.modelFor('guardian.quiz-builder').difficulties,
        grades: this.modelFor('guardian.quiz-builder').grades,
        quiz: this.store.createRecord('quiz', {
          title: null,
          guardian: guardian
        }),
        guardian: guardian
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});