define('vimme/controllers/sub-admin/reports/growth', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['grade', 'teacher', 'from', 'to'],

    grade: '',
    teacher: '',
    from: '',
    to: '',

    selectedGrade: null,
    selectedTeacher: null,
    selectedFrom: null,
    selectedTo: null,

    filteredTeachers: _ember['default'].computed.filterBy('teachers', 'isPlaceholder', false),

    teachersSort: ['school.title', 'lastThenFirst'],
    sortedTeachers: _ember['default'].computed.sort('filteredTeachers', 'teachersSort'),

    actions: {
      setGrade: function setGrade(grade) {
        var _this = this;

        if (grade) {
          grade.get('teachers').then(function (teachers) {
            _this.set('teachers', teachers);
            _this.set('grade', grade.id);
            _this.set('teacher', '');
            _this.set('selectedGrade', grade);
            _this.set('selectedTeacher', null);
          });
        } else {
          this.set('students', null);
          this.set('teachers', null);
          this.set('grade', '');
          this.set('teacher', '');
          this.set('selectedGrade', grade);
          this.set('selectedTeacher', null);
        }
      },
      setTeacher: function setTeacher(teacher) {
        var _this2 = this;

        if (teacher) {
          teacher.get('students').then(function (students) {
            _this2.set('students', students);
            _this2.set('teacher', teacher.id);
            _this2.set('selectedTeacher', teacher);
          });
        } else {
          this.set('students', null);
          this.set('teacher', '');
          this.set('selectedTeacher', null);
        }
      },
      setFrom: function setFrom(from) {
        this.set('from', from.id);
        this.set('selectedFrom', from);
      },
      setTo: function setTo(to) {
        this.set('to', to.id);
        this.set('selectedTo', to);
      }
    }
  });
});