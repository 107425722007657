define('vimme/routes/guardian-signup', ['exports', 'ember', 'npm:jwt-decode'], function (exports, _ember, _npmJwtDecode) {
  exports['default'] = _ember['default'].Route.extend({
    ajax: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      if (role === 'Admin') {
        this.transitionTo('admin.management.schools');
      } else if (role === 'SchoolAdmin') {
        this.transitionTo('school-admin.schedule-tests');
      } else if (role === 'SubAdmin') {
        this.transitionTo('sub-admin.reports.week');
      } else if (role === 'Teacher') {
        this.transitionTo('teacher.testing-calendar');
      } else if (role === 'Student') {
        this.transitionTo('student.testing-calendar');
      } else if (role === 'Guardian') {
        this.transitionTo('guardian.index');
      }
    },

    activate: function activate() {
      _ember['default'].$('body').addClass('index-page');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('index-page');
    }
  });
});