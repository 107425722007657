define('vimme/routes/teacher/reports/growth', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    queryParams: {
      from: {
        replace: true
      },
      to: {
        replace: true
      }
    },

    model: function model(params) {
      var weeks = this.modelFor('teacher.reports').weeks;

      var user = this.modelFor('teacher').user;
      var selectedFrom = params.from ? weeks.find(function (week) {
        return week.get('id') === params.from;
      }) : undefined;
      var selectedTo = params.to ? weeks.find(function (week) {
        return week.get('id') === params.to;
      }) : undefined;
      var students = user.get('students');

      return _ember['default'].RSVP.hash({
        params: params,
        weeks: weeks,
        selectedFrom: selectedFrom,
        selectedTo: selectedTo,
        students: students,
        user: user
      });
    },

    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('selectedFrom', model.selectedFrom);
      controller.set('selectedTo', model.selectedTo);
      controller.set('students', model.students);
    }
  });
});