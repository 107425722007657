define('vimme/controllers/school-admin/test/print', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    indexed: (function () {
      var items = this.get('model.questions');

      return items.map(function (item, index) {
        item.set('index', index + 1);

        return item;
      });
    }).property('model.questions')
  });
});