define('vimme/components/app-pages/teacher/quiz-builder/question-preview/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('image')) {
        var reader = new FileReader();
        reader.onload = function (e) {
          _this.set('image64', e.target.result);
        };
        reader.readAsDataURL(this.get('image'));
      } else {
        this.set('image64', null);
      }
    },

    image64: undefined,

    imagePath: _ember['default'].computed('editModel.imagePath', 'image64', function () {
      return this.get('image64') || this.get('editModel.imagePath');
    })
  });
});