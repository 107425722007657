define('vimme/routes/admin/questions', ['exports', 'vimme/routes/admin', 'ember'], function (exports, _vimmeRoutesAdmin, _ember) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'admin.questions.index') {
        if (transition.queryParams && transition.queryParams['test-review-id']) {
          this.replaceWith('admin.questions.question', 1, {
            queryParams: { testReviewID: transition.queryParams['test-review-id'] }
          });
        } else {
          this.replaceWith('admin.questions.question', 1);
        }
      }
    },
    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        studentTests: _this.modelFor('admin.test').get('studentTests'),
        questions: _this.modelFor('admin.test').get('questions'),
        answers: _this.modelFor('admin.test').get('answers')
      });
    }
  });
});