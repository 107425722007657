define('vimme/routes/admin/builder/tests/test/questions/new', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model() {
      var question = this.store.createRecord('question', {
        test: this.modelFor('admin.builder.tests.test').test,
        questionType: 'normal'
      });
      var standards = this.store.peekAll('standard');
      return _ember['default'].RSVP.hash({
        question: question,
        standards: standards
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('question').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});