define('vimme/models/student-test', ['exports', 'ember-data', 'vimme/utils/round'], function (exports, _emberData, _vimmeUtilsRound) {
  exports['default'] = _emberData['default'].Model.extend({
    isComplete: _emberData['default'].attr('boolean'),
    total: _emberData['default'].attr('number'),
    score: _emberData['default'].attr('number'),

    createdAt: _emberData['default'].attr('isodate'),
    updatedAt: _emberData['default'].attr('isodate'),

    student: _emberData['default'].belongsTo('student', { async: true }),
    scheduledTest: _emberData['default'].belongsTo('scheduledTest', { async: true }),
    studentQuestions: _emberData['default'].hasMany('studentQuestion', { async: true }),

    percent: (function () {
      var percent = this.get('score') / this.get('total') * 100;

      return percent;
    }).property('total', 'score'),

    roundedPercent: (function () {
      return (0, _vimmeUtilsRound['default'])(this.get('percent'));
    }).property('percent'),

    formattedPercent: (function () {
      return this.get('roundedPercent') + '%';
    }).property('roundedPercent')
  });
});