define('vimme/controllers/admin/builder/tests', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    // new: Ember.inject.controller('admin.builder.tests.new'),

    allowedTests: _ember['default'].computed('model.@each.isDraft', 'user.canEditLiveTests', function () {
      if (this.get('user.canEditLiveTests')) {
        return this.get('model');
      }
      return this.get('model').filterBy('isDraft', true);
    }),
    unsorted: _ember['default'].computed('searchTerm', 'allowedTests.@each.{isNew,isDeleted}', function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var tests = this.get('allowedTests').filterBy('isNew', false).filterBy('isDeleted', false),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = tests.filter(function (test) {
          return filter.test(test.get('titleWithGrade'));
        });
      } else {
        unsorted = tests;
      }
      return unsorted;
    }),
    sortProps: ['week.number', 'grade.id'],
    filteredTests: _ember['default'].computed.sort('unsorted', 'sortProps'),

    actions: {
      newTest: function newTest() {
        // if (this.get('application.currentRouteName') !== 'admin.builder.tests.new') {
        //     this.transitionToRoute('admin.builder.tests.new');
        // } else {
        //     this.store.all('tests').filterBy('id', null).invoke('deleteRecord');
        //     this.set('new.model.test', this.store.createRecord('test', {
        //         title: null
        //     }));
        // }
      }
    }
  });
});