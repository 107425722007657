define("vimme/templates/school-admin/reports/mathbots/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/school-admin/reports/mathbots/index.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "mathbots-teacher-reporting", [], ["students", ["subexpr", "@mut", [["get", "model.students", ["loc", [null, [2, 13], [2, 27]]]]], [], []], "levels", ["subexpr", "@mut", [["get", "model.levels", ["loc", [null, [3, 11], [3, 23]]]]], [], []], "attempts", ["subexpr", "@mut", [["get", "model.attempts", ["loc", [null, [4, 13], [4, 27]]]]], [], []], "averages", ["subexpr", "@mut", [["get", "model.averages", ["loc", [null, [5, 13], [5, 27]]]]], [], []], "routeName", "school-admin.reports.mathbots.student", "grades", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [7, 11], [7, 23]]]]], [], []], "grade", ["subexpr", "@mut", [["get", "model.grade", ["loc", [null, [8, 10], [8, 21]]]]], [], []], "teachers", ["subexpr", "@mut", [["get", "model.teachers", ["loc", [null, [9, 13], [9, 27]]]]], [], []], "teacher", ["subexpr", "@mut", [["get", "model.teacher", ["loc", [null, [10, 12], [10, 25]]]]], [], []], "setGrade", ["subexpr", "action", ["setGrade"], [], ["loc", [null, [11, 13], [11, 32]]]], "setTeacher", ["subexpr", "action", ["setTeacher"], [], ["loc", [null, [12, 15], [12, 36]]]]], ["loc", [null, [1, 0], [13, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});