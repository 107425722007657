define('vimme/models/scheduled-test', ['exports', 'ember-data', 'vimme/config/environment', 'vimme/utils/time-in-range'], function (exports, _emberData, _vimmeConfigEnvironment, _vimmeUtilsTimeInRange) {
  exports['default'] = _emberData['default'].Model.extend({
    startDate: _emberData['default'].attr('isodate'),
    endDate: _emberData['default'].attr('isodate'),
    isReviewable: _emberData['default'].attr('boolean'),

    test: _emberData['default'].belongsTo('test', { async: true }),
    teacher: _emberData['default'].belongsTo('teacher', { async: true }),
    studentTests: _emberData['default'].hasMany('studentTest', { async: true }),

    isActive: (function () {
      var now = moment();

      return (0, _vimmeUtilsTimeInRange['default'])(now, this.get('startDate'), this.get('endDate'));
    }).property().volatile(),

    isViewable: (function () {
      var now = moment(),
          startDate = this.get('startDate'),
          endDate = this.get('endDate'),
          farDate = moment(endDate).add(_vimmeConfigEnvironment['default'].viewableWindow, 'days');

      return now.diff(startDate) >= 0 && now.diff(farDate) < 0;
    }).property().volatile()
  });
});