define("vimme/templates/teacher/reports/quiz", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 4,
            "column": 2
          }
        },
        "moduleName": "vimme/templates/teacher/reports/quiz.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "app-pages/teacher/reports/quiz", [], ["quizId", ["subexpr", "@mut", [["get", "this.quiz", ["loc", [null, [2, 11], [2, 20]]]]], [], []], "quizzes", ["subexpr", "@mut", [["get", "this.model.quizzes", ["loc", [null, [3, 12], [3, 30]]]]], [], []]], ["loc", [null, [1, 0], [4, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});