define('vimme/routes/student/reports/progress', ['exports', 'vimme/routes/student', 'ember'], function (exports, _vimmeRoutesStudent, _ember) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash(Object.assign({
        tests: this.modelFor('student').tests,
        scheduledTests: this.store.findAll('scheduledTest'),
        studentTests: this.store.findAll('studentTest')
      }, this.modelFor('student')));
    }
  });
});