define('vimme/controllers/admin/test-data', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      filterTests: function filterTests(autocomplete, term) {
        this.set('searchTerm', term);
      },

      selectTest: function selectTest(test) {
        this.transitionToRoute('admin.test-data.test', test);
      }
    },

    unsorted: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var tests = this.get('model'),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i');

      if (!term) {
        return null;
      }

      return tests.filter(function (test) {
        return filter.test(test.get('titleWithGrade'));
      });
    }).property('searchTerm'),
    sortProps: ['titleWithGrade'],
    filteredTests: _ember['default'].computed.sort('unsorted', 'sortProps')
  });
});