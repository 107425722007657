define('vimme/routes/teacher/builder/paper-quizzes', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var localDifficulties = this.store.all('difficulty');
      var localGrades = this.store.all('grade');
      var teacher = this.modelFor('teacher').user;
      return _ember['default'].RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: localDifficulties.get('length') > 0 ? localDifficulties : this.store.findAll('difficulty'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        quizzes: teacher.get('quizzes'),
        teacher: teacher
      });
    }
  });
});