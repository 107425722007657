define('vimme/routes/admin/reports', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'admin.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('admin.reports.week');
        } else {
          this.transitionTo('admin.reports.week');
        }
      }
    },
    model: function model() {
      var grades = this.store.findAll('grade'),
          schools = this.store.findAll('school'),
          schoolYears = this.store.findAll('schoolYear'),
          weeks = this.store.findAll('week');

      var hash = {
        grades: grades,
        schools: schools,
        schoolYears: schoolYears,
        weeks: weeks
      };

      return _ember['default'].RSVP.hash(hash);
    }
  });
});