define('vimme/adapters/user-game', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'vimme/config/environment', 'ember'], function (exports, _emberData, _emberSimpleAuthMixinsDataAdapterMixin, _vimmeConfigEnvironment, _ember) {
  exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:token',
    host: _vimmeConfigEnvironment['default'].API.gameHost,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },

    pathForType: function pathForType(type) {
      var decamelized = _ember['default'].String.decamelize(type);
      var dasherized = _ember['default'].String.dasherize(decamelized);

      return _ember['default'].String.pluralize(dasherized);
    }
  });
});