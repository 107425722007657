define('vimme/routes/school-admin/management', ['exports', 'vimme/routes/school-admin'], function (exports, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'school-admin.management.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.management.sub-admins');
        } else {
          this.transitionTo('school-admin.management.sub-admins');
        }
      }
    }
  });
});