define('vimme/components/app-pages/teacher/reports/quiz/question-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    questions: null,
    studentQuestions: null,
    studentQuiz: null,

    paddedStudentQuestions: _ember['default'].computed('studentQuestions', 'studentQuiz', function () {
      var _this = this;

      var studentQuestions = this.get('studentQuestions').filter(function (sq) {
        return sq.get('studentNewQuiz.id') === _this.get('studentQuiz.id');
      });
      var questions = this.get('questions');

      if (!questions) {
        return;
      }

      return questions.map(function (question, num) {
        var studentQuestion = studentQuestions.find(function (studentQuestion) {
          return studentQuestion.get('newQuizQuestion.id') === question.get('id');
        });

        return { studentQuestion: studentQuestion || null, num: num + 1 };
      });
    })
  });
});