define('vimme/models/teacher', ['exports', 'ember-data', 'vimme/models/user'], function (exports, _emberData, _vimmeModelsUser) {
  exports['default'] = _vimmeModelsUser['default'].extend({
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    eula: _emberData['default'].attr('boolean'),
    isPlaceholder: _emberData['default'].attr('boolean'),

    grade: _emberData['default'].belongsTo('grade', { async: true }),
    quizzes: _emberData['default'].hasMany('quiz', { async: true }),
    students: _emberData['default'].hasMany('student', { async: true }),
    school: _emberData['default'].belongsTo('school', { async: true }),

    newQuizzes: _emberData['default'].hasMany('new-quiz', { async: true }),
    professorLevels: _emberData['default'].hasMany('professor-level', { async: true }),

    fullName: (function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }).property('firstName', 'lastName'),

    lastThenFirst: (function () {
      return this.get('lastName') + ', ' + this.get('firstName');
    }).property('firstName', 'lastName'),

    lastThenFirstPlusSchool: (function () {
      return this.get('lastName') + ', ' + this.get('firstName') + ' (' + this.get('school.title') + ')';
    }).property('firstName', 'lastName', 'school.title')
  });
});