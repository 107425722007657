define('vimme/models/new-quiz-question', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    order: _emberData['default'].attr('number'),
    title: _emberData['default'].attr('string'),
    imagePath: _emberData['default'].attr('string'),
    questionType: _emberData['default'].attr('string'),

    allowRuler: _emberData['default'].attr('boolean'),
    allowProtractor: _emberData['default'].attr('boolean'),
    allowCalculator: _emberData['default'].attr('boolean'),
    allowScientificCalculator: _emberData['default'].attr('boolean'),

    number: Ember.computed('order', function () {
      return this.get('order') + 1;
    }),

    newQuiz: _emberData['default'].belongsTo('new-quiz', { async: true }),

    newQuizQuestionAnswers: _emberData['default'].hasMany('new-quiz-question-answer'),

    isMultipleQuestion: Ember.computed('questionType', function () {
      return this.get('questionType') === 'multiple';
    }),

    isTextQuestion: Ember.computed('questionType', function () {
      return this.get('questionType') === 'text';
    })
  });
});