define('vimme/components/vimme-test-question-editor/component', ['exports', 'ember', 'vimme/config/environment', 'vimme/utils/compute-image-path'], function (exports, _ember, _vimmeConfigEnvironment, _vimmeUtilsComputeImagePath) {
  exports['default'] = _ember['default'].Component.extend({
    authAjax: _ember['default'].inject.service(),
    ajax: _ember['default'].inject.service(),

    tagName: '',

    // inputs
    question: null,

    tests: _ember['default'].computed(function () {
      return [];
    }),

    isSaving: false,

    cancelText: 'Cancel',

    image: null,
    grade: 1,

    alternateAudio: false,

    selectedQuestionType: 'normal',

    isCopyQuestionDialogVisible: false,
    selectedTests: _ember['default'].computed(function () {
      return [];
    }),

    setup: (function () {
      var question = this.get('question');

      var _question$getProperties = question.getProperties('questionType', 'alternateAudio', 'noAudio', 'answers');

      var questionType = _question$getProperties.questionType;
      var alternateAudio = _question$getProperties.alternateAudio;
      var noAudio = _question$getProperties.noAudio;

      if (questionType) {
        this.set('selectedQuestionType', questionType);
      }
      if (alternateAudio && !noAudio) {
        this.set('alternateAudio', true);
        this.set('originalAlternateAudio', alternateAudio);
      }
    }).on('init'),

    testQuestionTypes: [{
      id: 'normal',
      value: 'Multiple Choice with Single Answer'
    }, {
      id: 'multiple',
      value: 'Multiple Choice with Multiple Answers'
    }, {
      id: 'text',
      value: 'Text Answer'
    }],

    imagePath: _ember['default'].computed('question.imagePath', 'image', function () {
      var image = this.get('image');
      var imagePath = this.get('question.imagePath');

      return (0, _vimmeUtilsComputeImagePath.computeImagePath)(image, imagePath);
    }),

    isUnsavable: (function () {
      var isUnsavable = this.get('isSaving') || !this.get('question.title') || !this.get('question.test') || !this.get('question.answers.length');
      var image = this.get('showImage') && !this.get('imagePath');
      return isUnsavable || image;
    }).property('isSaving', 'showImage', 'imagePath', 'question.{title,test,answers.length}'),
    isSavable: _ember['default'].computed.not('isUnsavable'),

    image64: null,

    setImage64: (function () {
      var _this = this;

      var image = this.get('image');
      if (image) {
        var reader = new FileReader();
        reader.onload = function (e) {
          _this.set('image64', e.target.result);
        };
        reader.readAsDataURL(image);
      } else {
        this.set('image64', null);
      }
    }).observes('image'),

    _saveQuestion: function _saveQuestion(question) {
      var _this2 = this;

      return _ember['default'].RSVP.all(question.get('answers').map(function (answer) {
        var image = answer.get('localImage');

        if (!image) return _ember['default'].RSVP.resolve();

        answer.set('title', '');
        answer.set('alternateAudio', '');
        return _this2.getUploadUrl(image, 'answer').then(function (data) {
          answer.set('imagePath', 'https://s3.amazonaws.com/' + _vimmeConfigEnvironment['default'].S3.bucket + '/answers/' + encodeURI(data.filename));
          return _this2.uploadImage(image, data.upload_url);
        });
      })).then(function () {
        return _this2.attrs.saveQuestion(question);
      }).then(function (question) {
        _this2.set('isSaving', false);
        _this2.set('originalAlternateAudio', question.get('alternateAudio'));
        if (_this2.attrs.reroute) {
          _this2.attrs.reroute(question);
          return;
        }
        return question.get('answers').reload();
      })['catch'](function () {
        alert('error saving question');
        _this2.set('isSaving', false);
      });
    },

    getUploadUrl: function getUploadUrl(image, type) {
      return this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/' + type + 's-upload-url', {
        type: 'POST',
        contentType: 'application/json',
        dataType: 'json',
        data: JSON.stringify({
          type: image.type,
          name: image.name
        })
      });
    },

    uploadImage: function uploadImage(image, uploadUrl) {
      return this.get('ajax').request(uploadUrl, {
        type: 'PUT',
        dataType: 'text',
        cache: false,
        processData: false,
        contentType: false,
        headers: {
          'Cache-Control': 'max-age=86400'
        },
        data: image
      });
    },

    alternateAudio: false,

    actions: {
      setAlternateAudio: function setAlternateAudio(e) {
        e.preventDefault();
        var question = this.get('question');
        question.set('noAudio', false);
        if (this.get('alternateAudio')) {
          question.set('alternateAudio', '');
        } else {
          question.set('alternateAudio', this.get('originalAlternateAudio'));
        }
        this.toggleProperty('alternateAudio');
      },
      setNoAudio: function setNoAudio(e) {
        e.preventDefault();
        var question = this.get('question');
        question.set('alternateAudio', '');
        question.set('answersAudio', false);
        question.toggleProperty('noAudio');
        this.set('alternateAudio', false);
      },
      setAnswersAudio: function setAnswersAudio(e) {
        e.preventDefault();
        var question = this.get('question');
        question.toggleProperty('answersAudio');
      },
      setAnswerAlternateAudio: function setAnswerAlternateAudio(answer, e) {
        e.preventDefault();
        answer.set('alternateAudio', e.target.value);
      },
      save: function save() {
        var _this3 = this;

        var image = this.get('image');
        var question = this.get('question');

        this.set('isSaving', true);

        if (image) {
          this.getUploadUrl(image, 'question').then(function (data) {
            return _this3.uploadImage(image, data.upload_url).then(function () {
              question.set('imagePath', 'https://s3.amazonaws.com/' + _vimmeConfigEnvironment['default'].S3.bucket + '/questions/' + encodeURI(data.filename));
              question.set('image', null);
              question.set('questionType', _this3.get('selectedQuestionType'));
              _this3._saveQuestion(question);
            });
          })['catch'](function () {
            // upload error
            _this3.set('isSaving', false);
          });
        } else if (question.get('imagePath')) {
          question.set('questionType', this.get('selectedQuestionType'));
          this._saveQuestion(question);
        } else {
          question.set('imagePath', null);
          question.set('questionType', this.get('selectedQuestionType'));
          this._saveQuestion(question);
        }
      },
      deleteAnswer: function deleteAnswer(answer) {
        answer.deleteRecord();
      },
      cancelEdit: function cancelEdit() {
        var _this4 = this;

        this.set('image', null);
        var answers = this.get('question.answers');
        this.get('question').rollback();
        answers.filterBy('id', null).invoke('deleteRecord');
        answers.invoke('rollback');
        answers.reload().then(function () {
          return _this4.attrs.cancel();
        });
      },
      openQuestion: function openQuestion() {
        this.set('showQuestion', true);
      },
      hideQuestion: function hideQuestion() {
        this.set('showQuestion', false);
      },
      clear: function clear() {
        this.set('image', null);
        this.set('question.imagePath', null);
        this.set('question.image', null);
      },
      newAnswer: function newAnswer() {
        var store = this.get('targetObject.store');
        store.createRecord('answer', {
          question: this.get('question'),
          isCorrect: this.get('selectedQuestionType') === 'text' ? true : false
        });
      },
      toggleLocalImage: function toggleLocalImage(answer) {
        if (answer.get('isImage')) {
          answer.set('isLocalImage', false);
          answer.set('localImage', null);
          answer.set('imagePath', null);
        } else {
          answer.set('isLocalImage', true);
        }
      },
      openCopyQuestionDialog: function openCopyQuestionDialog() {
        this.set('selectedTests', []);
        this.set('isCopyQuestionDialogVisible', true);
      },
      closeCopyQuestionDialog: function closeCopyQuestionDialog() {
        this.set('isCopyQuestionDialogVisible', false);
      },
      copy: function copy() {
        var _this5 = this;

        // TODO: detect when dirty!!!!
        this.set('isLoading', true);
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/questions/copy', {
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            copy: {
              id: this.get('question.id'),
              tests: this.get('selectedTests').map(function (test) {
                return test.get('id');
              })
            }
          })
        }).then(function () {
          _this5.set('isLoading', false);
          _this5.set('isCopyQuestionDialogVisible', false);
        }, function () {
          _this5.set('isLoading', false);
        });
      }
    }
  });
});