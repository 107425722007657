define('vimme/components/app-pages/teacher/quiz-builder/component', ['exports', 'ember'], function (exports, _ember) {

  var escape = function escape(term) {
    return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    quizBuilder: _ember['default'].inject.service(),

    searchTerm: '',
    selectedWeek: null,

    quizzes: _ember['default'].computed(function () {
      return [];
    }),
    publicQuizzes: _ember['default'].computed(function () {
      return [];
    }),

    activeQuizzes: _ember['default'].computed('quizzes', 'publicQuizzes', 'quizBuilder.isPublicFilter', function () {
      return this.get('quizBuilder.isPublicFilter') ? this.get('publicQuizzes') : this.get('quizzes');
    }),

    filteredQuizzes: _ember['default'].computed('selectedWeek.id', 'searchTerm', 'activeQuizzes.@each.{title,week.id}', function () {
      var quizzes = this.get('activeQuizzes').filterBy('isNew', false).filterBy('isDeleted', false);

      var term = this.get('searchTerm');
      var filter = new RegExp(escape(term), 'i');
      var weekId = this.get('selectedWeek.id');

      return quizzes.filter(function (quiz) {
        if (weekId && quiz.get('week.id') !== weekId) {
          return false;
        }
        if (!term) {
          return true;
        }
        return filter.test(quiz.get('title'));
      });
    }),

    actions: {
      selectWeek: function selectWeek(week) {
        this.set('selectedWeek', week);
      }
    }
  });
});