define('vimme/controllers/school-admin/management/teachers', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    indexController: _ember['default'].inject.controller('school-admin/management/teachers/index'),

    actions: {
      newTeacher: function newTeacher() {
        if (this.get('applicationController.currentRouteName') !== 'school-admin.management.teachers.index') {
          this.transitionToRoute('school-admin.management.teachers');
        }

        var index = this.get('indexController');

        index.set('lastName', '');
        index.set('firstName', '');
        index.set('grade', null);
        index.set('email', '');
        index.set('password', '');
      }
    },

    unsorted: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var teachers = this.get('model.teachers').filterBy('isPlaceholder', false) || [],
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          unsorted;

      if (term) {
        unsorted = teachers.filter(function (teacher) {
          return filter.test(teacher.get('fullName'));
        });
      } else {
        unsorted = teachers;
      }
      return unsorted;
    }).property('searchTerm', 'model.teachers.[]'),
    sortProps: ['lastName'],
    filteredTeachers: _ember['default'].computed.sort('unsorted', 'sortProps')
  });
});