define('vimme/routes/sub-admin/reports/week', ['exports', 'vimme/routes/sub-admin', 'ember'], function (exports, _vimmeRoutesSubAdmin, _ember) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        weeks: this.modelFor('sub-admin.reports').weeks,
        schoolYears: this.modelFor('sub-admin.reports').schoolYears,
        grades: this.modelFor('sub-admin.reports').grades,
        gradeAveragesRaw: this.store.findAll('perGradeBySchoolWeeklyAverage'),
        schools: this.modelFor('sub-admin.reports').schools
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('selectedSchoolYear', null);
    }
  });
});