define('vimme/routes/teacher/builder/quizzes/quiz/new', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    quizBuilder: _ember['default'].inject.service(),

    model: function model() {
      var question = this.store.createRecord('new-quiz-question', {
        newQuiz: this.modelFor('teacher.builder.quizzes.quiz').quiz,
        questionType: 'normal',
        title: this.get('quizBuilder.question.title'),
        imagePath: this.get('quizBuilder.question.imagePath')
      });
      this.store.createRecord('new-quiz-question-answer', {
        newQuizQuestion: question
      });
      this.store.createRecord('new-quiz-question-answer', {
        newQuizQuestion: question
      });

      this.set('quizBuilder.question', null);

      return {
        question: question
      };
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.peekAll('new-quiz-question').filterBy('id', null).invoke('deleteRecord');
        this.store.peekAll('new-quiz-question-answer').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});