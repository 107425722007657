define('vimme/routes/teacher', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode', 'vimme/utils/embed-zendesk'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmJwtDecode, _vimmeUtilsEmbedZendesk) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authenticationRoute: 'index',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      this._super(transition);

      if (role !== 'Teacher') {
        this.replaceWith('index');
      } else if (transition.targetName === 'teacher.index') {
        if (transition.sequence === 0) {
          this.replaceWith('teacher.testing-calendar');
        } else {
          this.transitionTo('teacher.testing-calendar');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref2.role;
      var id = _ref2.id;

      var user = this.store.find(role, id);

      return _ember['default'].RSVP.hash({
        professorLevels: user.then(function (user) {
          return user.get('professorLevels');
        }),
        tests: this.store.findAll('test'),
        scheduledTests: this.store.findAll('scheduledTest'),
        user: user,
        school: user.then(function (user) {
          return user.get('school');
        })
      });
    },
    afterModel: function afterModel(model) {
      var user = model.user;
      var professorLevels = model.professorLevels;

      if (professorLevels && professorLevels.get('length')) {
        try {
          new Crate({
            server: '767817740282232832', // Vimme Learning
            channel: '767817740282232835', // #vimme-professors
            location: ['bottom', 'left'],
            color: '#fff',
            glyph: ['/assets/images/icon.png', '90%']
          });
        } catch (err) {}
      }

      (0, _vimmeUtilsEmbedZendesk['default'])();

      if (user) {
        window.zE(function () {
          window.zE.identify({
            name: user.get('fullName'),
            email: user.get('email')
          });
        });
      }
    }
  });
});