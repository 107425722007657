define('vimme/routes/teacher/builder/quizzes', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      var weeks = this.store.findAll('week');
      return _ember['default'].RSVP.hash({
        quizzes: teacher.get('newQuizzes'),
        publicQuizzes: this.store.find('newQuiz', { 'public': true }),
        teacher: teacher,
        weeks: weeks
      });
    }
  });
});