define('vimme/components/app-pages/teacher/quiz-builder/quiz/component', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    authAjax: _ember['default'].inject.service(),
    quizBuilder: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    isLoading: false,
    isNotLoading: _ember['default'].computed.not('isLoading'),

    quiz: undefined,
    teachers: _ember['default'].computed(function () {
      return [];
    }),
    editModel: undefined,
    isNew: false,

    isNotMyQuiz: _ember['default'].computed.not('quiz.teacher.id'),

    isReadOnly: _ember['default'].computed.or('isNotMyQuiz', 'isPublicOnly'),

    selectedTeachers: _ember['default'].computed(function () {
      return [];
    }),

    questionToDelete: undefined,

    isDeleteQuizDialogVisible: false,
    isDeleteQuizQuestionDialogVisible: _ember['default'].computed.bool('questionToDelete'),
    isShareQuizDialogVisible: false,
    isCopyQuizDialogVisible: false,

    isIsPublicDirty: _ember['default'].computed('quiz.isPublic', 'editModel.isPublic', function () {
      return this.get('quiz.isPublic') !== this.get('editModel.isPublic');
    }),

    isTitleDirty: _ember['default'].computed('quiz.title', 'editModel.title', function () {
      var q = this.get('quiz.title');
      var em = this.get('editModel.title');

      if (!q && !em) {
        return false;
      }

      q = q && q.trim();
      em = em && em.trim();

      return q !== em;
    }),
    titleCharactersLeft: _ember['default'].computed('editModel.title.length', function () {
      var len = this.get('editModel.title.length') || 0;
      return 32 - len;
    }),
    overTitleLength: _ember['default'].computed('titleCharactersLeft', function () {
      return this.get('titleCharactersLeft') < 0;
    }),
    isTitleValid: _ember['default'].computed('editModel.title', function () {
      var em = this.get('editModel.title');
      return !!em && !!em.trim() && em.trim().length <= 32;
    }),

    isWeekDirty: _ember['default'].computed('quiz.week.id', 'editModel.week', function () {
      var q = this.get('quiz.week.id');
      var em = this.get('editModel.week');

      if (!q && !em) {
        return false;
      }

      return q !== em;
    }),
    isWeekValid: _ember['default'].computed.bool('editModel.week'),

    isDescriptionDirty: _ember['default'].computed('quiz.description', 'editModel.description', function () {
      var q = this.get('quiz.description');
      var em = this.get('editModel.description');

      if (!q && !em) {
        return false;
      }

      q = q && q.trim();
      em = em && em.trim();

      return q !== em;
    }),

    isStartAtDirty: _ember['default'].computed('quiz.startAt', 'editModel.startAt', function () {
      var q = this.get('quiz.startAt');
      var em = this.get('editModel.startAt');

      if (!q && !em) {
        return false;
      }

      return (q && q.getTime()) !== (em && em.getTime());
    }),

    isEndAtDirty: _ember['default'].computed('quiz.endAt', 'editModel.endAt', function () {
      var q = this.get('quiz.endAt');
      var em = this.get('editModel.endAt');

      if (!q && !em) {
        return false;
      }

      return (q && q.getTime()) !== (em && em.getTime());
    }),

    isValid: _ember['default'].computed('isTitleValid', 'isWeekValid', 'editModel.isPublic', 'quiz.isPublic', 'quiz.newQuizQuestions.length', function () {
      if (!this.get('isTitleValid') || !this.get('isWeekValid')) return false;

      if (this.get('editModel.isPublic')) {
        return !!this.get('quiz.newQuizQuestions.length');
      }

      return true;
    }),
    isInvalid: _ember['default'].computed.not('isValid'),

    isDirty: _ember['default'].computed.or('isTitleDirty', 'isWeekDirty', 'isDescriptionDirty', 'isStartAtDirty', 'isEndAtDirty', 'isIsPublicDirty'),
    isClean: _ember['default'].computed.not('isDirty'),

    isPublicOnly: _ember['default'].computed('isNew', 'quiz.isPublic', function () {
      if (this.get('isNew')) return false;
      return this.get('quiz.isPublic');
    }),

    canSave: _ember['default'].computed.and('isNotLoading', 'isDirty', 'isValid'),
    cannotSave: _ember['default'].computed.not('canSave'),

    canShare: _ember['default'].computed.bool('selectedTeachers.length'),
    cannotShare: _ember['default'].computed.not('canShare'),

    slipList: null,

    beforeReorder: null,
    beforeSwipe: null,
    beforeWait: null,
    reorder: null,

    setupSlip: function setupSlip(list) {
      list.addEventListener('slip:beforereorder', this.beforeReorder, false);
      list.addEventListener('slip:beforeswipe', this.beforeSwipe, false);
      list.addEventListener('slip:beforewait', this.beforeWait, false);
      list.addEventListener('slip:reorder', this.reorder, false);

      this.set('slipList', new Slip(list));
    },

    cleanupSlip: function cleanupSlip(list) {
      list.removeEventListener('slip:beforereorder', this.beforeRorder);
      list.removeEventListener('slip:beforeswipe', this.beforeSwipe);
      list.removeEventListener('slip:beforewait', this.beforeWait);
      list.removeEventListener('slip:reorder', this.reorder);

      this.get('slipList').detach();
      this.set('slipList', null);
    },

    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      var list = document.querySelector('.sortable-list');

      if (!list) {
        return;
      }

      if (this.get('slipList')) {
        this.cleanupSlip(list);
      }

      this.beforeReorder = function (e) {
        if (/quiz-builder__question-reorder/.test(e.target.className) === false) {
          e.preventDefault();
        }
      };
      this.beforeSwipe = function (e) {
        e.preventDefault();
      };
      this.beforeWait = function (e) {
        e.preventDefault();
      };
      this.reorder = function (e) {
        var items = _this.get('sortedQuizQuestions').toArray();
        var item = items.objectAt(e.detail.originalIndex);

        _this.beginPropertyChanges();
        items.removeObject(item);
        items.insertAt(e.detail.spliceIndex, item);
        items.forEach(function (item, index) {
          item.set('order', index);
          if (item.get('isDirty')) {
            item.save();
          }
        });
        _this.endPropertyChanges();
      };

      this.setupSlip(list);
    },

    sortOrder: ['order'],
    sortedQuizQuestions: _ember['default'].computed.sort('quiz.newQuizQuestions', 'sortOrder'),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var list = document.querySelector('.sortable-list');

      if (!list) {
        return;
      }

      this.cleanupSlip(list);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setup();
    },

    setup: function setup() {
      var editModel = {
        title: this.get('quiz.title'),
        week: this.get('quiz.week.id'),
        description: this.get('quiz.description'),
        startAt: this.get('quiz.startAt') || null,
        endAt: this.get('quiz.endAt') || null,
        isPublic: this.get('quiz.isPublic') || false
      };
      this.setProperties({
        editModel: editModel,
        isNew: this.get('quiz.isNew')
      });
    },

    actions: {
      setTrimmedTitle: function setTrimmedTitle(title) {
        this.set('editModel.title', title.trim());
      },
      setTrimmedDescription: function setTrimmedDescription(description) {
        this.set('editModel.description', description.trim());
      },
      onStartChange: function onStartChange(_ref) {
        var _ref2 = _slicedToArray(_ref, 1);

        var start = _ref2[0];

        this.set('editModel.startAt', start);
      },
      onEndChange: function onEndChange(_ref3) {
        var _ref32 = _slicedToArray(_ref3, 1);

        var end = _ref32[0];

        this.set('editModel.endAt', end);
      },

      save: function save() {
        var _this2 = this;

        this.set('isLoading', true);
        this.get('quiz').setProperties(_extends({}, this.get('editModel'), {
          week: this.get('weeks').find(function (week) {
            return week.get('id') === _this2.get('editModel.week');
          })
        }));

        this.get('quiz').save().then(function (quiz) {
          _this2.set('isLoading', false);
          if (_this2.get('isNew')) {
            _this2.get('router').transitionTo('teacher.builder.quizzes.quiz', quiz.get('id'));
          }
        })['catch'](function (e) {
          console.warn(e);
          _this2.set('isLoading', false);
          alert('Something went wrong saving your quiz!');
        });
      },

      deleteQuestion: function deleteQuestion(question) {
        var _this3 = this;

        this.set('isLoading', true);
        question.destroyRecord().then(function () {
          _this3.set('questionToDelete', undefined);
          _this3.set('isLoading', false);
        })['catch'](function (e) {
          console.warn(e);
          _this3.set('isLoading', false);
          alert('Something went wrong deleting your quiz question!');
        });
      },

      'delete': function _delete() {
        var _this4 = this;

        this.set('isLoading', true);
        if (this.get('isNew')) {
          this.setup();
          this.set('isLoading', false);
        } else {
          this.get('quiz').destroyRecord().then(function () {
            _this4.set('isLoading', false);
            _this4.get('router').transitionTo('teacher.builder.quizzes');
          })['catch'](function (e) {
            console.warn(e);
            _this4.set('isLoading', false);
            alert('Something went wrong deleting your quiz!');
          });
        }
      },

      share: function share() {
        var _this5 = this;

        this.set('isLoading', true);
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/new-quizzes/share', {
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            share: {
              id: this.get('quiz.id'),
              teachers: this.get('selectedTeachers').map(function (t) {
                return t.get('id');
              })
            }
          })
        }).then(function () {
          _this5.set('isLoading', false);
          _this5.send('closeShareQuizDialog');
        })['catch'](function (e) {
          console.warn(e);
          _this5.set('isLoading', false);
          alert('Something went wrong sharing your quiz!');
        });
      },

      copy: function copy() {
        var _this6 = this;

        this.set('isLoading', true);
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/new-quizzes/' + this.get('quiz.id') + '/copy', {
          type: 'POST',
          contentType: 'application/json',
          dataType: 'json'
        }).then(function (copy) {
          _this6.get('store').pushPayload(copy);
          _this6.set('quizBuilder.isPublicFilter', false);
          _this6.set('isLoading', false);
          _this6.send('closeCopyQuizDialog');
          _this6.get('router').transitionTo('teacher.builder.quizzes.quiz', copy.new_quiz.id);
        })['catch'](function (e) {
          console.warn(e);
          _this6.set('isLoading', false);
          alert('Something went wrong copying the quiz!');
        });
      },

      openDeleteQuizDialog: function openDeleteQuizDialog() {
        this.set('isDeleteQuizDialogVisible', true);
      },
      closeDeleteQuizDialog: function closeDeleteQuizDialog() {
        this.set('isDeleteQuizDialogVisible', false);
      },
      openDeleteQuizQuestionDialog: function openDeleteQuizQuestionDialog(question) {
        this.set('questionToDelete', question);
      },
      closeDeleteQuizQuestionDialog: function closeDeleteQuizQuestionDialog() {
        this.set('questionToDelete', undefined);
      },
      openShareQuizDialog: function openShareQuizDialog() {
        this.set('isShareQuizDialogVisible', true);
      },
      closeShareQuizDialog: function closeShareQuizDialog() {
        this.set('isShareQuizDialogVisible', false);
        this.set('selectedTeachers', []);
      },
      openCopyQuizDialog: function openCopyQuizDialog() {
        this.set('isCopyQuizDialogVisible', true);
      },
      closeCopyQuizDialog: function closeCopyQuizDialog() {
        this.set('isCopyQuizDialogVisible', false);
      }
    }
  });
});