define("vimme/components/app-pages/admin/schools/school/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 31,
              "column": 16
            },
            "end": {
              "line": 33,
              "column": 16
            }
          },
          "moduleName": "vimme/components/app-pages/admin/schools/school/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    Create\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 16
            },
            "end": {
              "line": 35,
              "column": 16
            }
          },
          "moduleName": "vimme/components/app-pages/admin/schools/school/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("                    Save\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 39,
            "column": 6
          }
        },
        "moduleName": "vimme/components/app-pages/admin/schools/school/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-container");
        dom.setAttribute(el3, "style", "text-align: right;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "label");
        dom.setAttribute(el4, "for", "locked");
        var el5 = dom.createTextNode("\n                ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "id", "locked");
        dom.setAttribute(el5, "type", "checkbox");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n                Locked\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "label");
        dom.setAttribute(el3, "for", "title");
        var el4 = dom.createTextNode("Title *");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3, "id", "title");
        dom.setAttribute(el3, "class", "input");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "buttons");
        dom.setAttribute(el3, "style", "margin-top: 10px;");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-update icon-check");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1, 1, 1]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element1, [], true);
        }
        var element2 = dom.childAt(element0, [3, 3]);
        var element3 = dom.childAt(element0, [5, 1, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element1, 'checked');
        morphs[1] = dom.createAttrMorph(element1, 'onchange');
        morphs[2] = dom.createAttrMorph(element2, 'value');
        morphs[3] = dom.createAttrMorph(element2, 'oninput');
        morphs[4] = dom.createAttrMorph(element3, 'disabled');
        morphs[5] = dom.createElementMorph(element3);
        morphs[6] = dom.createMorphAt(element3, 1, 1);
        return morphs;
      },
      statements: [["attribute", "checked", ["get", "this.editModel.isLocked", ["loc", [null, [8, 30], [8, 53]]]]], ["attribute", "onchange", ["subexpr", "action", ["setIsLocked"], ["value", "target.checked"], ["loc", [null, [9, 29], [9, 76]]]]], ["attribute", "value", ["get", "this.editModel.title", ["loc", [null, [20, 20], [20, 40]]]]], ["attribute", "oninput", ["subexpr", "action", ["setTrimmedTitle"], ["value", "target.value"], ["loc", [null, [21, 20], [21, 69]]]]], ["attribute", "disabled", ["get", "this.cannotSave", ["loc", [null, [28, 27], [28, 42]]]]], ["element", "action", ["save"], [], ["loc", [null, [29, 16], [29, 33]]]], ["block", "if", [["get", "this.isNew", ["loc", [null, [31, 22], [31, 32]]]]], [], 0, 1, ["loc", [null, [31, 16], [35, 23]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});