define("vimme/templates/teacher/reports/question", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [5, 24], [5, 35]]]]], [], []], "optionLabelPath", "content.longName", "value", ["subexpr", "@mut", [["get", "selectedWeek", ["loc", [null, [7, 22], [7, 34]]]]], [], []], "prompt", "Select Week"], ["loc", [null, [4, 12], [9, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 16
              },
              "end": {
                "line": 14,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/teacher/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "week-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedWeek.longName", ["loc", [null, [13, 45], [13, 70]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 16,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedWeek", ["loc", [null, [12, 22], [12, 34]]]]], [], 0, null, ["loc", [null, [12, 16], [14, 23]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 8
            },
            "end": {
              "line": 20,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Print View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element20);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [19, 34], [19, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 8
            },
            "end": {
              "line": 22,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Report View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element19);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [21, 34], [21, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 28
                  },
                  "end": {
                    "line": 42,
                    "column": 28
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/question.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                             ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element10, 'class');
                morphs[1] = dom.createElementMorph(element10);
                morphs[2] = dom.createMorphAt(element10, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "studentTest.color", ["loc", [null, [41, 49], [41, 66]]]]]]], ["element", "action", ["gotoTestReview", ["get", "studentTest.scheduledTest.test.id", ["loc", [null, [41, 96], [41, 129]]]], 1, ["get", "studentTest.id", ["loc", [null, [41, 132], [41, 146]]]]], [], ["loc", [null, [41, 70], [41, 148]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [41, 149], [41, 181]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 28
                  },
                  "end": {
                    "line": 44,
                    "column": 28
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/question.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                             ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element9 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element9, 'class');
                morphs[1] = dom.createMorphAt(element9, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "studentTest.color", ["loc", [null, [43, 51], [43, 68]]]]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [43, 72], [43, 104]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 20
                },
                "end": {
                  "line": 46,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element11 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element11, 3, 3);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [39, 46], [39, 83]]]], ["block", "if", [["get", "studentQuestions", ["loc", [null, [40, 34], [40, 50]]]]], [], 0, 1, ["loc", [null, [40, 28], [44, 35]]]]],
            locals: ["studentTest"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 52,
                  "column": 24
                },
                "end": {
                  "line": 61,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/teacher/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createAttrMorph(element8, 'title');
              morphs[2] = dom.createElementMorph(element8);
              morphs[3] = dom.createMorphAt(element8, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header\n                                ", ["get", "indexedQuestion.shortName", ["loc", [null, [55, 34], [55, 59]]]]]]], ["attribute", "title", ["get", "indexedQuestion.standardsTitle", ["loc", [null, [57, 40], [57, 70]]]]], ["element", "action", ["sortQuestion", ["get", "indexedQuestion", ["loc", [null, [56, 56], [56, 71]]]]], [], ["loc", [null, [56, 32], [56, 73]]]], ["content", "indexedQuestion.shortName", ["loc", [null, [59, 32], [59, 61]]]]],
            locals: ["indexedQuestion"],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 28
                  },
                  "end": {
                    "line": 67,
                    "column": 28
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                             ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("%");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createMorphAt(element7, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedAverage.studentQuestion.color", ["loc", [null, [66, 51], [66, 86]]]]]]], ["content", "paddedAverage.studentQuestion.average", ["loc", [null, [66, 90], [66, 131]]]]],
              locals: ["paddedAverage"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 20
                },
                "end": {
                  "line": 69,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row reports-average-row");
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                     ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "paddedAverages", ["loc", [null, [65, 36], [65, 50]]]]], [], 0, null, ["loc", [null, [65, 28], [67, 37]]]]],
            locals: ["paddedAverages"],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 73,
                      "column": 32
                    },
                    "end": {
                      "line": 79,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/teacher/reports/question.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "vimme-report-question", [], ["paddedStudentQuestion", ["subexpr", "@mut", [["get", "paddedStudentQuestion", ["loc", [null, [75, 62], [75, 83]]]]], [], []], "clickMe", "showQuestion", "hoverMe", "setQuickQuestion"], ["loc", [null, [74, 36], [78, 38]]]]],
                locals: ["paddedStudentQuestion"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 24
                  },
                  "end": {
                    "line": 81,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [73, 40], [73, 62]]]]], [], 0, null, ["loc", [null, [73, 32], [79, 41]]]]],
              locals: ["paddedStudentQuestions"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 70,
                  "column": 20
                },
                "end": {
                  "line": 82,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [71, 61], [71, 70]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [71, 88], [71, 116]]]]], [], []]], 0, null, ["loc", [null, [71, 24], [81, 52]]]]],
            locals: ["studentTest"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 8
              },
              "end": {
                "line": 85,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header score-column");
            var el5 = dom.createTextNode("Total");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("%");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1, 1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element13, [1]);
            var element15 = dom.childAt(element13, [3]);
            var element16 = dom.childAt(element12, [3, 3]);
            var element17 = dom.childAt(fragment, [3, 1]);
            var element18 = dom.childAt(element17, [1]);
            var morphs = new Array(10);
            morphs[0] = dom.createAttrMorph(element13, 'class');
            morphs[1] = dom.createElementMorph(element14);
            morphs[2] = dom.createElementMorph(element15);
            morphs[3] = dom.createAttrMorph(element16, 'class');
            morphs[4] = dom.createMorphAt(element16, 0, 0);
            morphs[5] = dom.createMorphAt(element12, 5, 5);
            morphs[6] = dom.createAttrMorph(element18, 'class');
            morphs[7] = dom.createMorphAt(element18, 1, 1);
            morphs[8] = dom.createMorphAt(element17, 3, 3);
            morphs[9] = dom.createMorphAt(element17, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [29, 45], [29, 62]]]]]]], ["element", "action", ["sortOther", "student-column"], [], ["loc", [null, [30, 64], [30, 103]]]], ["element", "action", ["sortOther", "score-column"], [], ["loc", [null, [31, 62], [31, 99]]]], ["attribute", "class", ["concat", ["cell ", ["get", "studentAverageColor", ["loc", [null, [35, 43], [35, 62]]]]]]], ["content", "studentAverage", ["loc", [null, [35, 66], [35, 84]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [37, 28], [37, 40]]]]], [], 0, null, ["loc", [null, [37, 20], [46, 29]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [51, 45], [51, 62]]]]]]], ["block", "each", [["get", "indexedQuestions", ["loc", [null, [52, 32], [52, 48]]]]], [], 1, null, ["loc", [null, [52, 24], [61, 33]]]], ["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [63, 57], [63, 66]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "averages", ["loc", [null, [63, 84], [63, 92]]]]], [], []]], 2, null, ["loc", [null, [63, 20], [69, 48]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [70, 28], [70, 40]]]]], [], 3, null, ["loc", [null, [70, 20], [82, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 4
            },
            "end": {
              "line": 86,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "studentTests", ["loc", [null, [26, 14], [26, 26]]]]], [], 0, null, ["loc", [null, [26, 8], [85, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 24
                  },
                  "end": {
                    "line": 97,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "print-item");
                var el2 = dom.createTextNode("Q");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3, 1, 1);
                morphs[1] = dom.createAttrMorph(element4, 'class');
                return morphs;
              },
              statements: [["content", "paddedStudentQuestion.num", ["loc", [null, [96, 54], [96, 83]]]], ["attribute", "class", ["concat", [["get", "paddedStudentQuestion.studentQuestion.correctIcon", ["loc", [null, [96, 97], [96, 146]]]]]]]],
              locals: ["paddedStudentQuestion"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 88,
                  "column": 12
                },
                "end": {
                  "line": 100,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/teacher/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "print-row column");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-general");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-name");
              var el4 = dom.createTextNode("Student: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-avg");
              var el4 = dom.createTextNode("Total: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-details");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [91, 69], [91, 106]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [92, 66], [92, 98]]]], ["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [95, 32], [95, 54]]]]], [], 0, null, ["loc", [null, [95, 24], [97, 33]]]]],
            locals: ["paddedStudentQuestions"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 87,
                "column": 8
              },
              "end": {
                "line": 101,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/question.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [88, 49], [88, 58]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [88, 76], [88, 104]]]]], [], []]], 0, null, ["loc", [null, [88, 12], [100, 40]]]]],
          locals: ["studentTest"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 86,
              "column": 4
            },
            "end": {
              "line": 102,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "studentTests", ["loc", [null, [87, 16], [87, 28]]]]], [], 0, null, ["loc", [null, [87, 8], [101, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 106,
              "column": 0
            },
            "end": {
              "line": 124,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog question-dialog");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "btn btn-icon-only btn-dialog-exit icon-delete");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("Week ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": Question ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message test-question");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideQuestion"], [], ["loc", [null, [110, 11], [110, 36]]]], ["content", "openQuestion.studentQuestion.student.lastThenFirst", ["loc", [null, [111, 33], [111, 87]]]], ["content", "openQuestion.studentQuestion.question.test.week.id", ["loc", [null, [112, 38], [112, 92]]]], ["content", "openQuestion.num", ["loc", [null, [112, 103], [112, 123]]]], ["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion.question", ["loc", [null, [115, 25], [115, 62]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion", ["loc", [null, [116, 32], [116, 60]]]]], [], []], "isReviewing", true, "showAudio", ["subexpr", "not", [["get", "this.model.school.isTrial", ["loc", [null, [118, 31], [118, 56]]]]], [], ["loc", [null, [118, 26], [118, 57]]]]], ["loc", [null, [114, 12], [119, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 0
            },
            "end": {
              "line": 134,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/teacher/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quick-question", [], ["studentQuestion", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion", ["loc", [null, [128, 24], [128, 53]]]]], [], []], "question", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question", ["loc", [null, [129, 17], [129, 55]]]]], [], []], "answers", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question.answers", ["loc", [null, [130, 16], [130, 62]]]]], [], []], "alignLeft", ["subexpr", "@mut", [["get", "alignLeft", ["loc", [null, [131, 18], [131, 27]]]]], [], []], "alignTop", ["subexpr", "@mut", [["get", "alignTop", ["loc", [null, [132, 17], [132, 25]]]]], [], []]], ["loc", [null, [127, 4], [133, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 135,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/reports/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element21 = dom.childAt(fragment, [0]);
        var element22 = dom.childAt(element21, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element22, 1, 1);
        morphs[1] = dom.createMorphAt(element22, 3, 3);
        morphs[2] = dom.createMorphAt(element21, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [16, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [18, 18], [18, 23]]]]], [], 2, 3, ["loc", [null, [18, 8], [22, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [25, 14], [25, 19]]]]], [], 4, 5, ["loc", [null, [25, 4], [102, 15]]]], ["block", "if", [["get", "showQuestion", ["loc", [null, [106, 6], [106, 18]]]]], [], 6, null, ["loc", [null, [106, 0], [124, 7]]]], ["block", "if", [["get", "quickQuestion", ["loc", [null, [126, 6], [126, 19]]]]], [], 7, null, ["loc", [null, [126, 0], [134, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});