define('vimme/controllers/guardian/reports/week', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['print'],

    print: false,

    currentSortDirection: 'desc',
    currentSortColumn: 'average-column',

    averages: (function () {
      var studentTests = this.get('model.studentTests');
      // var studentTests = this.get('model.studentTests').filterBy('scheduledTest.test.isPractice', false);
      var averages = [];
      var keys = {};

      if (studentTests && studentTests.get('length') > 0) {
        studentTests.forEach(function (item) {
          var studentID = item.get('student.id');
          var grade = item.get('student.grade');

          if (keys[studentID] === undefined) {
            averages.push({
              student: item.get('student'),
              weeklyAverages: [],
              total: item.get('total'),
              grade: grade
            });
            keys[studentID] = averages.length - 1;
          }

          averages[keys[studentID]].weeklyAverages.push(item);
        });
      }

      averages = averages.toArray().sort(function (a, b) {
        var aAvgs = a.weeklyAverages,
            bAvgs = b.weeklyAverages,
            aSum = aAvgs.map(function (item) {
          return item.get('percent');
        }).reduce(function (last, next) {
          return last + next;
        }, 0),
            bSum = bAvgs.map(function (item) {
          return item.get('percent');
        }).reduce(function (last, next) {
          return last + next;
        }, 0),
            aRatio = aSum / aAvgs.get('length'),
            bRatio = bSum / bAvgs.get('length');

        return bRatio - aRatio;
      });

      return averages;
    }).property('model.studentTests'),

    actions: {
      printView: function printView() {
        this.toggleProperty('print');
      }
    }
  });
});