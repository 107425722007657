define('vimme/components/vimme-audio/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    attributeBindings: ['style'],

    audio: null,

    isError: false,
    isLoading: true,
    isReloading: false,

    isPlaying: false,

    reset: _ember['default'].observer('src', function () {
      this.set('isError', false);
      this.set('isLoading', true);
      this.set('isPlaying', false);
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.audio = this.$('audio')[0];
      this.audio.onplaying = function () {
        _this.set('isPlaying', true);
      };
      this.audio.onpause = function () {
        _this.set('isPlaying', false);
      };
      this.audio.onloadedmetadata = function () {
        _this.set('isError', false);
        _this.set('isLoading', false);
      };
      this.audio.onerror = function () {
        _this.set('isError', true);
        _this.set('isLoading', false);
      };
    },

    actions: {
      playPause: function playPause() {
        if (this.get('isLoading')) {
          return;
        }

        if (this.get('isPlaying')) {
          this.audio.pause();
        } else {
          if (this.get('isError')) {
            this.set('isLoading', true);
            this.audio.load();
          }

          this.audio.play();
        }
      }
    }
  });
});