define('vimme/components/vimme-quiz-question-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tr', 'vimme-quiz-question-row'],
    classNameBindings: ['isDisabled'],

    quizQuestion: null,
    isSelecting: false,
    previouslySelected: [],
    currentlySelected: [],

    isSelected: (function () {
      return !!this.get('currentlySelected').findBy('id', this.get('quizQuestion.id'));
    }).property('currentlySelected.[]'),

    isDisabled: (function () {
      return !!this.get('previouslySelected').findBy('id', this.get('quizQuestion.id'));
    }).property('previouslySelected.[]'),

    actions: {
      toggle: function toggle(quizQuestion) {
        if (this.get('isSelected')) {
          this.sendAction('remove', quizQuestion);
        } else {
          this.sendAction('add', quizQuestion);
        }
      },
      togglePreview: function togglePreview(quizQuestion, element) {
        this.sendAction('togglePreview', quizQuestion, element);
      }
    }
  });
});