define('vimme/models/per-teacher-average', ['exports', 'ember-data', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _emberData, _vimmeUtilsRound, _vimmeUtilsColor) {
  exports['default'] = _emberData['default'].Model.extend({
    total: _emberData['default'].attr('number'),
    scores: _emberData['default'].attr('string'),

    teacher: _emberData['default'].belongsTo('teacher', { async: true }),
    test: _emberData['default'].belongsTo('test', { async: true }),
    scheduledTest: _emberData['default'].belongsTo('scheduledTest', { async: true }),

    score: (function () {
      var scores = this.get('scores').split(',');

      scores.sort(function (a, b) {
        return parseInt(a, 10) - parseInt(b, 10);
      });

      return scores.reduce(function (prev, curr) {
        return parseInt(prev, 10) + parseInt(curr, 10);
      }) / scores.length;
    }).property('scores'),

    percent: (function () {
      var score = this.get('score'),
          total = this.get('total');

      return score / total * 100;
    }).property('score', 'total'),

    roundedPercent: (function () {
      return (0, _vimmeUtilsRound['default'])(this.get('percent'));
    }).property('percent'),

    formattedPercent: (function () {
      return this.get('roundedPercent') + '%';
    }).property('roundedPercent'),

    color: (function () {
      var percent = (0, _vimmeUtilsRound['default'])(this.get('percent'));
      var grade = this.get('test.grade.id');

      return (0, _vimmeUtilsColor['default'])(percent, grade);
    }).property('percent')
  });
});