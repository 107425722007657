define('vimme/models/student-new-quiz', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    isComplete: _emberData['default'].attr('boolean'),
    score: _emberData['default'].attr('number'),

    newQuiz: _emberData['default'].belongsTo('newQuiz', { async: true }),
    student: _emberData['default'].belongsTo('student', { async: true }),

    studentNewQuizQuestions: _emberData['default'].hasMany('studentNewQuizQuestion', { async: true })
  });
});