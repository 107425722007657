define('vimme/controllers/admin/builder/resources/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    index: _ember['default'].inject.controller('admin.builder.resources.index'),

    isSaving: false,

    actions: {
      cancel: function cancel() {
        this.store.all('resources').filterBy('id', null).invoke('deleteRecord');
        this.transitionToRoute('admin.builder.resources');
      },
      save: function save(resource) {
        var _this = this;

        this.set('isSaving', true);

        resource.save().then(function (resource) {
          _this.set('isSaving', false);
          _this.get('index').notifyPropertyChange('resources');
          _this.transitionToRoute('admin.builder.resources.resource', resource.get('id'));
        })['catch'](function () {
          _this.set('isSaving', false);
        });
      }
    }
  });
});