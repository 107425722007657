define('vimme/controllers/school-admin/reports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),

    selectedSchoolYear: null,

    actions: {
      setSchoolYear: function setSchoolYear(schoolYear) {
        if (schoolYear) {
          this.transitionToRoute('school-admin.reports.yearly', schoolYear.get('id'));
        } else {
          this.transitionToRoute('school-admin.reports.week');
        }
      }
    }
  });
});