define('vimme/controllers/teacher/reports/growth', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['from', 'to'],

    from: '',
    to: '',

    selectedFrom: null,
    selectedTo: null,

    actions: {
      setFrom: function setFrom(from) {
        this.set('from', from.id);
        this.set('selectedFrom', from);
      },
      setTo: function setTo(to) {
        this.set('to', to.id);
        this.set('selectedTo', to);
      }
    }
  });
});