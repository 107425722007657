define('vimme/components/app-pages/teacher/quiz-builder/question-bank/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    quizBuilder: _ember['default'].inject.service(),

    // input
    categories: _ember['default'].computed(function () {
      return [];
    }),
    difficulties: _ember['default'].computed(function () {
      return [];
    }),
    grades: _ember['default'].computed(function () {
      return [];
    }),

    questions: _ember['default'].computed(function () {
      return [];
    }),
    pagination: null,

    selectedQuestion: null,

    prev: _ember['default'].computed.bool('pagination.links.previous'),
    next: _ember['default'].computed.bool('pagination.links.next'),

    currentPage: _ember['default'].computed.alias('pagination.current_page'),
    perPage: _ember['default'].computed.alias('pagination.per_page'),
    count: _ember['default'].computed.alias('pagination.count'),
    total: _ember['default'].computed.alias('pagination.total'),
    totalPages: _ember['default'].computed.alias('pagination.total_pages'),

    prevPage: _ember['default'].computed('currentPage', function () {
      return this.get('currentPage') ? this.get('currentPage') - 1 : 0;
    }),
    nextPage: _ember['default'].computed('currentPage', 'totalPages', function () {
      if (!this.get('currentPage')) return 0;
      return this.get('currentPage') < this.get('totalPages') ? this.get('currentPage') + 1 : 0;
    }),
    start: _ember['default'].computed('pagination', 'currentPage', 'perPage', function () {
      if (!this.get('pagination')) {
        return 0;
      }
      return (this.get('currentPage') - 1) * this.get('perPage') + 1;
    }),
    end: _ember['default'].computed('pagination', 'currentPage', 'perPage', 'count', function () {
      if (!this.get('pagination')) {
        return 0;
      }
      return (this.get('currentPage') - 1) * this.get('perPage') + this.get('count');
    }),

    selectedCategory: null,
    selectedDifficulty: null,
    selectedGrade: null,

    isLoading: false,

    reconcileCategories: function reconcileCategories() {
      var _this = this;

      var grade = this.get('selectedGrade');
      var category = this.get('selectedCategory');

      if (!grade) {
        return _ember['default'].RSVP.resolve();
      }

      return this.get('store').find('category', { grades: grade }).then(function (categories) {
        var categoryFound = function categoryFound() {
          return categories.find(function (item) {
            return item.get('lowercaseTitle') === category;
          });
        };
        if (!category || !categoryFound()) {
          _this.set('selectedCategory', null);
        }
        _this.set('categories', categories);
      });
    },

    loadQuestions: function loadQuestions(page) {
      var _this2 = this;

      var category = this.get('selectedCategory');
      var difficulty = this.get('selectedDifficulty');
      var grade = this.get('selectedGrade');

      this.set('isLoading', true);

      this.get('store').find('quiz-question', {
        categories: category,
        difficulties: difficulty,
        grades: grade,
        page: page || 1
      }).then(function (questions) {
        if (!_this2.isDestroyed) {
          _this2.set('questions', questions);
          _this2.set('isLoading', false);
          _this2.set('pagination', questions.get('meta.pagination'));
        }
      })['catch'](function (err) {
        console.warn(err);
        if (!_this2.isDestroyed) {
          _this2.set('isLoading', false);
        }
      });
    },

    actions: {
      findQuestions: function findQuestions(_, page) {
        var _this3 = this;

        if (this.get('isLoading')) return;

        if (!this.get('selectedCategory') && !this.get('selectedDifficulty') && !this.get('selectedGrade')) {
          this.set('questions', []);
          this.set('pagination', null);
          return;
        }

        this.reconcileCategories().then(function () {
          _this3.set('selectedQuestion', null);
          _this3.loadQuestions(page);
        });
      },

      showPreview: function showPreview(question) {
        this.set('selectedQuestion', question);
      },
      hidePreview: function hidePreview() {
        this.set('selectedQuestion', null);
      },

      createQuestion: function createQuestion(question) {
        this.set('quizBuilder.question', question);
        this.router.transitionTo('teacher.builder.quizzes.quiz.new');
      }
    }
  });
});