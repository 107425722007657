define('vimme/routes/teacher/reports/mathbots/index', ['exports', 'ember', 'vimme/routes/teacher', 'vimme/config/environment'], function (exports, _ember, _vimmeRoutesTeacher, _vimmeConfigEnvironment) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    authAjax: _ember['default'].inject.service(),
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      var levels = this.store.findAll('level');
      var students = teacher.get('students');
      return this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.gameHost + '/reports/teacher?teacher=' + teacher.get('id')).then(function (_ref) {
        var attempts = _ref.attempts;

        return _ember['default'].RSVP.hash({
          levels: levels,
          students: students,
          attempts: attempts
        }).then(function (_ref2) {
          var levels = _ref2.levels;
          var students = _ref2.students;
          var attempts = _ref2.attempts;

          students = students.sortBy('lastThenFirst');
          var averages = levels.map(function (level) {
            var passed = 0;
            var total = 0;
            students.forEach(function (student) {
              var studentAttempts = attempts[student.get('userId')];
              var studentLevel = studentAttempts && studentAttempts[level.get('id')];
              if (studentLevel && studentLevel.passed) {
                passed++;
              }
              total++;
            });
            return Math.round(passed / total * 100);
          });
          return _ember['default'].RSVP.hash({
            levels: levels,
            students: students,
            attempts: attempts,
            averages: averages
          });
        });
      });
    }
  });
});