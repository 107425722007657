define('vimme/components/app-pages/student/quizzes/quiz-review/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    store: _ember['default'].inject.service(),

    quiz: undefined,
    studentQuiz: undefined,
    question: undefined,
    questions: _ember['default'].computed(function () {
      return [];
    }),
    studentQuestions: _ember['default'].computed(function () {
      return [];
    }),
    studentQuestion: undefined,

    missingAnswers: _ember['default'].computed('studentQuestion.newQuizQuestionAnswers.[]', 'question.newQuizQuestionAnswers.[]', function () {
      var _this = this;

      return this.get('question.newQuizQuestionAnswers').filter(function (answer) {
        return answer.get('isCorrect') && !_this.get('studentQuestion.newQuizQuestionAnswers').find(function (a) {
          return a.get('id') === answer.get('id');
        });
      });
    }),

    showMissingAnswers: _ember['default'].computed('studentQuestion.{isCorrect,isTextAnswer}', 'missingAnswers.length', function () {
      if (this.get('studentQuestion.isCorrect') || this.get('studentQuestion.isTextAnswer')) {
        return false;
      }
      return !!this.get('missingAnswers.length');
    }),

    isUnanswered: _ember['default'].computed('studentQuestion.{newQuizQuestionAnswers.length,textAnswer}', 'isTextType', function () {
      var textAnswer = this.get('studentQuestion.textAnswer');
      var answers = this.get('studentQuestion.newQuizQuestionAnswers.length');
      return !this.get('isTextType') && !answers || typeof textAnswer === 'undefined';
    }),

    isTextType: _ember['default'].computed('question.questionType', function () {
      return this.get('question.questionType') === 'text';
    }),

    number: 1,
    total: _ember['default'].computed.alias('questions.length'),

    prev: _ember['default'].computed('number', function () {
      return this.get('number') - 1;
    }),
    next: _ember['default'].computed('number', function () {
      return this.get('number') + 1;
    }),

    hasPrev: _ember['default'].computed('prev', function () {
      return this.get('prev') > 0;
    }),
    hasNext: _ember['default'].computed('next', 'total', function () {
      return this.get('next') <= this.get('total');
    }),

    tool: undefined,

    setup: function setup() {
      var _this2 = this;

      var studentQuestion = this.get('studentQuestions').find(function (sq) {
        return _ember['default'].get(sq, 'newQuizQuestion.id') === _this2.get('question.id');
      });

      if (!studentQuestion) {
        studentQuestion = this.get('store').createRecord('student-new-quiz-question', {
          newQuizQuestion: this.get('question'),
          studentNewQuiz: this.get('studentQuiz')
        });
      }

      this.setProperties({
        studentQuestion: studentQuestion,
        tool: undefined
      });
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.setup();
    },

    actions: {
      toggleTool: function toggleTool(tool) {
        if (this.get('tool') === tool) {
          this.set('tool', undefined);
        } else {
          this.set('tool', tool);
        }
      }
    }
  });
});