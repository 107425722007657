define('vimme/controllers/guardian/pacing-guides', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {

  var guideType = _vimmeConfigEnvironment['default'].environment === 'core' ? 'VCL' : 'VL';

  exports['default'] = _ember['default'].Controller.extend({
    selectedGradePG: null,

    pacingGuide: (function () {
      var grade = this.get('selectedGradePG');

      if (!grade) {
        return null;
      }

      return this.assets.resolve('assets/pacing-guides/grade-' + grade.get('id') + '-pacing-guide-' + guideType + '.pdf');
    }).property('selectedGradePG')
  });
});