define('vimme/routes/school-admin/reports', ['exports', 'ember', 'vimme/routes/school-admin'], function (exports, _ember, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'school-admin.reports.index') {
        if (transition.sequence === 0) {
          this.replaceWith('school-admin.reports.week');
        } else {
          this.transitionTo('school-admin.reports.week');
        }
      }
    },
    model: function model() {
      var grades = this.store.findAll('grade');
      var schoolYears = this.store.findAll('schoolYear');
      var weeks = this.store.findAll('week');

      return _ember['default'].RSVP.hash({ grades: grades, schoolYears: schoolYears, weeks: weeks });
    }
  });
});