define("vimme/components/vimme-test-question/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 0
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-audio", [], ["src", ["subexpr", "@mut", [["get", "question.audioPath", ["loc", [null, [3, 22], [3, 40]]]]], [], []], "style", "position: absolute; top: -32px; left: 50%; margin-left: -14px;"], ["loc", [null, [3, 4], [3, 113]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 12
                },
                "end": {
                  "line": 12,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "question-answer is-reviewing student-answer");
              var el2 = dom.createTextNode("Unanswered");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 17,
                      "column": 28
                    },
                    "end": {
                      "line": 19,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("img");
                  dom.setAttribute(el1, "alt", "");
                  dom.setAttribute(el1, "style", "max-width: 100%;");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element10 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element10, 'src');
                  return morphs;
                },
                statements: [["attribute", "src", ["get", "studentAnswer.image64", ["loc", [null, [18, 43], [18, 64]]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 19,
                        "column": 28
                      },
                      "end": {
                        "line": 21,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "alt", "");
                    dom.setAttribute(el1, "style", "max-width: 100%;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element9 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element9, 'src');
                    return morphs;
                  },
                  statements: [["attribute", "src", ["get", "studentAnswer.imagePath", ["loc", [null, [20, 43], [20, 66]]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 21,
                        "column": 28
                      },
                      "end": {
                        "line": 23,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                            ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "studentAnswer.title", ["loc", [null, [22, 32], [22, 57]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 19,
                      "column": 28
                    },
                    "end": {
                      "line": 23,
                      "column": 28
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "studentAnswer.imagePath", ["loc", [null, [19, 38], [19, 61]]]]], [], 0, 1, ["loc", [null, [19, 28], [23, 28]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 15,
                    "column": 20
                  },
                  "end": {
                    "line": 25,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element11, 'class');
                morphs[1] = dom.createMorphAt(element11, 1, 1);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["question-answer is-reviewing student-answer ", ["subexpr", "if", [["get", "studentAnswer.isCorrect", ["loc", [null, [16, 84], [16, 107]]]], "is-correct"], [], ["loc", [null, [16, 79], [16, 122]]]]]]], ["block", "if", [["get", "studentAnswer.image64", ["loc", [null, [17, 34], [17, 55]]]]], [], 0, 1, ["loc", [null, [17, 28], [23, 35]]]]],
              locals: ["studentAnswer"],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 28
                      },
                      "end": {
                        "line": 34,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("img");
                    dom.setAttribute(el1, "alt", "");
                    dom.setAttribute(el1, "style", "max-width: 100%;");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element8, 'src');
                    return morphs;
                  },
                  statements: [["attribute", "src", ["get", "missingAnswer.image64", ["loc", [null, [33, 43], [33, 64]]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 34,
                          "column": 28
                        },
                        "end": {
                          "line": 36,
                          "column": 28
                        }
                      },
                      "moduleName": "vimme/components/vimme-test-question/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("img");
                      dom.setAttribute(el1, "alt", "");
                      dom.setAttribute(el1, "style", "max-width: 100%;");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element7 = dom.childAt(fragment, [1]);
                      var morphs = new Array(1);
                      morphs[0] = dom.createAttrMorph(element7, 'src');
                      return morphs;
                    },
                    statements: [["attribute", "src", ["get", "missingAnswer.imagePath", ["loc", [null, [35, 43], [35, 66]]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@1.13.13",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 36,
                          "column": 28
                        },
                        "end": {
                          "line": 38,
                          "column": 28
                        }
                      },
                      "moduleName": "vimme/components/vimme-test-question/template.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                            ");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                      return morphs;
                    },
                    statements: [["content", "missingAnswer.title", ["loc", [null, [37, 32], [37, 57]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@1.13.13",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 34,
                        "column": 28
                      },
                      "end": {
                        "line": 38,
                        "column": 28
                      }
                    },
                    "moduleName": "vimme/components/vimme-test-question/template.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "missingAnswer.imagePath", ["loc", [null, [34, 38], [34, 61]]]]], [], 0, 1, ["loc", [null, [34, 28], [38, 28]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 20
                    },
                    "end": {
                      "line": 40,
                      "column": 20
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  dom.setAttribute(el1, "class", "question-answer is-reviewing student-answer");
                  var el2 = dom.createTextNode("\n");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("                        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "missingAnswer.image64", ["loc", [null, [32, 34], [32, 55]]]]], [], 0, 1, ["loc", [null, [32, 28], [38, 35]]]]],
                locals: ["missingAnswer"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 27,
                    "column": 16
                  },
                  "end": {
                    "line": 42,
                    "column": 16
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("h4");
                dom.setAttribute(el1, "class", "answer-header");
                var el2 = dom.createTextNode("Missing correct answer(s):");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("ul");
                dom.setAttribute(el1, "class", "student-answers");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "missingAnswers", ["loc", [null, [30, 28], [30, 42]]]]], [], 0, null, ["loc", [null, [30, 20], [40, 29]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 12
                },
                "end": {
                  "line": 43,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer(s):");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
              morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "studentQuestion.answers", ["loc", [null, [15, 28], [15, 51]]]]], [], 0, null, ["loc", [null, [15, 20], [25, 29]]]], ["block", "if", [["get", "missingAnswers.length", ["loc", [null, [27, 22], [27, 43]]]]], [], 1, null, ["loc", [null, [27, 16], [42, 23]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 8
              },
              "end": {
                "line": 44,
                "column": 8
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isUnanswered", ["loc", [null, [9, 18], [9, 30]]]]], [], 0, 1, ["loc", [null, [9, 12], [43, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 20
                },
                "end": {
                  "line": 51,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("img");
              dom.setAttribute(el1, "alt", "");
              dom.setAttribute(el1, "style", "max-width: 100%");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element5, 'src');
              return morphs;
            },
            statements: [["attribute", "src", ["get", "answer.image64", ["loc", [null, [50, 35], [50, 49]]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 20
                  },
                  "end": {
                    "line": 53,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("img");
                dom.setAttribute(el1, "alt", "");
                dom.setAttribute(el1, "style", "max-width: 100%");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element4, 'src');
                return morphs;
              },
              statements: [["attribute", "src", ["get", "answer.imagePath", ["loc", [null, [52, 35], [52, 51]]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 55,
                      "column": 24
                    },
                    "end": {
                      "line": 57,
                      "column": 24
                    }
                  },
                  "moduleName": "vimme/components/vimme-test-question/template.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "vimme-audio", [], ["src", ["subexpr", "@mut", [["get", "answer.audioPath", ["loc", [null, [56, 46], [56, 62]]]]], [], []], "style", "position: absolute; left: 100%; top: 50%; margin-top: -14px; margin-left: 3px;"], ["loc", [null, [56, 28], [56, 151]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 53,
                    "column": 20
                  },
                  "end": {
                    "line": 58,
                    "column": 20
                  }
                },
                "moduleName": "vimme/components/vimme-test-question/template.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createUnsafeMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["content", "answer.title", ["loc", [null, [54, 24], [54, 42]]]], ["block", "if", [["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [55, 35], [55, 49]]]], ["get", "answer.audioPath", ["loc", [null, [55, 50], [55, 66]]]], ["get", "question.answersAudio", ["loc", [null, [55, 67], [55, 88]]]], ["subexpr", "not", [["get", "question.noAudio", ["loc", [null, [55, 94], [55, 110]]]]], [], ["loc", [null, [55, 89], [55, 111]]]]], [], ["loc", [null, [55, 30], [55, 112]]]]], [], 0, null, ["loc", [null, [55, 24], [57, 31]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 20
                },
                "end": {
                  "line": 58,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "answer.imagePath", ["loc", [null, [51, 30], [51, 46]]]]], [], 0, 1, ["loc", [null, [51, 20], [58, 20]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 12
              },
              "end": {
                "line": 60,
                "column": 12
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            morphs[1] = dom.createMorphAt(element6, 1, 1);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["question-answer ", ["subexpr", "if", [["get", "answer.isCorrect", ["loc", [null, [48, 48], [48, 64]]]], "is-correct"], [], ["loc", [null, [48, 43], [48, 79]]]]]]], ["block", "if", [["get", "answer.image64", ["loc", [null, [49, 26], [49, 40]]]]], [], 0, 1, ["loc", [null, [49, 20], [58, 27]]]]],
          locals: ["answer"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 62,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "answer-header");
          var el2 = dom.createTextNode("Answer choices:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ol");
          dom.setAttribute(el1, "class", "question-answers");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [8, 14], [8, 25]]]]], [], 0, null, ["loc", [null, [8, 8], [44, 15]]]], ["block", "each", [["get", "question.answers", ["loc", [null, [47, 20], [47, 36]]]]], [], 1, null, ["loc", [null, [47, 12], [60, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 12
                },
                "end": {
                  "line": 69,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              dom.setAttribute(el2, "class", "question-answer is-reviewing student-answer");
              var el3 = dom.createTextNode("Unanswered");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 12
                },
                "end": {
                  "line": 74,
                  "column": 12
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h4");
              dom.setAttribute(el1, "class", "answer-header");
              var el2 = dom.createTextNode("Student answer:");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              dom.setAttribute(el1, "class", "student-answers");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("li");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [3, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["question-answer is-reviewing student-answer ", ["subexpr", "if", [["get", "studentQuestion.isCorrect", ["loc", [null, [72, 80], [72, 105]]]], "is-correct"], [], ["loc", [null, [72, 75], [72, 120]]]]]]], ["content", "studentQuestion.textAnswer", ["loc", [null, [72, 122], [72, 152]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 8
              },
              "end": {
                "line": 75,
                "column": 8
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "isUnanswered", ["loc", [null, [64, 18], [64, 30]]]]], [], 0, 1, ["loc", [null, [64, 12], [74, 19]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 81,
                  "column": 20
                },
                "end": {
                  "line": 83,
                  "column": 20
                }
              },
              "moduleName": "vimme/components/vimme-test-question/template.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "vimme-audio", [], ["src", ["subexpr", "@mut", [["get", "answer.audioPath", ["loc", [null, [82, 42], [82, 58]]]]], [], []], "style", "position: absolute; left: 100%; top: 50%; margin-top: -14px; margin-left: 3px;"], ["loc", [null, [82, 24], [82, 147]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 78,
                "column": 12
              },
              "end": {
                "line": 85,
                "column": 12
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element2, 'class');
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["question-answer ", ["subexpr", "if", [["get", "answer.isCorrect", ["loc", [null, [79, 48], [79, 64]]]], "is-correct"], [], ["loc", [null, [79, 43], [79, 79]]]]]]], ["content", "answer.title", ["loc", [null, [80, 20], [80, 36]]]], ["block", "if", [["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [81, 31], [81, 45]]]], ["get", "answer.audioPath", ["loc", [null, [81, 46], [81, 62]]]], ["get", "question.answersAudio", ["loc", [null, [81, 63], [81, 84]]]], ["subexpr", "not", [["get", "question.noAudio", ["loc", [null, [81, 90], [81, 106]]]]], [], ["loc", [null, [81, 85], [81, 107]]]]], [], ["loc", [null, [81, 26], [81, 108]]]]], [], 0, null, ["loc", [null, [81, 20], [83, 27]]]]],
          locals: ["answer"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 4
            },
            "end": {
              "line": 87,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h4");
          dom.setAttribute(el1, "class", "answer-header question-text-answer");
          var el2 = dom.createTextNode("Possible Answers:");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "student-answers question-text-answer");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "isReviewing", ["loc", [null, [63, 14], [63, 25]]]]], [], 0, null, ["loc", [null, [63, 8], [75, 15]]]], ["block", "each", [["get", "question.answers", ["loc", [null, [78, 20], [78, 36]]]]], [], 1, null, ["loc", [null, [78, 12], [85, 21]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 89,
              "column": 4
            },
            "end": {
              "line": 93,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("\n     ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "question-image");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "alt", "");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["get", "image", ["loc", [null, [91, 23], [91, 28]]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 93,
                "column": 4
              },
              "end": {
                "line": 97,
                "column": 4
              }
            },
            "moduleName": "vimme/components/vimme-test-question/template.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("\n     ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "question-image");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("img");
            dom.setAttribute(el2, "alt", "");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1, 1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            return morphs;
          },
          statements: [["attribute", "src", ["get", "question.src", ["loc", [null, [95, 23], [95, 35]]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 4
            },
            "end": {
              "line": 97,
              "column": 4
            }
          },
          "moduleName": "vimme/components/vimme-test-question/template.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "question.hasImage", ["loc", [null, [93, 14], [93, 31]]]]], [], 0, null, ["loc", [null, [93, 4], [97, 4]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 98,
            "column": 6
          }
        },
        "moduleName": "vimme/components/vimme-test-question/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "position: relative;");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0]);
        var element13 = dom.childAt(element12, [2]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element12, 1, 1);
        morphs[1] = dom.createAttrMorph(element13, 'class');
        morphs[2] = dom.createUnsafeMorphAt(element13, 1, 1);
        morphs[3] = dom.createMorphAt(element13, 3, 3);
        morphs[4] = dom.createMorphAt(element12, 4, 4);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "this.showAudio", ["loc", [null, [2, 11], [2, 25]]]], ["get", "question.audioPath", ["loc", [null, [2, 26], [2, 44]]]], ["subexpr", "not", [["get", "question.noAudio", ["loc", [null, [2, 50], [2, 66]]]]], [], ["loc", [null, [2, 45], [2, 67]]]]], [], ["loc", [null, [2, 6], [2, 68]]]]], [], 0, null, ["loc", [null, [2, 0], [4, 7]]]], ["attribute", "class", ["concat", ["question-text ", ["subexpr", "if", [["get", "question.isVertical", ["loc", [null, [5, 31], [5, 50]]]], "is-vertical"], [], ["loc", [null, [5, 26], [5, 66]]]], " ", ["subexpr", "if", [["get", "isUnanswered", ["loc", [null, [5, 72], [5, 84]]]], "is-unanswered"], [], ["loc", [null, [5, 67], [5, 102]]]], " ", ["subexpr", "if", [["get", "hideAnswers", ["loc", [null, [5, 108], [5, 119]]]], "hide-answers"], [], ["loc", [null, [5, 103], [5, 136]]]]]]], ["content", "question.title", ["loc", [null, [6, 4], [6, 24]]]], ["block", "unless", [["get", "question.isTextQuestion", ["loc", [null, [7, 14], [7, 37]]]]], [], 1, 2, ["loc", [null, [7, 4], [87, 15]]]], ["block", "if", [["get", "image", ["loc", [null, [89, 10], [89, 15]]]]], [], 3, 4, ["loc", [null, [89, 4], [97, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});