define('vimme/controllers/sub-admin/reports/week', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {

  var guideType = _vimmeConfigEnvironment['default'].environment === 'core' ? 'VCL' : 'VL';

  exports['default'] = _ember['default'].Controller.extend({
    isDemo: (function () {
      return _vimmeConfigEnvironment['default'].environment === 'demo' || _vimmeConfigEnvironment['default'].environment === 'demo-dev';
    }).property(),

    queryParams: ['grade', 'teacher', 'print'],

    pacingGuide: (function () {
      var grade = this.get('selectedGrade');

      if (!grade) {
        return null;
      }

      return this.assets.resolve('assets/pacing-guides/grade-' + grade.get('id') + '-pacing-guide-' + guideType + '.pdf');
    }).property('selectedGrade'),

    averages: null,

    selectedGrade: null,
    selectedTeacher: null,
    selectedSchoolYear: null,

    grade: null,
    teacher: null,
    print: false,

    showTeachers: false,
    teachers: null,

    showGradeAverages: false,
    showTeacherAverages: false,
    showStudentAverages: false,

    currentSortDirection: 'desc',
    currentSortColumn: 'grade-column',

    schoolYears: _ember['default'].computed('model.schoolYears.@each.isCompleted', function () {
      return this.get('model.schoolYears').filterBy('isCompleted', true);
    }),

    watchSelectedSchoolYear: (function () {
      var selectedSchoolYear = this.get('selectedSchoolYear');
      if (selectedSchoolYear) {
        this.transitionToRoute('sub-admin.reports.yearly', selectedSchoolYear.get('id'));
      }
    }).observes('selectedSchoolYear'),

    initAverages: (function () {
      var data = this.get('model.gradeAveragesRaw');

      var averages = [];
      var keys = {};

      if (data && data.get('length') > 0 && !this.get('grade') && !this.get('teacher')) {
        data.forEach(function (item) {
          var gradeID = item.get('grade.id');
          var schoolID = item.get('school.id');

          if (keys[gradeID + schoolID] === undefined) {
            averages.push({
              grade: item.get('grade'),
              school: item.get('school'),
              weeklyAverages: [],
              total: item.get('total')
            });
            keys[gradeID + schoolID] = averages.length - 1;
          }

          averages[keys[gradeID + schoolID]].weeklyAverages.push(item);
        });

        this.set('showGradeAverages', true);
        this.set('averages', averages);
      }
    }).on('init').observes('model.gradeAveragesRaw', 'grade', 'teacher'),

    setGrade: (function () {
      var selectedGrade = this.get('selectedGrade'),
          grade = this.get('grade');

      if (selectedGrade && selectedGrade.get('id') !== grade) {
        this.set('grade', selectedGrade.get('id'));
      } else if (grade && selectedGrade === null) {
        this.set('grade', null);
      }
    }).observes('selectedGrade'),

    watchGrade: (function () {
      var grade = this.get('grade'),
          teacher = this.get('teacher'),
          teachers = this.get('teachers'),
          sGrade = this.get('selectedGrade'),
          grades = this.get('model.grades'),
          _this = this;

      if (!grades) {
        return;
      }

      if (grade && (!sGrade || grade !== sGrade.get('id'))) {
        this.set('selectedGrade', grades.findBy('id', grade));
      }

      if (grade) {
        if (!teachers || teachers.get('firstObject.grade.id') !== grade) {
          this.store.find('teacher', { grade: grade }).then(function (teachers) {
            teachers = teachers.filterBy('isPlaceholder', false).sortBy('school.title', 'lastThenFirst');
            if (teachers.get('length') > 0) {
              _this.set('selectedTeacher', null);
              _this.set('showTeachers', true);
              _this.set('teachers', teachers);
            } else {
              _this.set('teacher', null);
            }
          });
        }

        if (!teacher) {
          this.store.find('perTeacherAverage', { grade: grade }).then(function (data) {
            var averages = [];
            var keys = {};

            if (data.get('length') > 0) {
              data.forEach(function (item) {
                var teacherID = item.get('teacher.id');

                if (keys[teacherID] === undefined) {
                  averages.push({
                    teacher: item.get('teacher'),
                    weeklyAverages: [],
                    total: item.get('total'),
                    grade: { id: grade }
                  });
                  keys[teacherID] = averages.length - 1;
                }

                averages[keys[teacherID]].weeklyAverages.push(item);
              });

              _this.set('showTeacherAverages', true);
              _this.set('showGradeAverages', false);
              _this.set('currentSortColumn', 'teacher-column');
              _this.set('averages', averages);
            }
          });
        }
      } else {
        this.set('grade', null);
        this.set('teacher', null);
        this.set('teachers', null);
        this.set('showTeachers', false);
        this.set('selectedTeacher', null);
        this.set('selectedGrade', null);
        this.set('showTeacherAverages', false);
      }
    }).observes('model.grades', 'grade', 'teacher'),

    setTeacher: (function () {
      var selectedTeacher = this.get('selectedTeacher'),
          teacher = this.get('teacher');

      if (selectedTeacher && selectedTeacher.get('id') !== teacher) {
        this.set('teacher', selectedTeacher.get('id'));
      } else if (teacher && selectedTeacher === null) {
        this.set('teacher', null);
      }
    }).observes('selectedTeacher'),

    watchTeacher: (function () {
      var teachers = this.get('teachers'),
          teacher = this.get('teacher'),
          grade = this.get('grade'),
          _this = this,
          fTeacher = null;

      if (!teachers) {
        return;
      }

      if (teacher) {
        fTeacher = teachers.findBy('id', teacher);
        if (fTeacher) {
          this.set('selectedTeacher', fTeacher);

          this.store.find('studentTest', { teacher: teacher }).then(function (data) {
            var averages = [];
            var keys = {};

            data = data.filterBy('scheduledTest.test.isPractice', false);

            if (data.get('length') > 0) {
              data.forEach(function (item) {
                var studentID = item.get('student.id');

                if (keys[studentID] === undefined) {
                  averages.push({
                    student: item.get('student'),
                    weeklyAverages: [],
                    total: item.get('total'),
                    grade: { id: grade }
                  });
                  keys[studentID] = averages.length - 1;
                }

                averages[keys[studentID]].weeklyAverages.push(item);
              });

              averages = averages.toArray().sort(function (a, b) {
                var aAvgs = a.weeklyAverages,
                    bAvgs = b.weeklyAverages,
                    aSum = aAvgs.map(function (item) {
                  return item.get('percent');
                }).reduce(function (last, next) {
                  return last + next;
                }, 0),
                    bSum = bAvgs.map(function (item) {
                  return item.get('percent');
                }).reduce(function (last, next) {
                  return last + next;
                }, 0),
                    aRatio = aSum / aAvgs.get('length'),
                    bRatio = bSum / bAvgs.get('length');

                return bRatio - aRatio;
              });

              _this.set('showStudentAverages', true);
              _this.set('showTeacherAverages', false);
              _this.set('currentSortColumn', 'average-column');
              _this.set('averages', averages);
            }
          });
        } else {
          this.set('teacher', null);
          this.set('showStudentAverages', false);
        }
      } else {
        this.set('teacher', null);
        this.set('showStudentAverages', false);
      }
    }).observes('teacher', 'teachers'),

    actions: {
      sortAveragesWeek: function sortAveragesWeek(week) {
        var averages = this.get('averages'),
            weekNum = week.get('number'),
            sorted;

        if (this.get('currentSortColumn') === 'W' + weekNum && this.get('currentSortDirection') === 'desc') {
          this.set('currentSortDirection', 'asc');

          sorted = averages.toArray().sort(function (a, b) {
            var aItem = a.weeklyAverages.find(function (weeklyAverage) {
              if (weeklyAverage.get('test')) {
                return weeklyAverage.get('test.week.number') === weekNum;
              } else if (weeklyAverage.get('scheduledTest')) {
                return weeklyAverage.get('scheduledTest.test.week.number') === weekNum;
              }
            });
            var bItem = b.weeklyAverages.find(function (weeklyAverage) {
              if (weeklyAverage.get('test')) {
                return weeklyAverage.get('test.week.number') === weekNum;
              } else if (weeklyAverage.get('scheduledTest')) {
                return weeklyAverage.get('scheduledTest.test.week.number') === weekNum;
              }
            });

            if (!aItem && !bItem) {
              return 0;
            } else if (!aItem) {
              return 1;
            } else if (!bItem) {
              return -1;
            }

            return aItem.get('percent') - bItem.get('percent');
          });
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', 'W' + weekNum);

          sorted = averages.toArray().sort(function (a, b) {
            var aItem = a.weeklyAverages.find(function (weeklyAverage) {
              if (weeklyAverage.get('test')) {
                return weeklyAverage.get('test.week.number') === weekNum;
              } else if (weeklyAverage.get('scheduledTest')) {
                return weeklyAverage.get('scheduledTest.test.week.number') === weekNum;
              }
            });
            var bItem = b.weeklyAverages.find(function (weeklyAverage) {
              if (weeklyAverage.get('test')) {
                return weeklyAverage.get('test.week.number') === weekNum;
              } else if (weeklyAverage.get('scheduledTest')) {
                return weeklyAverage.get('scheduledTest.test.week.number') === weekNum;
              }
            });

            if (!aItem && !bItem) {
              return 0;
            } else if (!aItem) {
              return 1;
            } else if (!bItem) {
              return -1;
            }

            return bItem.get('percent') - aItem.get('percent');
          });
        }

        this.set('averages', sorted);
      },
      sortAveragesOther: function sortAveragesOther(column) {
        var averages = this.get('averages'),
            currentSortColumn = this.get('currentSortColumn'),
            currentSortDirection = this.get('currentSortDirection'),
            sorted;

        if (column === currentSortColumn && currentSortDirection === 'desc') {
          this.set('currentSortDirection', 'asc');

          sorted = averages.toArray().reverse();
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', column);

          if (column === 'grade-column') {
            sorted = averages.toArray().sort(function (a, b) {
              return a.grade.get('id') - b.grade.get('id');
            });
          } else if (column === 'student-column') {
            sorted = averages.toArray().sort(function (a, b) {
              var aName = a.student.get('lastThenFirst').toLowerCase(),
                  bName = b.student.get('lastThenFirst').toLowerCase();

              if (aName < bName) {
                return -1;
              } else if (aName > bName) {
                return 1;
              } else {
                return 0;
              }
            });
          } else if (column === 'teacher-column') {
            sorted = averages.toArray().sort(function (a, b) {
              var aName = a.teacher.get('lastThenFirst').toLowerCase(),
                  bName = b.teacher.get('lastThenFirst').toLowerCase();

              if (aName < bName) {
                return -1;
              } else if (aName > bName) {
                return 1;
              } else {
                return 0;
              }
            });
          } else if (column === 'average-column') {
            sorted = averages.toArray().sort(function (a, b) {
              var aTotal = a.weeklyAverages.map(function (item) {
                return item.get('percent');
              }).reduce(function (last, next) {
                return last + next;
              }, 0);
              var bTotal = b.weeklyAverages.map(function (item) {
                return item.get('percent');
              }).reduce(function (last, next) {
                return last + next;
              }, 0);

              var aRatio = aTotal / a.weeklyAverages.get('length');
              var bRatio = bTotal / b.weeklyAverages.get('length');

              return bRatio - aRatio;
            });
          } else {
            sorted = averages;
          }
        }

        this.set('averages', sorted);
      },
      printView: function printView() {
        this.toggleProperty('print');
      }
    }
  });
});