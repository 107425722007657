define('vimme/components/mathbots-student-reporting/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['content', 'student-reports'],

    grid: _ember['default'].computed('multDivUserLevels', function () {
      var levels = this.get('multDivUserLevels');
      var rows = [];
      for (var i = 0; i < 10; i++) {
        var columns = [];
        for (var j = 0; j < 10; j++) {
          var item = !i || !j;
          columns[j] = item;
        }
        rows[i] = columns;
      }
      levels.forEach(function (level) {
        if (level.get('hasBeenPassed')) {
          switch (level.get('level.order')) {
            case 16:
              rows[2 - 1][2 - 1] = true;
              rows[3 - 1][3 - 1] = true;
              rows[4 - 1][4 - 1] = true;
              rows[5 - 1][5 - 1] = true;
              rows[6 - 1][6 - 1] = true;
              rows[7 - 1][7 - 1] = true;
              rows[8 - 1][8 - 1] = true;
              rows[9 - 1][9 - 1] = true;
              break;
            case 17:
              rows[6 - 1][7 - 1] = true;
              rows[7 - 1][6 - 1] = true;
              rows[4 - 1][7 - 1] = true;
              rows[7 - 1][4 - 1] = true;
              rows[8 - 1][7 - 1] = true;
              rows[7 - 1][8 - 1] = true;
              rows[4 - 1][8 - 1] = true;
              rows[8 - 1][4 - 1] = true;
              rows[6 - 1][4 - 1] = true;
              rows[4 - 1][6 - 1] = true;
              break;
            case 18:
              rows[6 - 1][8 - 1] = true;
              rows[8 - 1][6 - 1] = true;
              rows[5 - 1][9 - 1] = true;
              rows[9 - 1][5 - 1] = true;
              rows[5 - 1][7 - 1] = true;
              rows[7 - 1][5 - 1] = true;
              break;
            case 19:
              rows[9 - 1][2 - 1] = true;
              rows[2 - 1][9 - 1] = true;
              rows[9 - 1][3 - 1] = true;
              rows[3 - 1][9 - 1] = true;
              rows[9 - 1][4 - 1] = true;
              rows[4 - 1][9 - 1] = true;
              rows[9 - 1][6 - 1] = true;
              rows[6 - 1][9 - 1] = true;
              rows[9 - 1][7 - 1] = true;
              rows[7 - 1][9 - 1] = true;
              rows[9 - 1][8 - 1] = true;
              rows[8 - 1][9 - 1] = true;
              break;
            case 20:
              rows[3 - 1][2 - 1] = true;
              rows[2 - 1][3 - 1] = true;
              rows[3 - 1][4 - 1] = true;
              rows[4 - 1][3 - 1] = true;
              rows[3 - 1][5 - 1] = true;
              rows[5 - 1][3 - 1] = true;
              rows[3 - 1][6 - 1] = true;
              rows[6 - 1][3 - 1] = true;
              rows[3 - 1][7 - 1] = true;
              rows[7 - 1][3 - 1] = true;
              rows[3 - 1][8 - 1] = true;
              rows[8 - 1][3 - 1] = true;
              break;
            case 21:
              rows[4 - 1][2 - 1] = true;
              rows[2 - 1][4 - 1] = true;
              rows[5 - 1][2 - 1] = true;
              rows[2 - 1][5 - 1] = true;
              rows[6 - 1][2 - 1] = true;
              rows[2 - 1][6 - 1] = true;
              rows[7 - 1][2 - 1] = true;
              rows[2 - 1][7 - 1] = true;
              rows[8 - 1][2 - 1] = true;
              rows[2 - 1][8 - 1] = true;
              break;
            case 22:
              rows[4 - 1][5 - 1] = true;
              rows[5 - 1][4 - 1] = true;
              rows[6 - 1][5 - 1] = true;
              rows[5 - 1][6 - 1] = true;
              rows[8 - 1][5 - 1] = true;
              rows[5 - 1][8 - 1] = true;
              break;
            case 23:
              rows[10 - 1][2 - 1] = true;
              rows[2 - 1][10 - 1] = true;
              rows[10 - 1][3 - 1] = true;
              rows[3 - 1][10 - 1] = true;
              rows[10 - 1][4 - 1] = true;
              rows[4 - 1][10 - 1] = true;
              rows[10 - 1][5 - 1] = true;
              rows[5 - 1][10 - 1] = true;
              rows[10 - 1][6 - 1] = true;
              rows[6 - 1][10 - 1] = true;
              rows[10 - 1][7 - 1] = true;
              rows[7 - 1][10 - 1] = true;
              rows[10 - 1][8 - 1] = true;
              rows[8 - 1][10 - 1] = true;
              rows[10 - 1][9 - 1] = true;
              rows[9 - 1][10 - 1] = true;
              rows[10 - 1][10 - 1] = true;
              rows[10 - 1][10 - 1] = true;
              break;
          }
        }
      });
      return rows;
    })
  });
});