define('vimme/routes/teacher/management', ['exports', 'vimme/routes/teacher'], function (exports, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    queryParams: {
      unassigned: {
        refreshModel: true
      }
    },

    model: function model(params) {
      if (params.unassigned) {
        return this.store.query('student', { unassigned: true });
      }

      return this.store.query('student', { unassigned: false });
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});