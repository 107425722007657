define('vimme/components/vimme-week-view-row/component', ['exports', 'ember', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _ember, _vimmeUtilsRound, _vimmeUtilsColor) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    paddedWeeklyAverages: (function () {
      var weeklyAverages = this.get('average.weeklyAverages'),
          grade = this.get('average.grade.id'),
          weeks = this.get('weeks');

      return weeks.map(function (week, num) {
        var weeklyAverage = weeklyAverages.find(function (weeklyAverage) {
          if (weeklyAverage.get('test')) {
            return weeklyAverage.get('test.week.number') === week.get('number');
          } else if (weeklyAverage.get('scheduledTest')) {
            return weeklyAverage.get('scheduledTest.test.week.number') === week.get('number');
          }
        });

        var scoreColor = weeklyAverage ? (0, _vimmeUtilsColor['default'])(weeklyAverage.get('roundedPercent'), grade) : null;

        return { average: weeklyAverage || null, num: num + 1, color: scoreColor };
      });
    }).property('average.{weeklyAverages,grade.id}'),

    percentages: _ember['default'].computed.mapBy('average.weeklyAverages', 'percent'),

    percentagesSum: _ember['default'].computed.sum('percentages'),

    percent: (function () {
      return this.get('percentagesSum') / this.get('average.weeklyAverages.length');
    }).property('average.weeklyAverages.length', 'percentagesSum'),

    roundedPercent: (function () {
      return (0, _vimmeUtilsRound['default'])(this.get('percent'));
    }).property('percent'),

    formattedPercent: (function () {
      return this.get('roundedPercent') + '%';
    }).property('roundedPercent'),

    color: (function () {
      return (0, _vimmeUtilsColor['default'])(this.get('roundedPercent'), this.get('average.grade.id'));
    }).property('roundedPercent', 'average.grade.id')
  });
});