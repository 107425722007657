define('vimme/controllers/admin/builder/quiz-questions/quiz-question', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSaving: false,

    actions: {
      'delete': function _delete() {
        var _this = this;

        this.set('isSaving', true);

        this.get('model.quizQuestion').destroyRecord().then(function () {
          _this.store.all('category').filterBy('id', null).invoke('deleteRecord');
          _this.set('isSaving', false);
          _this.transitionToRoute('admin.builder.quiz-questions');
        })['catch'](function (quizQuestion) {
          quizQuestion.rollback();
          _this.set('isSaving', false);
        });
      },
      save: function save(quizQuestion) {
        var _this2 = this;

        this.set('isSaving', true);

        quizQuestion.save().then(function () {
          _this2.store.all('category').filterBy('id', null).invoke('deleteRecord');
          _this2.set('isSaving', false);
        })['catch'](function () {
          _this2.get('model.quizQuestion').rollback();
          _this2.set('isSaving', false);
        });
      }
    }
  });
});