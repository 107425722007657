define('vimme/controllers/application', ['exports', 'ember', 'npm:jwt-decode', 'vimme/config/environment'], function (exports, _ember, _npmJwtDecode, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    hideHeader: false,

    date: new Date().getFullYear(),

    config: _vimmeConfigEnvironment['default'],

    isCore: (function () {
      return _vimmeConfigEnvironment['default'].environment === 'core';
    }).property(),

    tokenParam: _ember['default'].computed('session.data.authenticated.token', function () {
      var token = this.get('session.data.authenticated.token');
      if (!token) return '';

      return '?token=' + token;
    }),

    referenceSheet: (function () {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var grade = _ref.grade;

      if (grade && grade >= 4 && grade <= 9) {
        return this.assets.resolve('assets/reference-sheets/istep-grade-' + grade + '-reference-sheet.pdf');
      }
    }).property('session.data.authenticated.token'),

    showEula: (function () {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref2.role;
      var eula = _ref2.eula;

      return role && role !== 'Student' && !(eula || this.get('session.data.signedEula'));
    }).property('session.data.{signedEula,authenticated.token}'),

    role: (function () {
      var token = this.get('session.data.authenticated.token');

      var _ref3 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref3.role;

      return role;
    }).property('session.data.authenticated.token'),

    name: (function () {
      var token = this.get('session.data.authenticated.token');

      var _ref4 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref4.role;
      var firstName = _ref4.firstName;
      var lastName = _ref4.lastName;

      return role === 'Admin' ? '' : (firstName + '').trim() + ' ' + (lastName + '').trim();
    }).property('session.data.authenticated.token'),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').set('data.signedEula', undefined);
        this.get('session').invalidate();
      },
      agree: function agree() {
        var token = this.get('session.data.authenticated.token');

        var _decode = (0, _npmJwtDecode['default'])(token);

        var role = _decode.role;
        var id = _decode.id;

        var _this = this,
            user = this.store.find(role.dasherize(), id);

        user.then(function (user) {
          user.set('eula', true);
          user.save().then(function (user) {
            _this.get('session').set('data.signedEula', user.get('eula'));
          });
        });
      }
    }
  });
});