define('vimme/routes/teacher/builder/paper-quizzes/index', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var teacher = this.modelFor('teacher.builder.paper-quizzes').teacher;
      return _ember['default'].RSVP.hash({
        categories: this.modelFor('teacher.builder.paper-quizzes').categories,
        difficulties: this.modelFor('teacher.builder.paper-quizzes').difficulties,
        grades: this.modelFor('teacher.builder.paper-quizzes').grades,
        quiz: this.store.createRecord('quiz', {
          title: null,
          teacher: teacher
        }),
        teacher: teacher
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});