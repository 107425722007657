define('vimme/controllers/info', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    authAjax: _ember['default'].inject.service(),

    name: '',
    email: '',
    school: '',
    title: '',
    how: '',

    showErrors: false,

    nameError: (function () {
      return !this.get('name');
    }).property('name'),
    emailError: (function () {
      return !this.get('email') || this.get('emailInvalid');
    }).property('email', 'emailInvalid'),
    schoolError: (function () {
      return !this.get('school');
    }).property('school'),
    titleError: (function () {
      return !this.get('title');
    }).property('title'),
    howError: (function () {
      return !this.get('how');
    }).property('how'),

    isValid: (function () {
      return !this.get('nameError') && this.get('email') && !this.get('schoolError') && !this.get('titleError') && !this.get('howError');
    }).property('nameError', 'email', 'emailValidError', 'schoolError', 'titleError', 'howError'),

    isInvalid: (function () {
      return !this.get('isValid');
    }).property('isValid'),

    actions: {
      showVideo: function showVideo() {
        this.set('showVideo', true);
        this.set('ytid', 'jH7_-JQ4CvY');
        _ember['default'].$('html,body').animate({
          scrollTop: '54px'
        }, 500);
      },
      hideVideo: function hideVideo() {
        this.set('showVideo', false);
        this.set('ytid', '');
        _ember['default'].$('html,body').animate({
          scrollTop: _ember['default'].$('.home-content').position().top
        }, 1000);
      },
      playVideo: function playVideo() {
        if (!WURFL.is_mobile) {
          this.get('myPlayer.player').playVideo();
        }
      },
      scroll: function scroll() {
        _ember['default'].$('html,body').animate({
          scrollTop: _ember['default'].$('.home-content').position().top
        }, 1000);
      },
      submitForm: function submitForm() {
        var _this = this,
            name = this.get('name'),
            email = this.get('email'),
            school = this.get('school'),
            title = this.get('title'),
            how = this.get('how'),
            hash = {
          type: 'POST',
          data: {
            name: name,
            email: email,
            school: school,
            title: title,
            how: how
          }
        };

        if (this.get('isValid')) {
          this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/contact', hash).then(function () {
            _this.set('formSubmitted', true);
          })['catch'](function (data) {
            if (data && data.responseJSON && data.responseJSON.errors && data.responseJSON.errors.email) {
              _this.set('emailInvalid', true);
            }

            _this.set('showErrors', true);
          });
        }
      }
    }
  });
});