define('vimme/routes/sub-admin/quiz-builder/index', ['exports', 'ember', 'vimme/routes/sub-admin'], function (exports, _ember, _vimmeRoutesSubAdmin) {
  exports['default'] = _vimmeRoutesSubAdmin['default'].extend({
    model: function model() {
      var subAdmin = this.modelFor('sub-admin.quiz-builder').subAdmin;
      return _ember['default'].RSVP.hash({
        categories: this.modelFor('sub-admin.quiz-builder').categories,
        difficulties: this.modelFor('sub-admin.quiz-builder').difficulties,
        grades: this.modelFor('sub-admin.quiz-builder').grades,
        quiz: this.store.createRecord('quiz', {
          title: null,
          subAdmin: subAdmin
        }),
        subAdmin: subAdmin
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.store.all('quiz').filterBy('id', null).invoke('deleteRecord');
      }
    }
  });
});