define('vimme/models/level', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    order: _emberData['default'].attr('number'),
    title: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    totalTime: _emberData['default'].attr('number'),

    game: _emberData['default'].belongsTo('game', { async: true }),

    questions: _emberData['default'].hasMany('question', { async: true }),
    userLevels: _emberData['default'].hasMany('user-level', { async: true })
  });
});