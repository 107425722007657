define("vimme/templates/teacher/reports/week", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 24
                  },
                  "end": {
                    "line": 22,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row reports-average-row");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createTextNode("Average");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element8 = dom.childAt(fragment, [1, 3]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element8, 'class');
                morphs[1] = dom.createMorphAt(element8, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [20, 51], [20, 56]]]]]]], ["content", "formattedPercent", ["loc", [null, [20, 60], [20, 80]]]]],
              locals: ["formattedPercent", "color"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 20
                },
                "end": {
                  "line": 23,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [17, 53], [17, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [17, 73], [17, 80]]]]], [], []]], 0, null, ["loc", [null, [17, 24], [22, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 25,
                    "column": 24
                  },
                  "end": {
                    "line": 30,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "cell");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var element7 = dom.childAt(element6, [3]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
                morphs[1] = dom.createAttrMorph(element7, 'class');
                morphs[2] = dom.createMorphAt(element7, 0, 0);
                return morphs;
              },
              statements: [["content", "average.student.lastThenFirst", ["loc", [null, [27, 50], [27, 83]]]], ["attribute", "class", ["concat", ["cell ", ["get", "color", ["loc", [null, [28, 51], [28, 56]]]]]]], ["content", "formattedPercent", ["loc", [null, [28, 60], [28, 80]]]]],
              locals: ["formattedPercent", "color"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 20
                },
                "end": {
                  "line": 31,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [25, 53], [25, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [25, 73], [25, 80]]]]], [], []]], 0, null, ["loc", [null, [25, 24], [30, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 24
                },
                "end": {
                  "line": 39,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element5, 'class');
              morphs[1] = dom.createElementMorph(element5);
              morphs[2] = dom.createMorphAt(element5, 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header ", ["get", "week.shortName", ["loc", [null, [38, 54], [38, 68]]]]]]], ["element", "action", ["sortAveragesWeek", ["get", "week", ["loc", [null, [38, 100], [38, 104]]]]], [], ["loc", [null, [38, 72], [38, 106]]]], ["content", "week.shortName", ["loc", [null, [38, 107], [38, 125]]]]],
            locals: ["week"],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 44,
                      "column": 32
                    },
                    "end": {
                      "line": 46,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/teacher/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element4, 'class');
                  morphs[1] = dom.createMorphAt(element4, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.average.color", ["loc", [null, [45, 55], [45, 88]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [45, 92], [45, 140]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 24
                  },
                  "end": {
                    "line": 48,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row reports-average-row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [44, 40], [44, 60]]]]], [], 0, null, ["loc", [null, [44, 32], [46, 41]]]]],
              locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 41,
                  "column": 20
                },
                "end": {
                  "line": 49,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [42, 53], [42, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [42, 73], [42, 80]]]]], [], []]], 0, null, ["loc", [null, [42, 24], [48, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        var child4 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 53,
                      "column": 32
                    },
                    "end": {
                      "line": 55,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/teacher/reports/week.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element3, 'class');
                  morphs[1] = dom.createMorphAt(element3, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedWeeklyAverage.color", ["loc", [null, [54, 55], [54, 80]]]]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [54, 84], [54, 132]]]]],
                locals: ["paddedWeeklyAverage"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 24
                  },
                  "end": {
                    "line": 57,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 3,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [53, 40], [53, 60]]]]], [], 0, null, ["loc", [null, [53, 32], [55, 41]]]]],
              locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 50,
                  "column": 20
                },
                "end": {
                  "line": 58,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [51, 53], [51, 64]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [51, 73], [51, 80]]]]], [], []]], 0, null, ["loc", [null, [51, 24], [57, 48]]]]],
            locals: ["average"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 61,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header average-column");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1, 1]);
            var element10 = dom.childAt(element9, [1]);
            var element11 = dom.childAt(element10, [1]);
            var element12 = dom.childAt(element10, [3]);
            var element13 = dom.childAt(fragment, [3, 1]);
            var element14 = dom.childAt(element13, [1]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element10, 'class');
            morphs[1] = dom.createElementMorph(element11);
            morphs[2] = dom.createElementMorph(element12);
            morphs[3] = dom.createMorphAt(element9, 3, 3);
            morphs[4] = dom.createMorphAt(element9, 4, 4);
            morphs[5] = dom.createAttrMorph(element14, 'class');
            morphs[6] = dom.createMorphAt(element14, 1, 1);
            morphs[7] = dom.createMorphAt(element13, 3, 3);
            morphs[8] = dom.createMorphAt(element13, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [12, 45], [12, 62]]]]]]], ["element", "action", ["sortAveragesOther", "student-column"], [], ["loc", [null, [13, 64], [13, 111]]]], ["element", "action", ["sortAveragesOther", "average-column"], [], ["loc", [null, [14, 64], [14, 111]]]], ["block", "each", [["get", "teacherAverages", ["loc", [null, [16, 28], [16, 43]]]]], [], 0, null, ["loc", [null, [16, 20], [23, 29]]]], ["block", "each", [["get", "averages", ["loc", [null, [24, 28], [24, 36]]]]], [], 1, null, ["loc", [null, [24, 20], [31, 29]]]], ["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [36, 45], [36, 62]]]]]]], ["block", "each", [["get", "model.weeks", ["loc", [null, [37, 32], [37, 43]]]]], [], 2, null, ["loc", [null, [37, 24], [39, 33]]]], ["block", "each", [["get", "teacherAverages", ["loc", [null, [41, 28], [41, 43]]]]], [], 3, null, ["loc", [null, [41, 20], [49, 29]]]], ["block", "each", [["get", "averages", ["loc", [null, [50, 28], [50, 36]]]]], [], 4, null, ["loc", [null, [50, 20], [58, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 62,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "averages", ["loc", [null, [9, 14], [9, 22]]]]], [], 0, null, ["loc", [null, [9, 8], [61, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 71,
                    "column": 24
                  },
                  "end": {
                    "line": 73,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/teacher/reports/week.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "print-item");
                var el2 = dom.createTextNode("W");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0, 1, 1);
                morphs[1] = dom.createMorphAt(element0, 3, 3);
                return morphs;
              },
              statements: [["content", "paddedWeeklyAverage.num", ["loc", [null, [72, 54], [72, 81]]]], ["content", "paddedWeeklyAverage.average.formattedPercent", ["loc", [null, [72, 83], [72, 131]]]]],
              locals: ["paddedWeeklyAverage"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 12
                },
                "end": {
                  "line": 76,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/teacher/reports/week.hbs"
            },
            arity: 3,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "print-row column");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-general");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-name");
              var el4 = dom.createTextNode("Student: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-avg");
              var el4 = dom.createTextNode("Average: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-details");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(element1, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "average.student.lastThenFirst", ["loc", [null, [67, 69], [67, 102]]]], ["content", "formattedPercent", ["loc", [null, [68, 68], [68, 88]]]], ["block", "each", [["get", "paddedWeeklyAverages", ["loc", [null, [71, 32], [71, 52]]]]], [], 0, null, ["loc", [null, [71, 24], [73, 33]]]]],
            locals: ["formattedPercent", "color", "paddedWeeklyAverages"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 63,
                "column": 8
              },
              "end": {
                "line": 77,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/teacher/reports/week.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "vimme-week-view-row", [], ["weeks", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [64, 41], [64, 52]]]]], [], []], "average", ["subexpr", "@mut", [["get", "average", ["loc", [null, [64, 61], [64, 68]]]]], [], []]], 0, null, ["loc", [null, [64, 12], [76, 36]]]]],
          locals: ["average"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 62,
              "column": 4
            },
            "end": {
              "line": 78,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/week.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "averages", ["loc", [null, [63, 16], [63, 24]]]]], [], 0, null, ["loc", [null, [63, 8], [77, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 80,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/reports/week.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var element16 = dom.childAt(element15, [1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element16, 1, 1);
        morphs[1] = dom.createMorphAt(element16, 3, 3);
        morphs[2] = dom.createMorphAt(element15, 3, 3);
        return morphs;
      },
      statements: [["inline", "print-view-button", [], ["printView", ["subexpr", "action", ["printView"], [], ["loc", [null, [3, 38], [3, 58]]]], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [3, 65], [3, 70]]]]], [], []], "position", "high"], ["loc", [null, [3, 8], [3, 88]]]], ["inline", "color-key", [], ["grade", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [5, 26], [5, 31]]]]], [], []], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [5, 38], [5, 43]]]]], [], []]], ["loc", [null, [5, 8], [5, 45]]]], ["block", "unless", [["get", "print", ["loc", [null, [8, 14], [8, 19]]]]], [], 0, 1, ["loc", [null, [8, 4], [78, 15]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});