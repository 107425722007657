define('vimme/routes/admin/builder/resources/index', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model() {
      var localWeeks = this.store.all('week');
      var localGrades = this.store.all('grade');
      return _ember['default'].RSVP.hash({
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week'),
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade')
      });
    }
  });
});