define('vimme/initializers/asset-map', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports.initialize = initialize;

  function advance(container, application, AssetMap) {
    container.register('assetMap:main', AssetMap, { singleton: true });
    application.inject('controller', 'assets', 'assetMap:main');
    application.inject('component', 'assets', 'assetMap:main');
    application.inject('model', 'assets', 'assetMap:main');
    application.advanceReadiness();
  }

  function initialize(container, application) {
    application.deferReadiness();

    var AssetMap = _ember['default'].Object.extend();

    if (['development', 'testing'].includes(_vimmeConfigEnvironment['default'].environment)) {
      AssetMap.reopen({
        resolve: function resolve(name) {
          return name;
        }
      });
      advance(container, application, AssetMap);
      return;
    }

    var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
      _ember['default'].$.ajax({
        cache: false,
        url: '/assets/assetMap.json',
        dataType: 'json',
        success: resolve
      }).fail(reject);
    });

    promise.then(function (assetMap) {
      AssetMap.reopen({
        assetMap: assetMap,
        resolve: function resolve(name) {
          var prepend = assetMap.prepend ? assetMap.prepend : '';
          var asset = assetMap.assets[name] ? assetMap.assets[name] : name;

          return prepend + asset;
        }
      });
    }, function () {
      AssetMap.reopen({
        resolve: function resolve(name) {
          return name;
        }
      });
    }).then(function () {
      advance(container, application, AssetMap);
    });
  }

  exports['default'] = {
    name: 'asset-map',
    initialize: initialize
  };
});