define('vimme/routes/teacher/builder/quizzes/quiz/question-bank', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        categories: this.store.findAll('category'),
        difficulties: this.store.findAll('difficulty'),
        grades: this.store.all('grade')
      });
    }
  });
});