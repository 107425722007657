define('vimme/components/app-pages/teacher/testing-calendar/component', ['exports', 'ember', 'vimme/utils/date-in-range'], function (exports, _ember, _vimmeUtilsDateInRange) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    store: null,
    pacingGuide: '',
    school: _ember['default'].computed(function () {
      return { isTrial: false };
    }),
    scheduledTests: _ember['default'].computed(function () {
      return [];
    }),
    tests: _ember['default'].computed(function () {
      return [];
    }),

    selectedDate: moment().startOf('day').toDate(),

    isScheduling: false,
    isUnscheduling: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedTest', this.get('tests.firstObject'));
    },

    selectedTest: undefined,

    resetSelectedScheduledTest: _ember['default'].observer('selectedDate', function () {
      this.set('_selectedScheduledTest', undefined);
    }),

    _selectedScheduledTest: undefined,

    isNew: _ember['default'].computed('newScheduledTest', 'selectedScheduledTest', function () {
      return this.get('newScheduledTest.id') === this.get('selectedScheduledTest.id');
    }),

    newScheduledTest: _ember['default'].computed('selectedDate', function () {
      return {
        id: 'new',
        startDate: this.get('selectedDate'),
        endDate: moment(this.get('selectedDate')).endOf('day').toDate()
      };
    }),

    selectedScheduledTest: _ember['default'].computed('selectedScheduledTests.[]', '_selectedScheduledTest', function () {
      return this.get('_selectedScheduledTest') || this.get('selectedScheduledTests.firstObject') || this.get('newScheduledTest');
    }),

    selectedScheduledTests: _ember['default'].computed('scheduledTests.@each.{startDate,endDate}', 'selectedDate', function () {
      var _this = this;

      return this.get('scheduledTests').filter(function (test) {
        return !!_this.get('selectedDate') && (0, _vimmeUtilsDateInRange['default'])(_this.get('selectedDate'), test.get('startDate'), test.get('endDate'));
      });
    }),

    isValid: _ember['default'].computed('selectedScheduledTest.{startDate,endDate}', function () {
      if (!this.get('selectedScheduledTest.startDate') || !this.get('selectedScheduledTest.endDate')) return false;
      if (this.get('selectedScheduledTest.startDate') >= this.get('selectedScheduledTest.endDate')) return false;
      return true;
    }),

    isStartDateDisabled: _ember['default'].computed('selectedScheduledTest.studentTests.length', function () {
      return !!this.get('selectedScheduledTest.studentTests.length');
    }),

    actions: {
      selectDate: function selectDate(selectedDate) {
        this.setProperties({ selectedDate: selectedDate });
      },
      selectTab: function selectTab(_selectedScheduledTest) {
        this.setProperties({ _selectedScheduledTest: _selectedScheduledTest });
      },
      selectTest: function selectTest(selectedTest) {
        // possible alternate approach from filtering test list to just navigate to test.
        // needs to move the calendar to the correct month, which is not built into the calendar.

        // const scheduledTest = this.get('scheduledTests').find((st) => {
        //   return st.get('test.id') === selectedTest.get('id');
        // });
        // if (scheduledTest) {
        //   this.setProperties({
        //       selectedDate: scheduledTest.get('startDate'),
        //       _selectedScheduledTest: scheduledTest,
        //   });
        // } else {
        //   this.setProperties({ selectedTest });
        // }

        this.setProperties({ selectedTest: selectedTest });
      },
      scheduleTest: function scheduleTest() {
        var _this2 = this;

        var selectedScheduledTest = this.get('selectedScheduledTest');
        var scheduledTest = this.get('isNew') ? this.get('store').createRecord('scheduledTest') : selectedScheduledTest;

        if (this.get('isNew')) {
          scheduledTest.set('startDate', selectedScheduledTest.startDate);
          scheduledTest.set('endDate', selectedScheduledTest.endDate);
          scheduledTest.set('test', this.get('selectedTest'));
        }

        this.set('isScheduling', true);

        scheduledTest.save().then(function (scheduledTest) {
          _this2.set('_selectedScheduledTest', scheduledTest);
          _this2.set('isScheduling', false);
          _this2.set('selectedTest', _this2.get('tests.firstObject'));
        }, function () {
          _this2.set('isScheduling', false);
          if (_this2.get('selectedScheduledTest.isNew')) {
            _this2.get('selectedScheduledTest').deleteRecord();
          }
          alert('There was an error scheduling your test!');
        });
      },
      unscheduleTest: function unscheduleTest() {
        var _this3 = this;

        this.set('isUnscheduling', true);

        var selectedScheduledTest = this.get('selectedScheduledTest');

        selectedScheduledTest.destroyRecord().then(function () {
          _this3.resetSelectedScheduledTest();
          _this3.set('isUnscheduling', false);
        }, function () {
          _this3.set('isUnscheduling', false);
          alert('There was an error unscheduling your test!');
        });
      },
      gotoTest: function gotoTest() {
        this.sendAction('gotoTest', this.get('selectedScheduledTest.test.id'));
      },
      toggleReview: function toggleReview() {
        var scheduledTest = this.get('selectedScheduledTest');

        if (scheduledTest) {
          scheduledTest.toggleProperty('isReviewable');
          scheduledTest.save()['catch'](function () {
            scheduledTest.toggleProperty('isReviewable');
          });
        }
      }
    }
  });
});