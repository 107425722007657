define('vimme/controllers/teacher/management', ['exports', 'ember', 'npm:jwt-decode'], function (exports, _ember, _npmJwtDecode) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    applicationController: _ember['default'].inject.controller('application'),
    indexController: _ember['default'].inject.controller('index'),

    queryParams: ['print', 'unassigned'],
    print: false,
    unassigned: false,

    sortAlphabetically: ['lastThenFirst:asc'],
    sortedStudents: _ember['default'].computed.sort('model', 'sortAlphabetically'),

    actions: {
      newStudent: function newStudent() {
        var token = this.get('session.data.authenticated.token');

        var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

        var id = _ref.id;

        var teacher = this.store.find('teacher', id),
            _this = this;

        if (this.get('applicationController.currentRouteName') !== 'teacher.management.index' || this.get('unassigned') || this.get('print')) {
          this.transitionToRoute('teacher.management', { queryParams: { unassigned: false, print: false } });
        }

        teacher.then(function (teacher) {
          var index = _this.get('indexController');

          index.set('lastName', '');
          index.set('middleInitial', '');
          index.set('firstName', '');
          index.set('grade', teacher.get('grade'));
          index.set('gradeNum', teacher.get('grade.id'));
          index.set('teacher', null);
          index.set('username', '');
          index.set('password', '');
        });
      }
    },

    filteredStudents: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var students = this.get('sortedStudents'),
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i');

      if (term) {
        students = students.filter(function (student) {
          return filter.test(student.get('fullName'));
        });
      }

      return students;
    }).property('searchTerm', 'model.[]')
  });
});