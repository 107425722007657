define('vimme/models/school-year-grade-report', ['exports', 'ember-data', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _emberData, _vimmeUtilsRound, _vimmeUtilsColor) {
  exports['default'] = _emberData['default'].Model.extend({
    average: _emberData['default'].attr('number'),

    grade: _emberData['default'].belongsTo('grade', { async: true }),
    schoolYear: _emberData['default'].belongsTo('schoolYear', { async: true }),

    schoolYearWeeklyReports: _emberData['default'].hasMany('schoolYearWeeklyReport', { async: false }),

    roundedPercent: (function () {
      var percent = this.get('average');
      if (percent) {
        return (0, _vimmeUtilsRound['default'])(percent);
      }
    }).property('average'),

    formattedPercent: (function () {
      var percent = this.get('roundedPercent');
      if (percent) {
        return percent + '%';
      }
    }).property('roundedPercent'),

    color: (function () {
      var percent = this.get('roundedPercent');
      var grade = this.get('grade.id');

      return (0, _vimmeUtilsColor['default'])(percent, grade);
    }).property('roundedPercent', 'grade.id')
  });
});