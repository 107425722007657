define('vimme/routes/school-admin/management/students/student', ['exports', 'ember', 'vimme/routes/school-admin'], function (exports, _ember, _vimmeRoutesSchoolAdmin) {
  exports['default'] = _vimmeRoutesSchoolAdmin['default'].extend({
    model: function model(params) {
      return _ember['default'].RSVP.hash({
        student: this.store.find('student', params.student_id),
        teachers: this.modelFor('school-admin.management.students').teachers,
        grades: this.modelFor('school-admin.management.students').grades
      });
    }
  });
});