define('vimme/components/growth-students-report/component', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    authAjax: _ember['default'].inject.service(),

    isLoading: true,

    currentSort: 'student.lastThenFirst',

    averagesSorting: _ember['default'].computed('currentSort', function () {
      return [this.get('currentSort')];
    }),
    sortedAverages: _ember['default'].computed.sort('averages', 'averagesSorting'),

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var teacher = this.get('teacher.id');
      var from = this.get('from.id');
      var to = this.get('to.id');

      this.set('isLoading', true);

      this.get('authAjax').request('/api/growth-students-report?teacher=' + teacher + '&from=' + from + '&to=' + to).then(function (resp) {
        var averages = Object.keys(resp).map(function (k) {
          var results = resp[k];
          var student = _this.students.find(function (g) {
            return g.id === k;
          });
          return {
            student: student,
            diff: results.diff,
            from: results.from,
            to: results.to
          };
        });
        _this.set('averages', averages);
        _this.set('isLoading', false);
      });
    },

    actions: {
      sort: function sort(type) {
        var base = 'student.lastThenFirst';
        var currentSort = this.get('currentSort');

        var _currentSort$split = currentSort.split(':');

        var _currentSort$split2 = _slicedToArray(_currentSort$split, 2);

        var key = _currentSort$split2[0];
        var dir = _currentSort$split2[1];

        if (key === type) {
          if (dir === 'desc') {
            this.set('currentSort', base);
          } else {
            this.set('currentSort', currentSort + ':desc');
          }
        } else {
          this.set('currentSort', type);
        }
      }
    }
  });
});