define('vimme/models/school-year-weekly-report', ['exports', 'ember-data', 'vimme/utils/round'], function (exports, _emberData, _vimmeUtilsRound) {
  exports['default'] = _emberData['default'].Model.extend({
    average: _emberData['default'].attr('number'),

    week: _emberData['default'].belongsTo('week', { async: true }),
    schoolYearGradeReport: _emberData['default'].belongsTo('schoolYearGradeReport', { async: true }),

    roundedPercent: (function () {
      var percent = this.get('average');
      if (percent) {
        return (0, _vimmeUtilsRound['default'])(percent);
      }
    }).property('average'),

    formattedPercent: (function () {
      var percent = this.get('roundedPercent');
      if (percent) {
        return percent + '%';
      }
    }).property('roundedPercent')
  });
});