define('vimme/routes/admin/builder/tests/test', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model(params) {
      var test = this.store.find('test', params.test_id);
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      return _ember['default'].RSVP.hash({
        test: test,
        grades: localGrades.get('length') > 0 ? localGrades : this.store.findAll('grade'),
        weeks: localWeeks.get('length') > 0 ? localWeeks : this.store.findAll('week')
      });
    }
  });
});