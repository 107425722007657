define('vimme/routes/admin/builder/tests', ['exports', 'ember', 'vimme/routes/admin'], function (exports, _ember, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    model: function model() {
      return _ember['default'].RSVP.hash({
        tests: this.modelFor('admin').tests,
        user: this.modelFor('admin').user
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model.tests);
      controller.set('user', model.user);
    },
    actions: {
      saveTest: function saveTest(test) {
        var _this = this;

        test.save().then(function (test) {
          return _this.transitionTo('admin.builder.tests.test', test.get('id'));
        })['catch'](function (error) {
          return alert(error.message);
        });
      },
      deleteTest: function deleteTest(test) {
        var _this2 = this;

        test.destroyRecord().then(function () {
          return _this2.transitionTo('admin.builder.tests');
        })['catch'](function (error) {
          return alert(error.message);
        });
      }
    }
  });
});