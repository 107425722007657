define('vimme/routes/admin/builder', ['exports', 'vimme/routes/admin'], function (exports, _vimmeRoutesAdmin) {
  exports['default'] = _vimmeRoutesAdmin['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);
      if (transition.targetName === 'admin.builder.index') {
        if (transition.sequence === 0) {
          this.replaceWith('admin.builder.tests');
        } else {
          this.transitionTo('admin.builder.tests');
        }
      }
    },
    model: function model() {}
  });
});