define('vimme/controllers/admin/teachers/teacher', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    schools: null,
    selectedSchool: null,
    gradeID: null,

    setUsername: (function () {
      this.set('model.username', this.get('model.email'));
    }).observes('model.email'),

    setSchool: (function () {
      var schoolID = this.get('model.school.id'),
          schools = this.get('schools'),
          school;

      if (schools) {
        school = schools.findBy('id', schoolID);
        this.set('selectedSchool', school);
      }
    }).observes('schools'),

    setGradeID: (function () {
      this.set('gradeID', this.get('model.grade.id'));
    }).observes('model.grade.id'),

    actions: {
      submit: function submit() {
        var grade = this.store.find('grade', this.get('gradeID')),
            _this = this;

        if (grade) {
          grade.then(function (grade) {
            var teacher = _this.get('model');

            teacher.set('grade', grade);
            teacher.set('school', _this.get('selectedSchool'));

            teacher.save();
          });
        }
      }
    }
  });
});