define('vimme/controllers/teacher/questions/question', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    questionsController: _ember['default'].inject.controller('teacher/questions'),

    studentQuestions: _ember['default'].computed.alias('questionsController.studentQuestions'),

    isReviewing: _ember['default'].computed.alias('questionsController.isReviewing'),

    hideAnswers: true,

    school: null, // set in setupController

    setHideAnswers: (function () {
      var isReviewing = this.get('isReviewing');

      if (isReviewing) {
        this.set('hideAnswers', false);
      } else {
        this.set('hideAnswers', true);
      }
    }).observes('isReviewing', 'model'),

    studentQuestion: (function () {
      var studentQuestions = this.get('studentQuestions');

      if (studentQuestions && studentQuestions.get('length') > 0) {
        return studentQuestions.findBy('question.id', this.get('model.id'));
      }
    }).property('studentQuestions.[]', 'model')
  });
});