define('vimme/routes/teacher/test', ['exports', 'ember', 'vimme/routes/teacher', 'npm:jwt-decode'], function (exports, _ember, _vimmeRoutesTeacher, _npmJwtDecode) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    session: _ember['default'].inject.service(),

    model: function model(params) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;
      var id = _ref.id;

      var user = this.store.find(role, id);
      var test = this.store.find('test', params.test_id);

      return _ember['default'].RSVP.hash({
        studentTests: test.then(function (test) {
          return test.get('studentTests');
        }),
        questions: test.then(function (test) {
          return test.get('questions');
        }),
        answers: test.then(function (test) {
          return test.get('answers');
        }),
        school: user.then(function (user) {
          return user.get('school');
        }),
        test: test,
        user: user
      });
    },
    afterModel: function afterModel(model, transition) {
      var dateAvailable = moment(model.test.get('dateAvailable'));
      var today = moment().startOf('day');

      if (dateAvailable.diff(today, 'day') > 0) {
        this.replaceWith('teacher.testing-calendar');
      } else if (transition.targetName === 'teacher.test.index') {
        this.replaceWith('teacher.questions');
      }
    }
  });
});