define('vimme/controllers/guardian-signup', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    authAjax: _ember['default'].inject.service(),

    identification: '',
    identificationConfirmation: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    code: '',

    isSaving: false,
    signupError: false,
    noSignupError: _ember['default'].computed.not('signupError'),

    showAccessCode: false,

    isValid: _ember['default'].computed.and('isIdentValid', 'isIdentConfirmationValid', 'isFirstValid', 'isLastValid', 'isPasswordValid', 'isPasswordConfirmationValid'),
    isInvalid: _ember['default'].computed.not('isValid'),

    isIdentValid: (function () {
      var ident = this.get('identification') || '';
      return !!ident.trim() && ident.includes('@');
    }).property('identification'),
    isIdentInvalid: _ember['default'].computed.not('isIdentValid'),

    isIdentConfirmationValid: (function () {
      var ident = this.get('identification');
      var conf = this.get('identificationConfirmation');
      return ident === conf;
    }).property('identification', 'identificationConfirmation'),
    isIdentConfirmationInvalid: _ember['default'].computed.not('isIdentConfirmationValid'),

    isFirstValid: (function () {
      var first = this.get('firstName') || '';
      return !!first.trim();
    }).property('firstName'),
    isFirstInvalid: _ember['default'].computed.not('isFirstValid'),

    isLastValid: (function () {
      var last = this.get('lastName') || '';
      return !!last.trim();
    }).property('lastName'),
    isLastInvalid: _ember['default'].computed.not('isLastValid'),

    isPasswordValid: (function () {
      var password = this.get('password') || '';
      return password.length >= 5;
    }).property('password'),
    isPasswordInvalid: _ember['default'].computed.not('isPasswordValid'),

    isPasswordConfirmationValid: (function () {
      var password = this.get('password');
      var conf = this.get('passwordConfirmation');
      return password === conf;
    }).property('password', 'passwordConfirmation'),
    isPasswordConfirmationInvalid: _ember['default'].computed.not('isPasswordConfirmationValid'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:jwt', credentials).then(function () {
          _this.set('signupError', false);
        }, function () {
          _this.set('signupError', true);
          alert('The username and/or password entered are invalid.');
        });
      },

      showAccessCode: function showAccessCode() {
        this.set('showAccessCode', true);
      },

      create: function create() {
        var _this2 = this;

        this.set('isSaving', true);

        var email = this.get('identification');
        var username = email;
        var email_confirmation = this.get('identificationConfirmation');
        var first_name = this.get('firstName');
        var last_name = this.get('lastName');
        var password = this.get('password');
        var password_confirmation = this.get('passwordConfirmation');
        var access_code = this.get('code');

        var guardian = {
          email: email,
          email_confirmation: email_confirmation,
          username: username,
          first_name: first_name,
          last_name: last_name,
          password: password,
          password_confirmation: password_confirmation,
          access_code: access_code
        };

        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/guardians', {
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            guardian: guardian
          })
        }).then(function () {
          _this2.set('isSaving', false);
          var credentials = {
            identification: email,
            password: password
          };
          _this2.get('session').authenticate('authenticator:jwt', credentials).then(function () {
            _this2.set('signupError', false);
          }, function () {
            _this2.set('signupError', true);
            alert('The username and/or password entered are invalid.');
          });
        })['catch'](function (error) {
          _this2.set('isSaving', false);

          if (error && _ember['default'].get(error, 'payload.errors') && Object.values(_ember['default'].get(error, 'payload.errors')).length) {
            alert(Object.values(_ember['default'].get(error, 'payload.errors'))[0][0]);
          } else {
            alert('Server Error');
          }
        });
      }
    }
  });
});