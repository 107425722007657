define('vimme/routes/teacher/reports/quiz', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;
      return _ember['default'].RSVP.hash({
        quizzes: this.store.findAll('new-quiz'),
        students: teacher.get('students')
      });
    }
  });
});