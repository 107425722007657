define("vimme/templates/teacher/reports/growth", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 4
            },
            "end": {
              "line": 27,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/teacher/reports/growth.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "growth-students-report", [], ["students", ["subexpr", "@mut", [["get", "students", ["loc", [null, [26, 42], [26, 50]]]]], [], []], "teacher", ["subexpr", "@mut", [["get", "model.user", ["loc", [null, [26, 59], [26, 69]]]]], [], []], "from", ["subexpr", "@mut", [["get", "selectedFrom", ["loc", [null, [26, 75], [26, 87]]]]], [], []], "to", ["subexpr", "@mut", [["get", "selectedTo", ["loc", [null, [26, 91], [26, 101]]]]], [], []]], ["loc", [null, [26, 8], [26, 103]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/teacher/reports/growth.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 5, 5);
        morphs[3] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "one-way-select", [], ["optionLabelPath", "longName", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [6, 20], [6, 31]]]]], [], []], "value", ["subexpr", "@mut", [["get", "selectedFrom", ["loc", [null, [7, 18], [7, 30]]]]], [], []], "update", ["subexpr", "action", ["setFrom"], [], ["loc", [null, [8, 19], [8, 37]]]], "prompt", "Select From", "promptIsSelectable", true], ["loc", [null, [3, 8], [11, 10]]]], ["inline", "one-way-select", [], ["optionLabelPath", "longName", "optionValuePath", "id", "options", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [15, 20], [15, 31]]]]], [], []], "value", ["subexpr", "@mut", [["get", "selectedTo", ["loc", [null, [16, 18], [16, 28]]]]], [], []], "update", ["subexpr", "action", ["setTo"], [], ["loc", [null, [17, 19], [17, 35]]]], "prompt", "Select To", "promptIsSelectable", true], ["loc", [null, [12, 8], [20, 10]]]], ["inline", "color-key", [], ["grade", ["subexpr", "@mut", [["get", "grade", ["loc", [null, [22, 26], [22, 31]]]]], [], []], "print", ["subexpr", "@mut", [["get", "print", ["loc", [null, [22, 38], [22, 43]]]]], [], []]], ["loc", [null, [22, 8], [22, 45]]]], ["block", "if", [["subexpr", "and", [["get", "students", ["loc", [null, [25, 15], [25, 23]]]], ["get", "selectedFrom", ["loc", [null, [25, 24], [25, 36]]]], ["get", "selectedTo", ["loc", [null, [25, 37], [25, 47]]]]], [], ["loc", [null, [25, 10], [25, 48]]]]], [], 0, null, ["loc", [null, [25, 4], [27, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});