define('vimme/models/user-game', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  exports['default'] = _emberData['default'].Model.extend({
    user: _emberData['default'].attr('number'),
    score: _emberData['default'].attr('number'),
    time: _emberData['default'].attr('number'),

    game: _emberData['default'].belongsTo('game', { async: true }),

    levelWithSelectablePart: _emberData['default'].belongsTo('level', { async: true }),
    userLevels: _emberData['default'].hasMany('user-level', { async: true }),

    formattedTime: _ember['default'].computed('time', function () {
      var time = this.get('time') || 0;
      return _moment['default'].utc(time * 1000).format('m:ss');
    })
  });
});