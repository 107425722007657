define('vimme/routes/student/questions/question', ['exports', 'vimme/routes/student'], function (exports, _vimmeRoutesStudent) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    model: function model(params) {
      return this.modelFor('student.questions').questions.objectAt(parseInt(params.question_id, 10) - 1);
    },
    afterModel: function afterModel(model) {
      if (!model) {
        this.replaceWith('student.questions.question', 1);
      }
    },
    setupController: function setupController(controller, model) {
      var parentModel = this.modelFor('student.questions');
      var studentQuestions = parentModel.studentQuestions;
      var studentQuestion = studentQuestions.find(function (studentQuestion) {
        return studentQuestion.get('question.id') === model.get('id');
      });
      var textAnswer = studentQuestion ? studentQuestion.get('textAnswer') : null;

      controller.set('model', model);
      controller.set('studentQuestions', studentQuestions);
      controller.set('studentQuestion', studentQuestion);
      controller.set('studentTest', parentModel.studentTest);
      controller.set('textAnswer', textAnswer);
      controller.set('test', parentModel.test);
      controller.set('student', parentModel.user);
      controller.set('school', parentModel.school);

      this.controllerFor('student.questions').set('currentQuestion', model);
    }
  });
});