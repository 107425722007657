define('vimme/controllers/school-admin/reports/standard', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  var Controller = _ember['default'].Controller;
  var computed = _ember['default'].computed;
  exports['default'] = Controller.extend({
    authAjax: _ember['default'].inject.service(),

    queryParams: ['grade', 'teacher', 'print'],

    reports: null,

    selectedGrade: null,
    selectedTeacher: null,
    selectedSchoolYear: null,

    grade: null,
    teacher: null,
    print: false,

    teachers: null,

    schoolYearName: null,

    schoolYears: computed('model.schoolYears.@each.isCompleted', function () {
      return this.get('model.schoolYears').filterBy('isCompleted', true);
    }),

    inProgressSchoolYear: computed('model.schoolYears.@each.isCompleted', function () {
      return this.get('model.schoolYears').findBy('isCompleted', false);
    }),

    watchSelectedSchoolYear: (function () {
      var selectedSchoolYear = this.get('selectedSchoolYear');
      if (selectedSchoolYear) {
        this.transitionToRoute('school-admin.reports.yearly', selectedSchoolYear.get('id'));
      }
    }).observes('selectedSchoolYear'),

    setGrade: (function () {
      var selectedGrade = this.get('selectedGrade');
      var grade = this.get('grade');

      if (selectedGrade && selectedGrade.get('id') !== grade) {
        this.set('grade', selectedGrade.get('id'));
      } else if (grade && selectedGrade === null) {
        this.set('grade', null);
      }
    }).observes('selectedGrade'),

    watchGrade: (function () {
      var _this = this;

      var grade = this.get('grade');
      var teacher = this.get('teacher');
      var teachers = this.get('teachers');
      var sGrade = this.get('selectedGrade');
      var grades = this.get('model.grades');

      if (!grades) {
        return;
      }

      if (grade && (!sGrade || grade !== sGrade.get('id'))) {
        this.set('selectedGrade', grades.findBy('id', grade));
      }

      if (grade) {
        if (!teachers || teachers.get('firstObject.grade.id') !== grade) {
          this.store.find('teacher', { grade: grade }).then(function (teachers) {
            teachers = teachers.filterBy('isPlaceholder', false).sortBy('lastThenFirst');
            if (teachers.get('length') > 0) {
              _this.set('selectedTeacher', null);
              _this.set('teachers', teachers);
            } else {
              _this.set('teacher', null);
            }
          });
        }

        if (!teacher) {
          this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/standard-reports', { data: { grade: grade } }).then(function (data) {
            if (data.length) {
              _this.set('reports', data);
            } else {
              _this.set('grade', null);
            }
          });
        }
      } else {
        this.set('grade', null);
        this.set('teacher', null);
        this.set('teachers', null);
        this.set('selectedTeacher', null);
        this.set('selectedGrade', null);
        this.set('reports', null);
      }
    }).observes('model.grades', 'grade', 'teacher'),

    setTeacher: (function () {
      var selectedTeacher = this.get('selectedTeacher');
      var teacher = this.get('teacher');

      if (selectedTeacher && selectedTeacher.get('id') !== teacher) {
        this.set('teacher', selectedTeacher.get('id'));
      } else if (teacher && selectedTeacher === null) {
        this.set('teacher', null);
      }
    }).observes('selectedTeacher'),

    watchTeacher: (function () {
      var _this2 = this;

      var teachers = this.get('teachers');
      var teacher = this.get('teacher');
      var fTeacher = null;

      if (!teachers) {
        return;
      }

      if (teacher) {
        fTeacher = teachers.findBy('id', teacher);
        if (fTeacher) {
          this.set('selectedTeacher', fTeacher);

          this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/standard-reports', { data: { teacher: teacher } }).then(function (data) {
            if (data.length) {
              _this2.set('reports', data);
            } else {
              _this2.set('teacher', null);
              _this2.set('selectedTeacher', null);
            }
          });
        } else {
          this.set('teacher', null);
        }
      } else {
        this.set('teacher', null);
      }
    }).observes('teacher', 'teachers'),

    actions: {
      printView: function printView() {
        this.toggleProperty('print');
      }
    }
  });
});