define('vimme/controllers/index', ['exports', 'ember', 'vimme/config/environment'], function (exports, _ember, _vimmeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    session: _ember['default'].inject.service(),
    authAjax: _ember['default'].inject.service(),

    loginError: false,
    showForgotDialog: false,

    isCore: (function () {
      return _vimmeConfigEnvironment['default'].environment === 'core';
    }).property(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password');
        this.get('session').authenticate('authenticator:jwt', credentials).then(function () {
          _this.set('loginError', false);
        }, function () {
          _this.set('loginError', true);
        });
      },
      closeErrorDialog: function closeErrorDialog() {
        this.set('loginError', false);
      },
      showForgotDialog: function showForgotDialog() {
        this.set('showForgotDialog', true);
      },
      cancelForgotDialog: function cancelForgotDialog() {
        this.set('forgotEmail', null);
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        this.set('showForgotDialog', false);
      },
      sendResetLink: function sendResetLink(forgotEmail) {
        var _this2 = this;

        var hash = {
          type: 'POST',
          data: {
            email: forgotEmail
          }
        };
        this.set('isResetError', false);
        this.set('isResetSuccess', false);
        this.get('authAjax').request(_vimmeConfigEnvironment['default'].API.host + '/password/reset-link', hash).then(function (data) {
          _this2.set('message', data.message);
          _this2.set('isResetError', false);
          _this2.set('isResetSuccess', true);
        })['catch'](function (xhr) {
          _this2.set('message', xhr.responseJSON.message);
          _this2.set('isResetError', true);
          _this2.set('isResetSuccess', false);
        });
      }
    }
  });
});