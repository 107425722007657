define("vimme/templates/school-admin/reports/question", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 12
              },
              "end": {
                "line": 23,
                "column": 12
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "teachers", ["loc", [null, [18, 28], [18, 36]]]]], [], []], "optionLabelPath", "content.lastThenFirst", "value", ["subexpr", "@mut", [["get", "selectedTeacher", ["loc", [null, [20, 26], [20, 41]]]]], [], []], "prompt", "Select Teacher"], ["loc", [null, [17, 16], [22, 18]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 8
            },
            "end": {
              "line": 24,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.grades", ["loc", [null, [5, 24], [5, 36]]]]], [], []], "optionLabelPath", "content.title", "value", ["subexpr", "@mut", [["get", "selectedGrade", ["loc", [null, [7, 22], [7, 35]]]]], [], []], "prompt", "Select Grade"], ["loc", [null, [4, 12], [9, 14]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "model.weeks", ["loc", [null, [11, 24], [11, 35]]]]], [], []], "optionLabelPath", "content.longName", "value", ["subexpr", "@mut", [["get", "selectedWeek", ["loc", [null, [13, 22], [13, 34]]]]], [], []], "prompt", "Select Week"], ["loc", [null, [10, 12], [15, 14]]]], ["block", "if", [["get", "showTeachers", ["loc", [null, [16, 18], [16, 30]]]]], [], 0, null, ["loc", [null, [16, 12], [23, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 26,
                "column": 16
              },
              "end": {
                "line": 28,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "grade-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedGrade.title", ["loc", [null, [27, 46], [27, 69]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 16
              },
              "end": {
                "line": 31,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "week-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedWeek.longName", ["loc", [null, [30, 45], [30, 70]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 32,
                "column": 16
              },
              "end": {
                "line": 34,
                "column": 16
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "teacher-crumb");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedTeacher.lastThenFirst", ["loc", [null, [33, 48], [33, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 8
            },
            "end": {
              "line": 36,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "print-crumb");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element19, 1, 1);
          morphs[1] = dom.createMorphAt(element19, 2, 2);
          morphs[2] = dom.createMorphAt(element19, 3, 3);
          return morphs;
        },
        statements: [["block", "if", [["get", "selectedGrade", ["loc", [null, [26, 22], [26, 35]]]]], [], 0, null, ["loc", [null, [26, 16], [28, 23]]]], ["block", "if", [["get", "selectedWeek", ["loc", [null, [29, 22], [29, 34]]]]], [], 1, null, ["loc", [null, [29, 16], [31, 23]]]], ["block", "if", [["get", "selectedTeacher", ["loc", [null, [32, 22], [32, 37]]]]], [], 2, null, ["loc", [null, [32, 16], [34, 23]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 8
            },
            "end": {
              "line": 40,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Print View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element18 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element18);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [39, 34], [39, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 40,
              "column": 8
            },
            "end": {
              "line": 42,
              "column": 8
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "class", "print-view");
          var el2 = dom.createTextNode("Report View");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element17 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element17);
          return morphs;
        },
        statements: [["element", "action", ["printView"], [], ["loc", [null, [41, 34], [41, 56]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 20
                },
                "end": {
                  "line": 62,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              dom.setAttribute(el2, "class", "cell");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var element10 = dom.childAt(element9, [3]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
              morphs[1] = dom.createElementMorph(element10);
              morphs[2] = dom.createMorphAt(element10, 0, 0);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [59, 46], [59, 83]]]], ["element", "action", ["gotoTestReview", ["get", "studentTest.scheduledTest.test.id", ["loc", [null, [60, 70], [60, 103]]]], 1, ["get", "studentTest.id", ["loc", [null, [60, 106], [60, 120]]]]], [], ["loc", [null, [60, 44], [60, 122]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [60, 123], [60, 155]]]]],
            locals: ["studentTest"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 68,
                  "column": 24
                },
                "end": {
                  "line": 77,
                  "column": 24
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("\n                         ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("\n                     ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element8, 'class');
              morphs[1] = dom.createAttrMorph(element8, 'title');
              morphs[2] = dom.createElementMorph(element8);
              morphs[3] = dom.createMorphAt(element8, 1, 1);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["cell-header\n                                ", ["get", "indexedQuestion.shortName", ["loc", [null, [71, 34], [71, 59]]]]]]], ["attribute", "title", ["get", "indexedQuestion.standardsTitle", ["loc", [null, [73, 40], [73, 70]]]]], ["element", "action", ["sortQuestion", ["get", "indexedQuestion", ["loc", [null, [72, 56], [72, 71]]]]], [], ["loc", [null, [72, 32], [72, 73]]]], ["content", "indexedQuestion.shortName", ["loc", [null, [75, 32], [75, 61]]]]],
            locals: ["indexedQuestion"],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 81,
                    "column": 28
                  },
                  "end": {
                    "line": 83,
                    "column": 28
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("\n                             ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("%");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("\n                         ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element7 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element7, 'class');
                morphs[1] = dom.createMorphAt(element7, 0, 0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["cell ", ["get", "paddedAverage.studentQuestion.color", ["loc", [null, [82, 51], [82, 86]]]]]]], ["content", "paddedAverage.studentQuestion.average", ["loc", [null, [82, 90], [82, 131]]]]],
              locals: ["paddedAverage"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 79,
                  "column": 20
                },
                "end": {
                  "line": 85,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row reports-average-row");
              var el2 = dom.createComment("\n                         ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("\n                     ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "paddedAverages", ["loc", [null, [81, 36], [81, 50]]]]], [], 0, null, ["loc", [null, [81, 28], [83, 37]]]]],
            locals: ["paddedAverages"],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@1.13.13",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 89,
                      "column": 32
                    },
                    "end": {
                      "line": 95,
                      "column": 32
                    }
                  },
                  "moduleName": "vimme/templates/school-admin/reports/question.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("\n                                 ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("\n                             ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "vimme-report-question", [], ["paddedStudentQuestion", ["subexpr", "@mut", [["get", "paddedStudentQuestion", ["loc", [null, [91, 62], [91, 83]]]]], [], []], "clickMe", "showQuestion", "hoverMe", "setQuickQuestion"], ["loc", [null, [90, 36], [94, 38]]]]],
                locals: ["paddedStudentQuestion"],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 87,
                    "column": 24
                  },
                  "end": {
                    "line": 97,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createComment("\n                             ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("\n                         ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [89, 40], [89, 62]]]]], [], 0, null, ["loc", [null, [89, 32], [95, 41]]]]],
              locals: ["paddedStudentQuestions"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 86,
                  "column": 20
                },
                "end": {
                  "line": 98,
                  "column": 20
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [87, 61], [87, 70]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [87, 88], [87, 116]]]]], [], []]], 0, null, ["loc", [null, [87, 24], [97, 52]]]]],
            locals: ["studentTest"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 8
              },
              "end": {
                "line": 101,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-general column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-general-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header student-column");
            var el5 = dom.createTextNode("Student");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell-header score-column");
            var el5 = dom.createTextNode("Total");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row reports-average-row");
            var el4 = dom.createTextNode("\n                        ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "cell");
            var el5 = dom.createTextNode("Average");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("%");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                    ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("\n         ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "reports-detailed column");
            var el2 = dom.createTextNode("\n                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "reports-detailed-inner");
            var el3 = dom.createTextNode("\n                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row-header currentSortColumn}}");
            var el4 = dom.createComment("\n                     ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("\n                 ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element11 = dom.childAt(fragment, [1, 1]);
            var element12 = dom.childAt(element11, [1]);
            var element13 = dom.childAt(element12, [1]);
            var element14 = dom.childAt(element12, [3]);
            var element15 = dom.childAt(element11, [3, 3]);
            var element16 = dom.childAt(fragment, [3, 1]);
            var morphs = new Array(9);
            morphs[0] = dom.createAttrMorph(element12, 'class');
            morphs[1] = dom.createElementMorph(element13);
            morphs[2] = dom.createElementMorph(element14);
            morphs[3] = dom.createAttrMorph(element15, 'class');
            morphs[4] = dom.createMorphAt(element15, 0, 0);
            morphs[5] = dom.createMorphAt(element11, 5, 5);
            morphs[6] = dom.createMorphAt(dom.childAt(element16, [1]), 1, 1);
            morphs[7] = dom.createMorphAt(element16, 3, 3);
            morphs[8] = dom.createMorphAt(element16, 4, 4);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["row-header ", ["get", "currentSortColumn", ["loc", [null, [49, 45], [49, 62]]]]]]], ["element", "action", ["sortOther", "student-column"], [], ["loc", [null, [50, 64], [50, 103]]]], ["element", "action", ["sortOther", "score-column"], [], ["loc", [null, [51, 62], [51, 99]]]], ["attribute", "class", ["concat", ["cell ", ["get", "studentAverageColor", ["loc", [null, [55, 43], [55, 62]]]]]]], ["content", "studentAverage", ["loc", [null, [55, 66], [55, 84]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [57, 28], [57, 40]]]]], [], 0, null, ["loc", [null, [57, 20], [62, 29]]]], ["block", "each", [["get", "indexedQuestions", ["loc", [null, [68, 32], [68, 48]]]]], [], 1, null, ["loc", [null, [68, 24], [77, 33]]]], ["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [79, 57], [79, 66]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "averages", ["loc", [null, [79, 84], [79, 92]]]]], [], []]], 2, null, ["loc", [null, [79, 20], [85, 48]]]], ["block", "each", [["get", "studentTests", ["loc", [null, [86, 28], [86, 40]]]]], [], 3, null, ["loc", [null, [86, 20], [98, 29]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 45,
              "column": 4
            },
            "end": {
              "line": 102,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "studentTests", ["loc", [null, [46, 14], [46, 26]]]]], [], 0, null, ["loc", [null, [46, 8], [101, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@1.13.13",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 111,
                    "column": 24
                  },
                  "end": {
                    "line": 113,
                    "column": 24
                  }
                },
                "moduleName": "vimme/templates/school-admin/reports/question.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "print-item");
                var el2 = dom.createTextNode("Q");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var element4 = dom.childAt(element3, [3]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3, 1, 1);
                morphs[1] = dom.createAttrMorph(element4, 'class');
                return morphs;
              },
              statements: [["content", "paddedStudentQuestion.num", ["loc", [null, [112, 54], [112, 83]]]], ["attribute", "class", ["concat", [["get", "paddedStudentQuestion.studentQuestion.correctIcon", ["loc", [null, [112, 97], [112, 146]]]]]]]],
              locals: ["paddedStudentQuestion"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@1.13.13",
              "loc": {
                "source": null,
                "start": {
                  "line": 104,
                  "column": 12
                },
                "end": {
                  "line": 116,
                  "column": 12
                }
              },
              "moduleName": "vimme/templates/school-admin/reports/question.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "print-row column");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-general");
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-name");
              var el4 = dom.createTextNode("Student: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              dom.setAttribute(el3, "class", "print-item print-avg");
              var el4 = dom.createTextNode("Total: ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "print-details");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
              return morphs;
            },
            statements: [["content", "studentTest.student.lastThenFirst", ["loc", [null, [107, 69], [107, 106]]]], ["content", "studentTest.formattedPercent", ["loc", [null, [108, 66], [108, 98]]]], ["block", "each", [["get", "paddedStudentQuestions", ["loc", [null, [111, 32], [111, 54]]]]], [], 0, null, ["loc", [null, [111, 24], [113, 33]]]]],
            locals: ["paddedStudentQuestions"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@1.13.13",
            "loc": {
              "source": null,
              "start": {
                "line": 103,
                "column": 8
              },
              "end": {
                "line": 117,
                "column": 8
              }
            },
            "moduleName": "vimme/templates/school-admin/reports/question.hbs"
          },
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "vimme-question-view-row", [], ["questions", ["subexpr", "@mut", [["get", "questions", ["loc", [null, [104, 49], [104, 58]]]]], [], []], "studentQuestions", ["subexpr", "@mut", [["get", "studentTest.studentQuestions", ["loc", [null, [104, 76], [104, 104]]]]], [], []]], 0, null, ["loc", [null, [104, 12], [116, 40]]]]],
          locals: ["studentTest"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 102,
              "column": 4
            },
            "end": {
              "line": 118,
              "column": 4
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "each", [["get", "studentTests", ["loc", [null, [103, 16], [103, 28]]]]], [], 0, null, ["loc", [null, [103, 8], [117, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 122,
              "column": 0
            },
            "end": {
              "line": 139,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "dialog question-dialog");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "dialog-inner");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("a");
          dom.setAttribute(el3, "class", "btn btn-icon-only btn-dialog-exit icon-delete");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-name");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-type");
          var el4 = dom.createTextNode("Week ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(": Question ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "dialog-message test-question");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [5]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["hideQuestion"], [], ["loc", [null, [126, 11], [126, 36]]]], ["content", "openQuestion.studentQuestion.student.lastThenFirst", ["loc", [null, [127, 33], [127, 87]]]], ["content", "openQuestion.studentQuestion.question.test.week.id", ["loc", [null, [128, 38], [128, 92]]]], ["content", "openQuestion.num", ["loc", [null, [128, 103], [128, 123]]]], ["inline", "vimme-test-question", [], ["question", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion.question", ["loc", [null, [131, 25], [131, 62]]]]], [], []], "studentQuestion", ["subexpr", "@mut", [["get", "openQuestion.studentQuestion", ["loc", [null, [132, 32], [132, 60]]]]], [], []], "isReviewing", true], ["loc", [null, [130, 12], [134, 14]]]]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 141,
              "column": 0
            },
            "end": {
              "line": 149,
              "column": 0
            }
          },
          "moduleName": "vimme/templates/school-admin/reports/question.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "vimme-quick-question", [], ["studentQuestion", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion", ["loc", [null, [143, 24], [143, 53]]]]], [], []], "question", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question", ["loc", [null, [144, 17], [144, 55]]]]], [], []], "answers", ["subexpr", "@mut", [["get", "quickQuestion.studentQuestion.question.answers", ["loc", [null, [145, 16], [145, 62]]]]], [], []], "alignLeft", ["subexpr", "@mut", [["get", "alignLeft", ["loc", [null, [146, 18], [146, 27]]]]], [], []], "alignTop", ["subexpr", "@mut", [["get", "alignTop", ["loc", [null, [147, 17], [147, 25]]]]], [], []]], ["loc", [null, [142, 4], [148, 6]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 150,
            "column": 0
          }
        },
        "moduleName": "vimme/templates/school-admin/reports/question.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "content reports reports-question");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "whole filter-bar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element20 = dom.childAt(fragment, [0]);
        var element21 = dom.childAt(element20, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element21, 1, 1);
        morphs[1] = dom.createMorphAt(element21, 3, 3);
        morphs[2] = dom.createMorphAt(element20, 3, 3);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "unless", [["get", "print", ["loc", [null, [3, 18], [3, 23]]]]], [], 0, 1, ["loc", [null, [3, 8], [36, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [38, 18], [38, 23]]]]], [], 2, 3, ["loc", [null, [38, 8], [42, 19]]]], ["block", "unless", [["get", "print", ["loc", [null, [45, 14], [45, 19]]]]], [], 4, 5, ["loc", [null, [45, 4], [118, 15]]]], ["block", "if", [["get", "showQuestion", ["loc", [null, [122, 6], [122, 18]]]]], [], 6, null, ["loc", [null, [122, 0], [139, 7]]]], ["block", "if", [["get", "quickQuestion", ["loc", [null, [141, 6], [141, 19]]]]], [], 7, null, ["loc", [null, [141, 0], [149, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7]
    };
  })());
});