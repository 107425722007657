define('vimme/routes/guardian/resources', ['exports', 'vimme/routes/guardian', 'ember'], function (exports, _vimmeRoutesGuardian, _ember) {
  exports['default'] = _vimmeRoutesGuardian['default'].extend({
    model: function model() {
      var week0 = _ember['default'].Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var grade0 = _ember['default'].Object.create({
        id: 'none',
        title: 'General',
        shortTitle: 'General'
      });
      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');
      var weeks = [week0];
      var grades = [grade0];
      if (localWeeks.get('length')) {
        weeks = weeks.concat(localWeeks.toArray());
      } else {
        weeks = this.store.findAll('week').then(function (moreWeeks) {
          return weeks.concat(moreWeeks.toArray());
        });
      }
      if (localGrades.get('length')) {
        grades = grades.concat(localGrades.toArray());
      } else {
        grades = this.store.findAll('grade').then(function (moreGrades) {
          return grades.concat(moreGrades.toArray());
        });
      }
      return _ember['default'].RSVP.hash({
        grades: grades,
        weeks: weeks
      });
    }
  });
});