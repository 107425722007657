define('vimme/routes/student/questions', ['exports', 'ember', 'vimme/routes/student', 'npm:jwt-decode'], function (exports, _ember, _vimmeRoutesStudent, _npmJwtDecode) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    beforeModel: function beforeModel(transition) {
      this._super(transition);

      if (transition.targetName === 'student.questions.index') {
        this.replaceWith('student.questions.question', 1);
      }
    },
    model: function model() {
      var scheduledTest = this.modelFor('student.test').get('scheduledTests.firstObject');
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;
      var id = _ref.id;

      var user = this.store.find(role, id);

      return _ember['default'].RSVP.hash({
        test: this.modelFor('student.test'),
        questions: this.modelFor('student.test').get('questions'),
        answers: this.modelFor('student.test').get('answers'),
        studentQuestions: this.modelFor('student.test').get('studentQuestions'),
        scheduledTest: this.modelFor('student.test').get('scheduledTests.firstObject'),
        studentTest: scheduledTest.get('studentTests.firstObject'),
        school: user.then(function (user) {
          return user.get('school');
        }),
        user: user
      });
    },
    afterModel: function afterModel(model) {
      if (model.questions.get('length') === 0) {
        this.replaceWith('student');
      }
    },
    resetController: function resetController(controller, isExiting) {
      this._super.apply(this, arguments);

      if (isExiting) {
        controller.get('model.studentQuestions').reload();
        controller.get('model.answers').reload();
      }
    }
  });
});