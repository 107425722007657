define('vimme/controllers/teacher/reports/question', ['exports', 'ember', 'vimme/utils/round', 'vimme/utils/color'], function (exports, _ember, _vimmeUtilsRound, _vimmeUtilsColor) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['week', 'print'],

    selectedWeek: null,

    week: null,
    print: false,

    questions: null,
    openQuestion: null,

    studentQuestions: null,
    studentTests: null,

    showQuestion: (function () {
      return this.get('openQuestion') && !this.get('print');
    }).property('openQuestion', 'print'),

    setWeek: (function () {
      var selectedWeek = this.get('selectedWeek'),
          week = this.get('week');

      if (selectedWeek && selectedWeek.get('id') !== week) {
        this.set('week', selectedWeek.get('id'));
      } else if (week && selectedWeek === null) {
        this.set('week', null);
      }
    }).observes('selectedWeek'),

    watchWeek: (function () {
      var week = this.get('week'),
          sWeek = this.get('selectedWeek'),
          weeks = this.get('model.weeks');

      if (!weeks) {
        return;
      }

      if (week && (!sWeek || week !== sWeek.get('id'))) {
        this.set('selectedWeek', weeks.findBy('id', week));
      }

      if (!week) {
        this.set('week', null);
        this.set('selectedWeek', null);
        this.set('studentTests', null);
        this.set('studentQuestions', null);
      }
    }).observes('model.weeks', 'week'),

    watchSelects: (function () {
      var week = this.get('week'),
          weeks = this.get('model.weeks'),
          _this = this;

      if (!weeks) {
        return;
      }

      if (week) {
        var test = weeks.findBy('id', week).get('tests.firstObject');

        if (test) {
          test.get('questions').then(function (questions) {
            _this.set('questions', questions);

            _this.store.find('studentQuestion', { week: week }).then(function (studentQuestions) {
              _this.set('studentQuestions', studentQuestions);
              _this.store.find('studentTest', { week: week }).then(function (studentTests) {
                _this.set('studentTests', studentTests.filterBy('scheduledTest.test.isPractice', false));
                _this.set('currentSortColumn', 'score-column');
              });
            });
          });
        } else {
          this.set('studentQuestions', null);
          this.set('studentTests', null);
        }
      } else {
        this.set('studentQuestions', null);
        this.set('studentTests', null);
      }
    }).observes('week', 'model.weeks'),

    indexedQuestions: (function () {
      var questions = this.get('questions');

      if (questions) {
        questions = questions.map(function (question, num) {
          var standardsTitle = question.get('standards').mapBy('id').join(', ');
          return { question: question, num: num + 1, shortName: 'Q' + (num + 1), standardsTitle: standardsTitle };
        });
      }

      return questions;
    }).property('questions'),

    currentSortDirection: 'desc',
    currentSortColumn: 'score-column',

    studentAverage: (function () {
      var studentTests = this.get('studentTests');

      if (studentTests) {
        var scores = studentTests.map(function (studentTest) {
          return studentTest.get('percent');
        });

        return (0, _vimmeUtilsRound['default'])(scores.reduce(function (prev, next) {
          return prev + next;
        }, 0) / scores.get('length'));
      }

      return 0;
    }).property('studentTests.@each.percent'),

    studentAverageColor: (function () {
      return (0, _vimmeUtilsColor['default'])(this.get('studentAverage'), this.get('model.user.grade.id'));
    }).property('studentAverage'),

    averages: (function () {
      var studentQuestions = this.get('studentQuestions');
      var grade = this.get('model.user.grade.id');
      var averages = [];
      var keys = {};
      var total = this.get('studentTests.length');

      if (studentQuestions && studentQuestions.get('length') > 0) {
        studentQuestions.forEach(function (item) {
          var questionID = item.get('question.id');
          var correct = item.get('isCorrect') ? 1 : 0;

          if (keys[questionID] === undefined) {
            var score = (0, _vimmeUtilsRound['default'])(correct / total * 100);
            var scoreColor = (0, _vimmeUtilsColor['default'])(score, grade);
            averages.push(_ember['default'].Object.create({
              question: item.get('question'),
              correct: correct,
              average: score,
              color: scoreColor
            }));
            keys[questionID] = averages.length - 1;
          } else {
            averages[keys[questionID]].set('correct', averages[keys[questionID]].get('correct') + correct);
            var score = (0, _vimmeUtilsRound['default'])(averages[keys[questionID]].get('correct') / total * 100);
            var scoreColor = (0, _vimmeUtilsColor['default'])(score, grade);
            averages[keys[questionID]].set('average', score);
            averages[keys[questionID]].set('color', scoreColor);
          }
        });
      }

      return averages.sortBy('question.id');
    }).property('studentQuestions', 'studentTests.length'),

    actions: {
      sortQuestion: function sortQuestion(question) {
        var studentTests = this.get('studentTests'),
            currentSortColumn = this.get('currentSortColumn'),
            currentSortDirection = this.get('currentSortDirection'),
            sorted;

        if (currentSortColumn === question.shortName && currentSortDirection === 'desc') {
          this.set('currentSortDirection', 'asc');

          sorted = studentTests.toArray().sort(function (a, b) {
            var aQuestion = a.get('studentQuestions').find(function (studentQuestion) {
              return studentQuestion.get('question.id') === question.question.get('id');
            });
            var bQuestion = b.get('studentQuestions').find(function (studentQuestion) {
              return studentQuestion.get('question.id') === question.question.get('id');
            });

            if (!aQuestion && !bQuestion) {
              return 0;
            } else if (!aQuestion) {
              return 1;
            } else if (!bQuestion) {
              return -1;
            }

            if (aQuestion.get('isCorrect')) {
              return 1;
            } else if (bQuestion.get('isCorrect')) {
              return -1;
            } else {
              return 0;
            }
          });
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', question.shortName);

          sorted = studentTests.toArray().sort(function (a, b) {
            var aQuestion = a.get('studentQuestions').find(function (studentQuestion) {
              return studentQuestion.get('question.id') === question.question.get('id');
            });
            var bQuestion = b.get('studentQuestions').find(function (studentQuestion) {
              return studentQuestion.get('question.id') === question.question.get('id');
            });

            if (!aQuestion && !bQuestion) {
              return 0;
            } else if (!aQuestion) {
              return 1;
            } else if (!bQuestion) {
              return -1;
            }

            if (aQuestion.get('isCorrect')) {
              return -1;
            } else if (bQuestion.get('isCorrect')) {
              return 1;
            } else {
              return 0;
            }
          });
        }

        this.set('studentTests', sorted);
      },
      sortOther: function sortOther(column) {
        var studentTests = this.get('studentTests'),
            currentSortColumn = this.get('currentSortColumn'),
            currentSortDirection = this.get('currentSortDirection'),
            sorted;

        if (column === currentSortColumn && currentSortDirection === 'desc') {
          this.set('currentSortDirection', 'asc');

          sorted = studentTests.toArray().reverse();
        } else {
          this.set('currentSortDirection', 'desc');
          this.set('currentSortColumn', column);

          if (column === 'student-column') {
            sorted = studentTests.toArray().sort(function (a, b) {
              var aName = a.get('student.lastThenFirst'),
                  bName = b.get('student.lastThenFirst');

              if (aName < bName) {
                return -1;
              } else if (aName > bName) {
                return 1;
              } else {
                return 0;
              }
            });
          } else if (column === 'score-column') {
            sorted = studentTests.toArray().sort(function (a, b) {
              return b.get('score') - a.get('score');
            });
          } else {
            sorted = studentTests;
          }
        }

        this.set('studentTests', sorted);
      },
      printView: function printView() {
        this.toggleProperty('print');
      },
      showQuestion: function showQuestion(padded) {
        this.set('openQuestion', padded);
      },
      hideQuestion: function hideQuestion() {
        this.set('openQuestion', null);
      },
      setQuickQuestion: function setQuickQuestion(quickQuestion, element) {
        this.set('quickQuestion', quickQuestion);
        if (element) {
          var rect = _ember['default'].$(element).get(0).getBoundingClientRect();
          this.set('alignLeft', rect.right);
          this.set('alignTop', rect.top - 1);
        }
      }
    }
  });
});