define('vimme/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'vimme/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _vimmeConfigEnvironment) {
  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    authenticationRoute: "index",
    refreshAccessTokens: false,
    serverTokenEndpoint: _vimmeConfigEnvironment['default'].API.tokenEndpoint,

    makeRequest: function makeRequest(url, data) {
      data.client_id = _vimmeConfigEnvironment['default'].API.clientID;
      data.client_secret = _vimmeConfigEnvironment['default'].API.clientSecret;
      return this._super(url, data);
    }
  });
});