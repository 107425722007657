define('vimme/routes/teacher/management/student', ['exports', 'ember', 'vimme/routes/teacher'], function (exports, _ember, _vimmeRoutesTeacher) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model(params) {
      var student = this.store.find('student', params.student_id);
      var guardians = student.then(function (student) {
        return student.get('guardians');
      });

      var _modelFor = this.modelFor('teacher');

      var school = _modelFor.school;

      return _ember['default'].RSVP.hash({
        student: student,
        guardians: guardians,
        school: school
      });
    },

    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });
});