define('vimme/routes/admin', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'npm:jwt-decode'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _npmJwtDecode) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    authenticationRoute: 'index',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      var token = this.get('session.data.authenticated.token');

      var _ref = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref.role;

      this._super(transition);

      if (role !== 'Admin') {
        this.replaceWith('index');
      } else if (transition.targetName === 'admin.index') {
        if (transition.sequence === 0) {
          this.replaceWith('admin.management.schools');
        } else {
          this.transitionTo('admin.management.schools');
        }
      }
    },
    model: function model() {
      var token = this.get('session.data.authenticated.token');

      var _ref2 = token ? (0, _npmJwtDecode['default'])(token) : {};

      var role = _ref2.role;
      var id = _ref2.id;

      try {
        new Crate({
          server: '767817740282232832', // Vimme Learning
          channel: '767817740282232835', // #vimme-professors
          location: ['bottom', 'left'],
          color: '#fff',
          glyph: ['/assets/images/icon.png', '90%']
        });
      } catch (err) {}

      return _ember['default'].RSVP.hash({
        tests: this.store.findAll('test'),
        user: this.store.find(role, id)
      });
    }
  });
});