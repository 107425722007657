define('vimme/components/app-pages/teacher/testing-calendar/picker/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    // input
    date: undefined,
    type: 'Start',
    isDisabled: false,

    showPicker: false,

    AMPM: _ember['default'].computed(function () {
      return ['am', 'pm'];
    }),

    momentDate: _ember['default'].computed('date', function () {
      return this.get('date') ? moment(this.get('date')) : undefined;
    }),

    selectedDay: _ember['default'].computed('momentDate', function () {
      return this.get('momentDate') ? this.get('momentDate').startOf('day').toDate() : undefined;
    }),
    selectedHour: _ember['default'].computed('momentDate', function () {
      return this.get('momentDate') ? this.get('momentDate').format('h') : undefined;
    }),
    selectedMinute: _ember['default'].computed('momentDate', function () {
      return this.get('momentDate') ? this.get('momentDate').format('mm') : undefined;
    }),
    selectedAMPM: _ember['default'].computed('momentDate', function () {
      return this.get('momentDate') ? this.get('momentDate').format('a') : 'am';
    }),

    isPickerDisabled: _ember['default'].computed('isDisabled', 'date', function () {
      return this.get('isDisabled') || !this.get('date');
    }),

    actions: {
      togglePicker: function togglePicker() {
        this.toggleProperty('showPicker');
      },
      selectDay: function selectDay(day) {
        this.set('date', day);
        this.toggleProperty('showPicker');
      },
      selectHour: function selectHour(hour) {
        if (hour === '12' && this.get('selectedAMPM') === 'am') hour = 0;
        if (hour !== '12' && this.get('selectedAMPM') === 'pm') hour = Number(hour) + 12;
        this.set('date', this.get('momentDate').set('hour', hour).toDate());
      },
      selectMinute: function selectMinute(minute) {
        this.set('date', this.get('momentDate').set('minute', minute).toDate());
      },
      selectAMPM: function selectAMPM(ampm) {
        if (this.get('selectedAMPM') !== ampm) {
          var hour = Number(this.get('selectedHour'));
          if (hour === 12 && ampm === 'am') hour = 0;
          if (hour !== 12 && ampm === 'pm') hour = hour + 12;
          this.set('date', this.get('momentDate').set('hour', hour).toDate());
        }
      }
    }
  });
});