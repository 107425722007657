define('vimme/controllers/school-admin/management/students/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    schoolAdminController: _ember['default'].inject.controller('schoolAdmin'),
    school: _ember['default'].computed.alias('schoolAdminController.model.user.school'),

    lastName: '',
    firstName: '',
    middleInitial: '',
    teacher: null,
    grade: _ember['default'].computed.alias('teacher.grade'),
    username: '',
    password: '',
    schoolStudentId: '',

    isSaving: false,

    resetVals: (function () {
      this.set('lastName', '');
      this.set('firstName', '');
      this.set('middleInitial', '');
      this.set('teacher', null);
      this.set('username', '');
      this.set('password', '');
      this.set('schoolStudentId', '');
    }).on('init'),

    isValid: (function () {
      var lastName = this.get('lastName'),
          firstName = this.get('firstName'),
          teacher = this.get('teacher'),
          password = this.get('password');

      return !!lastName && !!firstName && !!teacher && !!password && password.length >= 5;
    }).property('lastName', 'firstName', 'teacher', 'password'),

    isUnsavable: _ember['default'].computed.not('isValid'),

    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('isSaving')) {
          this.set('isSaving', true);

          this.get('school').then(function (school) {
            var student = _this.store.createRecord('student', {
              lastName: _this.get('lastName'),
              firstName: _this.get('firstName'),
              middleInitial: _this.get('middleInitial'),
              school: school,
              grade: _this.get('grade'),
              teacher: _this.get('teacher'),
              schoolStudentId: _this.get('schoolStudentId'),
              password: _this.get('password')
            });

            student.save().then(function (student) {
              _this.set('lastName', '');
              _this.set('firstName', '');
              _this.set('middleInitial', '');
              _this.set('teacher', null);
              _this.set('username', '');
              _this.set('password', '');
              _this.set('schoolStudentId', '');

              _this.set('isSaving', false);

              _this.transitionToRoute('school-admin.management.students.student', student.get('id'));
            })['catch'](function (error) {
              student.deleteRecord();

              _this.set('isSaving', false);

              if (error && error.responseJSON && error.responseJSON.message) {
                alert(error.responseJSON.message);
              } else {
                alert('Server Error');
              }
            });
          });
        }
      }
    }
  });
});