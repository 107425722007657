define('vimme/controllers/school-admin/management/students', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    indexController: _ember['default'].inject.controller('school-admin/management/students/index'),

    queryParams: ['print'],
    print: false,

    selectedTeacher: null,
    selectedGrade: null,

    resetSelectedTeacher: (function () {
      var selectedGrade = this.get('selectedGrade');
      if (!selectedGrade || selectedGrade.get('id') !== this.get('selectedTeacher.grade.id')) {
        this.set('selectedTeacher', null);
      }
    }).observes('selectedGrade'),

    actions: {
      newStudent: function newStudent() {
        if (this.get('applicationController.currentRouteName') !== 'school-admin.management.students.index') {
          this.transitionToRoute('school-admin.management.students');
        }

        var index = this.get('indexController');

        index.set('lastName', '');
        index.set('middleInitial', '');
        index.set('firstName', '');
        index.set('teacher', null);
        index.set('username', '');
        index.set('password', '');
      }
    },

    filteredTeachers: (function () {
      return this.get('model.teachers').filterBy('grade.id', this.get('selectedGrade.id'));
    }).property('model.teachers.[]', 'selectedGrade'),

    unsorted: (function () {
      var escape = function escape(term) {
        return term ? term.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') : '';
      };

      var students = this.get('model.students') || [],
          term = this.get('searchTerm'),
          filter = new RegExp(escape(term), 'i'),
          selectedGrade = this.get('selectedGrade'),
          selectedTeacher = this.get('selectedTeacher');

      if (selectedGrade) {
        students = students.filterBy('grade.id', selectedGrade.get('id'));
      }

      if (selectedTeacher) {
        students = students.filterBy('teacher.id', selectedTeacher.get('id'));
      }

      if (term) {
        students = students.filter(function (student) {
          return filter.test(student.get('fullName'));
        });
      }

      return students;
    }).property('searchTerm', 'model.students.[]', 'selectedGrade', 'selectedTeacher'),

    sortProps: ['lastThenFirst'],
    filteredStudents: _ember['default'].computed.sort('unsorted', 'sortProps'),

    filteredRealStudents: _ember['default'].computed.filterBy('filteredStudents', 'teacher.isPlaceholder', false)
  });
});