define('vimme/components/app-pages/teacher/quiz-builder/list-item/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    question: undefined,

    isPreviewVisible: false,

    number: _ember['default'].computed('question.order', function () {
      return this.get('question.order') + 1;
    }),

    actions: {
      showPreview: function showPreview() {
        this.set('isPreviewVisible', true);
      },
      hidePreview: function hidePreview() {
        this.set('isPreviewVisible', false);
      }
    }
  });
});