define('vimme/routes/teacher/resources', ['exports', 'vimme/routes/teacher', 'ember'], function (exports, _vimmeRoutesTeacher, _ember) {
  exports['default'] = _vimmeRoutesTeacher['default'].extend({
    model: function model() {
      var teacher = this.modelFor('teacher').user;

      var localGrades = this.store.all('grade');
      var localWeeks = this.store.all('week');

      var week0 = _ember['default'].Object.create({
        id: 'none',
        number: 0,
        longName: 'General'
      });
      var grade0 = _ember['default'].Object.create({
        id: 'none',
        title: 'General',
        shortTitle: 'General'
      });

      var weeks = [week0].concat(localWeeks.toArray());
      var grades = [grade0].concat(localGrades.toArray());

      return _ember['default'].RSVP.hash({
        grade: teacher.get('grade.id'),
        grades: grades,
        weeks: weeks
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      if (!controller.get('grades')) {
        controller.set('grades', model.grade);
      }
    }
  });
});