define('vimme/routes/student/new-quiz', ['exports', 'ember', 'vimme/routes/student'], function (exports, _ember, _vimmeRoutesStudent) {
  exports['default'] = _vimmeRoutesStudent['default'].extend({
    model: function model(_ref) {
      var quiz_id = _ref.quiz_id;
      var number = _ref.number;

      number = parseInt(number, 0);
      var quiz = this.store.find('new-quiz', quiz_id);
      var questions = quiz.then(function (quiz) {
        return quiz.get('newQuizQuestions');
      });
      var question = questions.then(function (questions) {
        return questions.find(function (question) {
          return question.get('order') === number - 1;
        });
      });
      var studentQuizzes = quiz.then(function (quiz) {
        return quiz.get('studentNewQuizzes');
      });
      var studentQuiz = studentQuizzes.then(function (quizzes) {
        return quizzes.get('firstObject');
      });
      var studentQuestions = studentQuiz.then(function (quiz) {
        return quiz.get('studentNewQuizQuestions');
      });

      return _ember['default'].RSVP.hash({
        quiz: quiz,
        questions: questions,
        question: question,
        number: number,
        studentQuiz: studentQuiz,
        studentQuizzes: studentQuizzes,
        studentQuestions: studentQuestions
      });
    }
  });
});