define('vimme/models/answer', ['exports', 'vimme/utils/compute-image-path', 'ember-data'], function (exports, _vimmeUtilsComputeImagePath, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    imagePath: _emberData['default'].attr('string'),

    isCorrect: _emberData['default'].attr('boolean', { defaultValue: false }),

    audioPath: _emberData['default'].attr('string'),
    alternateAudio: _emberData['default'].attr('string'),
    noAudio: _emberData['default'].attr('boolean'),

    question: _emberData['default'].belongsTo('question', { async: true }),

    createdAt: _emberData['default'].attr('isodate'),
    updatedAt: _emberData['default'].attr('isodate'),

    isLocalImage: false,

    localImage: null,

    effectiveImagePath: Ember.computed('imagePath', 'localImage', function () {
      return (0, _vimmeUtilsComputeImagePath.computeImagePath)(this.get('localImage'), this.get('imagePath'));
    }),

    isImage: Ember.computed('imagePath', 'localImage', 'isLocalImage', function () {
      return !!this.get('imagePath') || !!this.get('localImage') || this.get('isLocalImage');
    }),

    image64: null,

    setImage64: (function () {
      var _this = this;

      var image = this.get('localImage');
      if (image) {
        var reader = new FileReader();
        reader.onload = function (e) {
          _this.set('image64', e.target.result);
        };
        reader.readAsDataURL(image);
      } else {
        this.set('image64', null);
      }
    }).observes('localImage')
  });
});