define('vimme/components/mathbots-teacher-reporting/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['content', 'reports'],
    classNameBindings: ['signedUp:reports-question:reports-mathbots'],

    signupAvg: _ember['default'].computed('signedUp', 'attempts', 'students', function () {
      var _this = this;

      var signedUp = this.get('signedUp');
      if (signedUp) {
        var tracker = this.get('students').reduce(function (prev, student) {
          var signedUp = _this.get('attempts.' + student.get('userId') + '.is_signed_up');
          return {
            signedUp: prev.signedUp + (signedUp ? 1 : 0),
            total: prev.total + 1
          };
        }, { signedUp: 0, total: 0 });
        return tracker.total ? Math.round(tracker.signedUp / tracker.total * 100) : 0;
      }
      return 0;
    })
  });
});