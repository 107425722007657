define('vimme/components/app-pages/admin/schools/school/component', ['exports', 'ember'], function (exports, _ember) {
  var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    isLoading: false,
    isNotLoading: _ember['default'].computed.not('isLoading'),

    school: undefined,
    editModel: undefined,
    isNew: false,
    isTitleDirty: _ember['default'].computed('school.title', 'editModel.title', function () {
      var s = this.get('school.title');
      var em = this.get('editModel.title');

      if (!s && !em) {
        return false;
      }

      s = s && s.trim();
      em = em && em.trim();

      return s !== em;
    }),
    isTitleValid: _ember['default'].computed('editModel.title', function () {
      var em = this.get('editModel.title');
      return !!em && !!em.trim();
    }),

    isLockedDirty: _ember['default'].computed('school.isLocked', 'editModel.isLocked', function () {
      var s = this.get('school.isLocked');
      var em = this.get('editModel.isLocked');

      return s !== em;
    }),

    isValid: _ember['default'].computed.bool('isTitleValid'),
    isInvalid: _ember['default'].computed.not('isValid'),

    isDirty: _ember['default'].computed.or('isTitleDirty', 'isLockedDirty'),
    isClean: _ember['default'].computed.not('isDirty'),

    canSave: _ember['default'].computed.and('isNotLoading', 'isDirty', 'isValid'),
    cannotSave: _ember['default'].computed.not('canSave'),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setup();
    },

    setup: function setup() {
      var editModel = {
        title: this.get('school.title'),
        isLocked: this.get('school.isLocked')
      };
      this.setProperties({
        editModel: editModel,
        isNew: this.get('school.isNew')
      });
    },

    actions: {
      setTrimmedTitle: function setTrimmedTitle(title) {
        this.set('editModel.title', title.trim());
      },
      setIsLocked: function setIsLocked(isLocked) {
        this.set('editModel.isLocked', isLocked);
      },

      save: function save() {
        var _this = this;

        this.set('isLoading', true);
        this.get('school').setProperties(_extends({}, this.get('editModel')));

        this.get('school').save().then(function (school) {
          _this.set('isLoading', false);
          if (_this.get('isNew')) {
            _this.get('router').transitionTo('admin.management.schools.school', school.get('id'));
          }
        })['catch'](function (e) {
          console.warn(e);
          _this.set('isLoading', false);
          _this.get('school').rollbackAttributes();
          alert('Something went wrong saving the school!');
        });
      }
    }
  });
});