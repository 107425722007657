define("vimme/components/print-view-button/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "vimme/components/print-view-button/template.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("a");
        dom.setAttribute(el1, "class", "print-view");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'style');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        return morphs;
      },
      statements: [["attribute", "style", ["get", "style", ["loc", [null, [1, 30], [1, 35]]]]], ["element", "action", [["get", "printView", ["loc", [null, [1, 47], [1, 56]]]]], [], ["loc", [null, [1, 38], [1, 58]]]], ["inline", "if", [["get", "print", ["loc", [null, [1, 64], [1, 69]]]], "Report View", "Print View"], [], ["loc", [null, [1, 59], [1, 98]]]]],
      locals: [],
      templates: []
    };
  })());
});